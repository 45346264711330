import { db } from '../../../firebase-config';
import { doc, query, collection, getDocs, getDoc, setDoc, deleteDoc, updateDoc, arrayUnion} from 'firebase/firestore';
//import {GermanTerritories, SovietTerritories, NeutralTerritories, FinnlandTerritories, SovietFightingTerritories1, SovietFightingTerritories2, SovietFightingTerritories3, SovietFightingTerritories4} from "../Main/svgComponents/MainMap";

export async function deleteGameSimplified(user, gameId){

   const docSnap = await getDoc(doc(db, "games", gameId, "players", user.uid, "time", "running"));
   const numberLogs = docSnap.data().numberLogs;

   for(let j = 0; j<=numberLogs;j++){
      const logNumber = `log${j}`
      await deleteDoc(doc(db, "games", gameId, "players", user.uid, "time", logNumber ));
   }

   await deleteDoc(doc(db, "games", gameId, "players", user.uid));
   await deleteDoc(doc(db, "games", gameId, "players", user.uid, "time", "running"));
   await deleteDoc(doc(db, "games", gameId, "players", user.uid, "time", "total"));
   await deleteDoc(doc(db, "games", gameId, "players", user.uid, "ww2", "economy"));
   await deleteDoc(doc(db, "games", gameId, "players", user.uid, "ww2", "army"));

}

export async function deleteGame(user, gameId){
   
   const docSnapGame = await getDoc(doc(db, "games", gameId))
   updateDoc(doc(db, "games", gameId), {
      loaded: "deleting",
      loaded3: Date.now()/1000
   })
   //const territoryArray = [GermanTerritories, SovietTerritories, NeutralTerritories, SovietFightingTerritories1, SovietFightingTerritories2, SovietFightingTerritories3, SovietFightingTerritories4];

   if (docSnapGame.data().loaded1 === true) {
      const playersArray = docSnapGame.data().players;
      await deleteDoc(doc(db, "games", gameId, "ww2", "actions"));
      await deleteDoc(doc(db, "games", gameId, "german", "territories"));
      await deleteDoc(doc(db, "games", gameId, "russian", "territories"));
      await deleteDoc(doc(db, "games", gameId, "frontline", "german"));
      await deleteDoc(doc(db, "games", gameId, "frontline", "soviet"));

      for (let i = 0; i<playersArray.length; i++) {
         const docSnap = await getDoc(doc(db, "games", gameId, "players", playersArray[i].uid, "time", "running"))
         const numberLogs = docSnap.data().numberLogs

         for(let j = 0; j<=numberLogs;j++){
            const logNumber = `log${j}`
            await deleteDoc(doc(db, "games", gameId, "players", playersArray[i].uid, "time", logNumber ));
         }

         await deleteDoc(doc(db, "games", gameId, "players", playersArray[i].uid));
         await deleteDoc(doc(db, "games", gameId, "players", playersArray[i].uid, "time", "running"));
         await deleteDoc(doc(db, "games", gameId, "players", playersArray[i].uid, "time", "total"));
         await deleteDoc(doc(db, "games", gameId, "players", playersArray[i].uid, "ww2", "economy"));
         await deleteDoc(doc(db, "games", gameId, "players", playersArray[i].uid, "ww2", "army"));

         if (i === playersArray.length-1) {
            updateDoc(doc(db, "games", gameId), {
               loaded1: "deleted",
            })
         }
      }
   }

   let undeletedTerritories = [];
   const querySnapshot = await getDocs(collection(db, "games", gameId, "territories"));
   querySnapshot.forEach((doc) => {
      undeletedTerritories.push(doc)
   });
   
   for (let i = 0; i <= undeletedTerritories.length; i++){
      await  deleteDoc(doc(db, "games", gameId, "territories", undeletedTerritories[i].id))
      if (i === undeletedTerritories.length){
         await deleteDoc(doc(db, "games", gameId));
         return null;
      }
   }
}