import React, {useState, useEffect} from 'react'
import NewCampaignImage from '../../General/NewCampaignImage'
import { useSelector} from 'react-redux'
import { Link } from "react-router-dom"
import { initializeGame } from './initializeGame';
import { deleteGame } from './deleteGame';
import { db } from '../../../firebase-config';
import { doc, deleteDoc, updateDoc, arrayUnion, getDocs, getDoc, collection, query, where } from 'firebase/firestore';
import { useNavigate } from "react-router-dom"

const MultiplayerGameListed = (props) => {

   const item = props.item;
   const user = useSelector((state) => state.user.value)
   const navigate = useNavigate();

   const [join, setJoin] = useState(false);

   useEffect(()=>{

      const joined = item.data().players.some(object => {
         if (object.uid === user.uid){return true}
         else {return false}
      })
      setJoin(joined)
   },[])
   
   async function joinAs(item, faction){
      setJoin("inProcess")
      const docRefGame = doc(db, "games", item.data().id)
      const docSnapGame = await getDoc(docRefGame)
      if((faction === "Germany" & docSnapGame.data().playersGermany.length === 0)||
      (faction === "Russia" & docSnapGame.data().playersRussia.length === 0)){
         setJoin("joined")
         await initializeGame(user, item.data().id, faction);
         setTimeout(() => navigate(`/multiplayer/${item.data().id}` , { state: { id: `${item.data().id}`, players: item.data().players }}), 2000);
      }
      
   }

   function renderDelete(){

      if (item.data().loaded !== "deleting"){
         return null;
      } else if (item.data().loaded === "deleting"){

         const timeDifferenceSec = Date.now()/1000 - item.data().loaded3;
         if (timeDifferenceSec*0.3 < 10){
            return <h3>Deleting...</h3>
         } else {
            deleteGame(user, item.data().id)
            return <>
            <h5>Aborted. Resuming deletion...</h5>
            </>
         }
      }
   }

   return (
   <section className='game-list-cont'>
      <div className='listed-image-div'>
         <NewCampaignImage
            selectedScenario={item.data().scenario}
            height="50px"
         />
      </div>
      <section className='multiplayer-sub-container'>
         <h4 className='multiplayer-expendable'>Operation:</h4>
         <h4 className='multiplayer-secondary-expendable'> {item.data().scenario}</h4>
      </section>
      <section className='multiplayer-sub-container'>
         <h4 className='multiplayer-expendable'>Game ID:</h4>
         <h4>{item.data().id}</h4>
      </section>
      
      {item.data().playerCount === "Singleplayer" & join !== undefined ? 
         (<>
            <h3 className='multiplayer-secondary-expendable'>Singleplayer</h3>
         </>)
         :
         (<>
            <div className='multiplayer-secondary-expendable'>
               {item.data().playersGermany.length ? (<>
                  {item.data().playersGermany.map((player) => {
                     return (
                        <>
                        {(player.uid === item.data().host) ? 
                        (<h5>{player.nickName} (Host)</h5>) 
                        : 
                        (<h5>{player.nickName}</h5>)}
                        </>
                     )
                  })}
               </>):<h4>Open slot (Germany)</h4>}
               <h5>vs.</h5>
               {item.data().playersRussia.length ? (<>
                     {item.data().playersRussia.map((player) => {
                        return (
                           <>
                           {(player.uid === item.data().host) ? 
                           (<h5>{player.nickName} (Host)</h5>) 
                           : 
                           (<h5>{player.nickName}</h5>)}
                           </>
                        )
                     })}
               </>):<h5>Open slot (Russia)</h5>}
            </div>
         </>)//Not singleplayerdeleteG
      }
      
      <section className='multiplayer-div-buttons'>

      {item.data().playerCount === "Singleplayer" || (item.data().playerCount > 0 & props.myGame === "yes" ) ? <>
         <Link to={`/multiplayer/${item.data().id}`} 
            state={{ 
               id: item.data().id,
               players: item.data().players
            }}>
            <button className="inverse-button listed-button">View</button>
            
         </Link>

         {renderDelete()}
         
         
      </>:null}

      {join === false ? 
      <button className='inverse-button listed-button' onClick={()=>{setJoin("selection")}}>Join</button>
      :null}
   
      {join === "selection" ? <>
      <button className='inverse-button listed-button' onClick={()=>{joinAs(item, "Germany")}}>as Germany</button>
      <button className='inverse-button listed-button' onClick={()=>{joinAs(item, "Russia")}}>as Russia</button>
      </>:null}

      {join === "inProcess" ? 
      <button className='inverse-button listed-button'>Waiting...</button>
      :null}
      
      {join === "joined" ?
      <Link to={`/multiplayer/${item.data().id}`} 
         state={{ 
            id: item.data().id,
            players: item.data().players
         }}>
         <button className="inverse-button listed-button">View</button>
      </Link>
      :null}
               
      </section>
   </section>
   )
}

export default MultiplayerGameListed

/*
function renderDelete(){
      if(deleting === true){
         return <h3>Deleting..</h3>
      } else if (deleting === "really-delete"){
         return <>
         <button className="inverse-button really-delete" onClick={(()=> {deleteFunction()})}>Really delete?</button>
         <img className="cursor-pointer" onClick={()=>setDeleting(false)}src="images/icons/delete.png" width="30px" alt="cancel" />
         </>
      } else if (deleting === false){
         return <button className="inverse-button listed-button" onClick={()=>setDeleting("really-delete")}>Delete</button>
      }
   }



*/