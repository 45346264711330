import { db } from '../../../../firebase-config';
import { doc, getDoc, setDoc, updateDoc, arrayUnion, arrayRemove, increment} from "firebase/firestore";
import { CheckNeighbors, CheckNeighborsSpecial, CheckNeighborsSubtracted } from './CheckNeighbors';
import { deleteGame, deleteGameSimplified } from '../../PreGame/deleteGame';
import faction from '../../../../features/faction';

export async function MoveCommand(gameId, faction, user, pts, selection, cost, tankInput, infantryInput, oldSelectionName, attackedSelection, oldSelection){

   const docRefGerman = doc(db, "games", gameId, "german", "territories");
   const docRefRussian = doc(db, "games", gameId, "russian", "territories");

   var updatedTerritoryFrom = "empty";
   var updatedTerritoryToMove = "inf";
   tankInput = parseInt(tankInput)
   infantryInput = parseInt(infantryInput)

   const docRefFrom = doc(db, "games", gameId, "territories", oldSelectionName);
   const docSnapFrom = await getDoc(docRefFrom);
   const infantryFrom = docSnapFrom.data().infDivs;
   const tankFrom = docSnapFrom.data().tankDivs;

   const docRefTo = doc(db, "games", gameId, "territories", attackedSelection);
   const docSnapTo = await getDoc(docRefTo);   
   const infantryTo = docSnapTo.data().infDivs;
   const tankTo = docSnapTo.data().tankDivs;

   const updatedInfTo = parseInt(infantryTo) + parseInt(infantryInput);
   const updatedTankTo = parseInt(tankTo) + parseInt(tankInput);
   const updatedInfFrom = infantryFrom - infantryInput;
   const updatedTankFrom = tankFrom - tankInput;
   const oldTerritoryFrom = oldSelection.type;
   const oldTerritoryTo = selection.type;
   const currentTime = Date.now();

   if(updatedTankFrom > 0){updatedTerritoryFrom = "tank"}
   if(updatedInfFrom > 0 & updatedTankFrom === 0){updatedTerritoryFrom = "inf"}
   if(updatedTankTo > 0){updatedTerritoryToMove = "tank"}
   if(updatedInfTo > 0 & updatedTankTo === 0){ updatedTerritoryToMove = "inf" }


   updateDoc(docRefTo, {
      infDivs: infantryInput+infantryTo,
      tankDivs: tankInput+tankTo,
      lastAttack: currentTime,
   })
   updateDoc(docRefFrom, {
      infDivs: infantryFrom-infantryInput,
      tankDivs: tankFrom-tankInput,
   })

   if (faction === "Germany"){
      if (updatedTerritoryFrom === "tank") {   //not moving all tanks
      } else {   
         await updateDoc(docRefGerman, {
            territories: arrayRemove({name: oldSelection.name, path: oldSelection.path, type: oldTerritoryFrom})
         })
         await updateDoc(docRefGerman, {
            territories: arrayUnion({name: oldSelection.name, path: oldSelection.path, type: updatedTerritoryFrom})
         })
      }

      if (oldTerritoryTo === updatedTerritoryToMove){
      } else {
         await updateDoc(docRefGerman, {
            territories: arrayRemove({name: selection.name, path: selection.path, type: oldTerritoryTo})
         })
         await updateDoc(docRefGerman, {
            territories: arrayUnion({name: selection.name, path: selection.path, type: updatedTerritoryToMove})
         })
      }
      const docSnapGerman = await getDoc(docRefGerman);
      return(docSnapGerman.data().territories)
   } else if (faction === "Russia"){
      if (updatedTerritoryFrom === "tank") {   //not moving all tanks
      } else {   
         await updateDoc(docRefRussian, {
            territories: arrayRemove({name: oldSelection.name, path: oldSelection.path, type: oldTerritoryFrom})
         })
         await updateDoc(docRefRussian, {
            territories: arrayUnion({name: oldSelection.name, path: oldSelection.path, type: updatedTerritoryFrom})
         })
      }

      if (oldTerritoryTo === updatedTerritoryToMove){
      } else {
         await updateDoc(docRefRussian, {
            territories: arrayRemove({name: selection.name, path: selection.path, type: oldTerritoryTo})
         })
         await updateDoc(docRefRussian, {
            territories: arrayUnion({name: selection.name, path: selection.path, type: updatedTerritoryToMove})
         })
      }
      const docSnapGerman = await getDoc(docRefRussian);
      return(docSnapGerman.data().territories)
   }

   updateDoc(doc(db, "games", gameId), {
      actionsLog: arrayUnion({faction: faction, type: "move", time: Date.now(), territory: selection.name})
   })
}

const primaryLine = ["Riga", "Aizkraukle", "Jekabpils", "Daugavpils", "Kraslava", "Moiry", "Verkhnyadzvinsk",
   "Navapolatsk", "Sumilna", "Vitebsk", "UnnaBeshenkovichi", "Orsa", "Chavusy", "Bykhaw",
   "Kanhnho", "Svietlahorsk", "Kapmaebl", "Chojniki", "Chernobyl", "Boryspil", "Pereyaslav-Khmelnytskyi", "Shramkivka", "Lazirky",
   "Lubny", "Myrhorod", "Poltava", "Mahdalynivka", "Synelnykove", "Zaporozhe", "Enerhodor",
   "Nova Kakhovka", "Kherson"]

async function retreatNeighbors(gameId, neighbors, docSnapTo){
   var retreatableNeighbors = [];
   for (let i = 0; i < neighbors.length; i++){
      const docSnap = await getDoc(doc(db, "games", gameId, "territories", neighbors[i]))
      if (docSnap.data().controlledBy === docSnapTo.data().controlledBy){
         retreatableNeighbors.push(neighbors[i])
      }
   }
   return retreatableNeighbors
}
async function hostileNeighbors(gameId, neighbors, docSnapTo){
   var hostileBorderTerritories = [];
   
   for (let i = 0; i < neighbors.length; i++){
      const docSnap = await getDoc(doc(db, "games", gameId, "territories", neighbors[i]))
      if (docSnap.data().controlledBy !== docSnapTo.data().controlledBy){
         hostileBorderTerritories.push(neighbors[i])
      }
   }
   return hostileBorderTerritories
}
async function friendlyNeighbors(gameId, neighbors, docSnapTo){
   var friendlyBorderTerritories = [];
   
   for (let i = 0; i < neighbors.length; i++){
      const docSnap = await getDoc(doc(db, "games", gameId, "territories", neighbors[i]))
      if (docSnap.data().controlledBy === docSnapTo.data().controlledBy){
         friendlyBorderTerritories.push(neighbors[i])
      }
   }
   return friendlyBorderTerritories
}
export async function AttackCommand(gameId, user, pts, selection, cost, tankInput, infantryInput,  power, powerDefence, oldSelectionName, attackedSelection, oldSelection){

   const docRefGame = doc(db, "games", gameId);
   const docRefFrom = doc(db, "games", gameId, "territories", oldSelectionName);
   const docSnapFrom = await getDoc(docRefFrom);
   const infantryFrom = docSnapFrom.data().infDivs;
   const tankFrom = docSnapFrom.data().tankDivs;
   const factionFrom = docSnapFrom.data().controlledBy;
   const lastAttack = docSnapFrom.data().lastAttack;

   const docRefTo = doc(db, "games", gameId, "territories", attackedSelection);
   const docSnapTo = await getDoc(docRefTo);   
   const infantryTo = docSnapTo.data().infDivs;
   const tankTo = docSnapTo.data().tankDivs;
   const neighbors = docSnapTo.data().neighbors;
   const economyTo = docSnapTo.data().economyLevel

   const updatedInfFrom = infantryFrom - infantryInput;
   const updatedTankFrom = tankFrom - tankInput;
   const currentTime = Date.now();

   const longCooldown = 300;
   const timeSinceLastAttack = Math.floor((currentTime - lastAttack)/1000/60);

   if (user === "AI" & timeSinceLastAttack < longCooldown){
      console.log("Ai territory still on cooldown")
      return;
   }

   if (pts < cost){
      alert("Not enough points. You need " + cost + " points.");
      return;
   } else if (timeSinceLastAttack < longCooldown){
      alert("Still on cooldown. " + timeSinceLastAttack + " / "+ longCooldown+ " minutes have passed");
      return;
   }

   var updatedTerritoryFrom = "empty";
   var updatedTerritoryToAttack = "inf";
   if(updatedTankFrom > 0){updatedTerritoryFrom = "tank"}
   if(updatedInfFrom > 0 & updatedTankFrom === 0){updatedTerritoryFrom = "inf"}

   var retreatableNeighbors = [];

   const docRefGerman = doc(db, "games", gameId, "german", "territories");
   const docRefRussian = doc(db, "games", gameId, "russian", "territories");

   //move bases forward & broken first line
   if (factionFrom === "Germany"){
      const docRefActions = doc(db, "games", gameId, "ww2", "actions");
      const docSnapWW = await getDoc(docRefActions);

      if (docSnapWW.data().daugavaDniperBroken === false){
         for(let i = 0; i<primaryLine.length; i++){
            if(attackedSelection === primaryLine[i]){
               
               updateDoc(docRefActions, {
                  daugavaDniperBroken: true
               })
            }
         }
      }

      //Move bases forward
      if (attackedSelection === "Riga" || attackedSelection === "Leningrad"|| attackedSelection === "Pskov"){
         const docRefActions = doc(db, "games", gameId, "ww2", "actions");
         updateDoc(docRefActions, {
            RFBasesNorth: arrayUnion(attackedSelection)
         })
      }
      if (attackedSelection === "Brzesc Litewski" || attackedSelection === "Minsk"|| attackedSelection === "Smolensk"
      || attackedSelection === "Vyazma"|| attackedSelection === "Moscow"){
         const docRefActions = doc(db, "games", gameId, "ww2", "actions");
         updateDoc(docRefActions, {
            RFBasesCenter: arrayUnion(attackedSelection)
         })
      }
      if (attackedSelection === "Lwow" || attackedSelection === "Kiev"|| attackedSelection === "Kharkov"
      || attackedSelection === "Voroshilovgrad" || attackedSelection === "Stalingrad"){
         const docRefActions = doc(db, "games", gameId, "ww2", "actions");
         updateDoc(docRefActions, {
            RFBasesSouth: arrayUnion(attackedSelection)
         })
      }
      if (attackedSelection === "Odessa" || attackedSelection === "Sevastopol"|| attackedSelection === "Maikop"){
         const docRefActions = doc(db, "games", gameId, "ww2", "actions");
         updateDoc(docRefActions, {
            RFBasesDeepSouth: arrayUnion(attackedSelection)
         })
      }
      if (attackedSelection === "Moscow" || attackedSelection === "Stalingrad"|| attackedSelection === "Kostroma"){
         const docRefActions = doc(db, "games", gameId, "ww2", "actions");
         updateDoc(docRefActions, {
            RussianBases: arrayRemove(attackedSelection)
         })
      }

   } else {
            
      if (attackedSelection === "Riga" || attackedSelection === "Leningrad"|| attackedSelection === "Pskov"){
         const docRefActions = doc(db, "games", gameId, "ww2", "actions");
         updateDoc(docRefActions, {
            RFBasesNorth: arrayRemove(attackedSelection)
         })
      }
      if (attackedSelection === "Brzesc Litewski" || attackedSelection === "Minsk"|| attackedSelection === "Smolensk"
      || attackedSelection === "Vyazma"|| attackedSelection === "Moscow"){
         const docRefActions = doc(db, "games", gameId, "ww2", "actions");
         updateDoc(docRefActions, {
            RFBasesCenter: arrayRemove(attackedSelection)
         })
      }
      if (attackedSelection === "Lwow" || attackedSelection === "Kiev"|| attackedSelection === "Kharkov"
      || attackedSelection === "Voroshilovgrad" || attackedSelection === "Stalingrad"){
         const docRefActions = doc(db, "games", gameId, "ww2", "actions");
         updateDoc(docRefActions, {
            RFBasesSouth: arrayRemove(attackedSelection)
         })
      }
      if (attackedSelection === "Odessa" || attackedSelection === "Sevastopol"|| attackedSelection === "Maikop"){
         const docRefActions = doc(db, "games", gameId, "ww2", "actions");
         updateDoc(docRefActions, {
            RFBasesDeepSouth: arrayRemove(attackedSelection)
         })
      }
      if (attackedSelection === "Moscow" || attackedSelection === "Stalingrad"|| attackedSelection === "Kostroma"){
         const docRefActions = doc(db, "games", gameId, "ww2", "actions");
         updateDoc(docRefActions, {
            RussianBases: arrayUnion(attackedSelection)
         })
      }
   }    

   var shattTankDef;
   var shattInfDef;
   var shattTankAtt;
   var shattInfAtt;

   function shatteredInfDivs(strength){
      const shattInfDet = Math.floor(strength/20);
      const shattInfProb = (strength % 20)*5/100;
      if (Math.random() < shattInfProb){
         return shattInfDet +1;
      } else {
         return shattInfDet
      }
   }

   //shattered Divisions
   if (tankInput > 0){
      const shattTankAttProb = powerDefence/120;
      if (Math.random() < shattTankAttProb){
         shattTankAtt = 1;
         shattInfAtt = shatteredInfDivs(powerDefence);
      } else {
         shattInfAtt = shatteredInfDivs(powerDefence);
         shattTankAtt = 0;
      }
   } else {
      shattInfAtt = shatteredInfDivs(powerDefence)
      shattTankAtt = 0;
   }

   if (shattInfAtt > infantryInput){
      shattInfAtt = infantryInput-1
   }

   if (tankTo > 0){
      const shattTankDefProb = power/60;
      if (Math.random() < shattTankDefProb){
         shattTankDef = 1;
         shattInfDef = 0;
      } else {
         shattInfDef = shatteredInfDivs(power)
         shattTankDef = 0;
      }
   } else {
      shattInfDef = shatteredInfDivs(power)
      shattTankDef = 0;
   }

   if (shattInfDef > infantryTo){
      shattInfDef = infantryTo
   }

   const remainingInfAtt = infantryInput-shattInfAtt;
   const remainingTanksAtt = tankInput-shattTankAtt;

   if (remainingTanksAtt > 0){updatedTerritoryToAttack = "tank"}
   if (remainingTanksAtt === 0 & remainingInfAtt > 0){updatedTerritoryToAttack = "inf"}

   updateDoc(docRefTo, {
      infDivs: remainingInfAtt,
      tankDivs: remainingTanksAtt,
      lastAttack: currentTime
   })
   updateDoc(docRefFrom, {
      infDivs: infantryFrom - infantryInput,
      tankDivs: tankFrom - tankInput,
   })

   async function retreat(){
      if (retreatableNeighbors === []){
         alert("encircled and shattered");
      } else {
         for (let i = 0; i < infantryTo-shattInfDef; i++){
            const position = Math.floor(Math.random()*retreatableNeighbors.length);
            const retreatTer = retreatableNeighbors[position];
            const docSnap = await getDoc(doc(db, "games", gameId, "territories", retreatTer));
            const infantryCount = docSnap.data().infDivs + 1
            
            updateDoc(doc(db, "games", gameId, "territories", retreatTer),{
               infDivs: infantryCount
            })
            
         }
         for (let i = 1; i < tankTo-shattTankDef; i++){
            const position = Math.floor(Math.random()*neighbors.length);
            const retreatTer = retreatableNeighbors[position];
            const docSnap = await getDoc(doc(db, "games", gameId, "territories", retreatTer));
            const tankCount = docSnap.data().tankDivs +1;
            updateDoc(doc(db, "games", gameId, "territories", retreatTer),{
               tankDivs: tankCount
            })
         }
      }
   }
   retreatableNeighbors = await retreatNeighbors(gameId, neighbors, docSnapTo);
   retreat();

   async function checkTerritoriesFrom (docRef){
      if (updatedTerritoryFrom !== "tank") {
         await updateDoc(docRef, {
            territories: arrayRemove({name: oldSelection.name, path: oldSelection.path, type: oldSelection.type})
         })
         await updateDoc(docRef, {
            territories: arrayUnion({name: oldSelection.name, path: oldSelection.path, type: updatedTerritoryFrom})
         })
      }
   }

   async function addTerritoriesTo(docRef){
      await updateDoc(docRef, {
         territories: arrayUnion({name: selection.name, path: selection.path, type: updatedTerritoryToAttack})
      })
   }

   async function clearEnemyTerritory(docRefEnemy){
      let capturedType = "empty";
      if (tankTo > 0){
         capturedType = "tank";
      } else if (infantryTo > 0){
         capturedType = "inf";
      }
      await updateDoc(docRefEnemy, {
         territories: arrayRemove({name: selection.name, path: selection.path, type: capturedType})
      })
   }

   if (factionFrom === "Germany"){
      
      checkTerritoriesFrom(docRefGerman)
      addTerritoriesTo(docRefGerman)
      clearEnemyTerritory(docRefRussian)
      
      updateDoc(docRefGame, {
         germanTerritoryPoints: increment(economyTo),
         sovietTerritoryPoints: increment(-economyTo),
      })

      const docSnapGerman = await getDoc(docRefGerman);
      const docSnapRussian = await getDoc(docRefRussian);
      updateDoc(docRefGame, {
         lastGermanMapAction: Date.now()/1000
      })

      return({
         //germanTerritories: docSnapGerman.data().territories,
         //russianTerritories: docSnapRussian.data().territories,
         shattAttInf: shattInfAtt,
         shattAttTank: shattTankAtt,
         shattDefInf: shattInfDef,
         shattDefTank: shattTankDef,
      });
      
   } else if (factionFrom === "Russia"){

      checkTerritoriesFrom(docRefRussian)
      addTerritoriesTo(docRefRussian)
      clearEnemyTerritory(docRefGerman)

      updateDoc(docRefGame, {
         germanTerritoryPoints: increment(-economyTo),
         TerritoryPoints: increment(economyTo),
      })

      updateDoc(docRefGame, {
         lastRussianMapAction: Date.now()/1000
      })

      const docSnapGerman = await getDoc(docRefGerman);
      const docSnapRussian = await getDoc(docRefRussian)

      return({
         //russianTerritories: docSnapRussian.data().territories,
         //germanTerritories: docSnapGerman.data().territories,
         shattAttInf: shattInfAtt,
         shattAttTank: shattTankAtt,
         shattDefInf: shattInfDef,
         shattDefTank: shattTankDef,
      });
   }
}
export async function AttackCommand2 (gameId, oldSelectionName, attackedSelection){
   //check if they territories of the attacking factions are removed from frontline
   //territories of attacking faction bordering conquered territory 
   //check ct if any hostile except for the conquered territory.

   const docRefFrom = doc(db, "games", gameId, "territories", oldSelectionName);
   const docSnapFrom = await getDoc(docRefFrom);
   const factionFrom = docSnapFrom.data().controlledBy;
   const docRefTo = doc(db, "games", gameId, "territories", attackedSelection);
   const docSnapTo = await getDoc(docRefTo);   
   const neighbors = docSnapTo.data().neighbors;
   var docRefAttack;
   var docRefDefence;

   updateDoc(doc(db, "games", gameId), {
      actionsLog: arrayUnion({faction: factionFrom, type: "attack", time: Date.now(), territory: attackedSelection})
   })

   if (factionFrom === "Germany"){
      docRefAttack = doc(db, "games", gameId, "frontline", "german");
      docRefDefence = doc(db, "games", gameId, "frontline", "soviet");
      
   } else {
      docRefAttack = doc(db, "games", gameId, "frontline", "soviet");
      docRefDefence = doc(db, "games", gameId, "frontline", "german");
   }
   
   await updateDoc(docRefDefence, {
      territories: arrayRemove(attackedSelection)
   })

   //territory itself
   const hostileFree = await CheckNeighborsSpecial(gameId, attackedSelection, factionFrom);
   if (hostileFree === false){
      await updateDoc(docRefAttack, {
         territories: arrayUnion(attackedSelection)
      })
   } 
   
   //get all hostileneighbors of the territory that is being attacked
   let hostileBeforeChange = await hostileNeighbors(gameId, neighbors, docSnapTo); 

   for(let i = 0; i<hostileBeforeChange.length; i++){
      const hostileFree = await CheckNeighborsSubtracted(gameId, hostileBeforeChange[i], attackedSelection, factionFrom);
      if (hostileFree){
         await updateDoc(docRefAttack, {
            territories: arrayRemove(hostileBeforeChange[i])
         })
      }
   }

   var friendlyBeforeChange = await friendlyNeighbors(gameId, neighbors, docSnapTo); 
   for(let i = 0; i<friendlyBeforeChange.length; i++){
      const hostileFree = await CheckNeighbors(gameId, friendlyBeforeChange[i]);
      if (hostileFree ){}
      else {
         const docSnapFrontLine = await getDoc(docRefDefence)
         const territories = docSnapFrontLine.data().territories;
         for(let j = 0; j<territories.length; j++){
            if (friendlyBeforeChange[i]=== territories[j]){
               //nothing
            } else {
               await updateDoc(docRefDefence, {
                  territories: arrayUnion(friendlyBeforeChange[i])
               })
            }
         }
      }
   }
   
   updateDoc(docRefTo, {
      controlledBy: factionFrom,
      defenceLevel: 0,
   })
   
}
export async function ArchiveWin (gameId, winnerFaction, user){
   CreditWins(gameId, winnerFaction);
   ArchiveGame(gameId, winnerFaction, user, true)
}
export async function CreditWins (gameId, winnerFaction, user){
   const docSnapGame = await getDoc(doc(db, "games", gameId))
   if(winnerFaction === "Germany"){
      const playersGermanyArray = docSnapGame.data().playersGermany;
      for(let i = 0; i < playersGermanyArray; i++){
         updateDoc(doc(db, "users", playersGermanyArray[i]), {
            wins: increment(1),
         });
      }
   } else if(winnerFaction === "Russia"){
      const playersRussiaArray = docSnapGame.data().playersRussia;
      for(let i = 0; i < playersRussiaArray; i++){
         updateDoc(doc(db, "users", playersRussiaArray[i]), {
            wins: increment(1),
         });
      }
   }
}

export async function ArchiveGame (gameId, faction, user, winBoolean){
   await updateDoc(doc(db, "users", user.uid), {
      games: increment(1)
   })
   const docRefGame = doc(db, "games", gameId);
   const docSnapGame = await getDoc(docRefGame);

   const arrayContains = docSnapGame.data().players.some(element => {
      if (element.uid === user.uid){
         return true;
      } else {
         return false;
      }
   })

   if (arrayContains){

      const docRefTerritories = doc(db, "games", gameId, "german", "territories");
      const docSnapTerritories = await getDoc(docRefTerritories)
      const docRefPastGame = doc(db, "users", user.uid, "pastGames", docSnapGame.data().id);

      if (winBoolean === true){
         updateDoc(doc(db, "users", user.uid),{
            wins: increment(1)
         });
         setDoc(docRefPastGame, {
         victory: "yes",
         id: docSnapGame.data().id,
         scenario: docSnapGame.data().scenario,
         playersGermany: docSnapGame.data().playersGermany,
         playersRussia: docSnapGame.data().playersRussia,
         territories: docSnapTerritories.data().territories,
         });
      }  else {
         updateDoc(doc(db, "users", user.uid),{
            losses: increment(1)
         });
         setDoc(docRefPastGame, {
         victory: "no",
         id: docSnapGame.data().id,
         scenario: docSnapGame.data().scenario,
         playersGermany: docSnapGame.data().playersGermany,
         playersRussia: docSnapGame.data().playersRussia,
         territories: docSnapTerritories.data().territories,
         });
      }

      await deleteGameSimplified(user, gameId);

      updateDoc(docRefGame, {
         players: arrayRemove({nickName: user.nickName, uid: user.uid})
      })

      if (faction === "Germany"){
         updateDoc(docRefGame, {
            playersGermany: arrayRemove({nickName: user.nickName, uid: user.uid})
         })
      } else if (faction === "Russia"){
         updateDoc(docRefGame, {
            playersRussia: arrayRemove({nickName: user.nickName, uid: user.uid})
         })
      }

      if (docSnapGame.data().players.length === 1 || docSnapGame.data().playerCount === "Singleplayer"){
         deleteGame(user, gameId)
      }
   }
}