import React, { useState} from 'react'
import { db } from '../../../firebase-config';
import { doc, getDoc, setDoc, updateDoc, arrayUnion } from 'firebase/firestore';
import {baltic_states, finnish_forward, south_leningrad, east_poland, east_romania, 
    smolensk_area, east_minsk, northern_baltics, german_starting}
    from './territories-short-categorized';
import { german_taifun_short, russian_taifun_short } from './taifun-short'

export async function setTerritoriesTaifun(gameId){

    await setDoc(doc(db, "games", gameId, "german", "territories"), {
        territories: german_taifun_short
    });
    await setDoc(doc(db, "games", gameId, "russian", "territories"), {
        territories: russian_taifun_short
    });

    const docRefGame = doc(db, "games", gameId);
    const docSnapGame = await getDoc(docRefGame);

    
    updateDoc(doc(db, "games", gameId), {
    loaded5: true,
    })

    if (docSnapGame.data().loaded1 === true & docSnapGame.data().loaded2 === true & docSnapGame.data().loaded3 === 7 & docSnapGame.data().loaded4 === true & docSnapGame.data().loaded6 === true){
    }
}