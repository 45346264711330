import React, {useState, useEffect} from 'react'
import { UpgradeableItem } from './../UpgradeableItem';
import { useLocation} from 'react-router-dom';
import { useSelector } from "react-redux"
import { db } from '../../../../../firebase-config';
import { doc, getDoc, setDoc, updateDoc, arrayUnion} from "firebase/firestore";

const MultiplayerGameUpgrades = () => {

   const location = useLocation();
   const gameId = location.state.id;
   const user = useSelector((state) => state.user.value)
   const pts = useSelector((state) => state.pts.value)
   const faction = useSelector((state) => state.faction.value);

   const docRefGame = doc(db, "games", gameId);
   const docRefGameEco = doc(db, "games", gameId, "players", user.uid, "ww2", "economy");

   const [economyLevel, setEconomyLevel] = useState(1)
   const [infrastructureLevel, setInfrastructureLevel] = useState(1)
   const [tankProductionLevel, setTankProductionLevel] = useState(1)
   const [armyTrainingLevel, setArmyTrainingLevel] = useState(1)
   const [recruitmentLevel, setRecruitmentLevel] = useState(1)
   const [propagandaLevel, setPropagandaLevel] = useState(1)
   const [tankResearchLevel, setTankResearchLevel] = useState(1)
   const [armyResearchLevel, setArmyResearchLevel] = useState(1)
   const [infantryResearchLevel, setInfantryResearchLevel] = useState(1)
   const [playerFaction, setPlayerFaction] = useState(null)
   const [playerCount, setPlayerCount] = useState(1)
   const [loaded, setLoaded] = useState(false)

   var upgradeReduction = 0;

   if (playerFaction === "germany"){
      if (economyLevel === 2 || economyLevel === 3 ){
         upgradeReduction = 1
      } else if (economyLevel === 4 || economyLevel === 5 ){
         upgradeReduction = 2
      } else if (economyLevel === 6 || economyLevel === 7 ){
         upgradeReduction = 3
      } else if (economyLevel === 8 || economyLevel === 9 ){
         upgradeReduction = 4
      } else if (economyLevel === 10 || economyLevel === 11 ){
         upgradeReduction = 5
      }
   }
   else if (playerFaction === "russia"){
      if (economyLevel >= 2 & economyLevel < 7 ){
      upgradeReduction = 1
      } else if (economyLevel >= 7){
      upgradeReduction = 2
      }
   }

  useEffect(()=> {

    async function fetchData(){
      const docSnapEco = await getDoc(docRefGameEco);
      setEconomyLevel(docSnapEco.data().economyLevel)
      setInfrastructureLevel(docSnapEco.data().infrastructureLevel)
      setTankProductionLevel(docSnapEco.data().tankProductionLevel)
      setArmyTrainingLevel(docSnapEco.data().armyTrainingLevel)
      setRecruitmentLevel(docSnapEco.data().recruitmentLevel)
      setTankResearchLevel(docSnapEco.data().tankResearchLevel)
      setPropagandaLevel(docSnapEco.data().propagandaLevel)
      setArmyResearchLevel(docSnapEco.data().armyResearchLevel)
      //setWinterPreparationLevel(docSnapEco.data().winterPreparationLevel)
      setInfantryResearchLevel(docSnapEco.data().infantryResearchLevel)

      const docSnapGame = await getDoc(docRefGame);

      setPlayerCount(docSnapGame.data().playerCount)
      setLoaded(true)
    }

    fetchData()
  },[])

  function renderBanners (){
   if(faction.faction === "Germany"){
      return  <img className="faction-banner" src="/images/icons/german-banner.svg" 
      height="250px" alt="german-banner" />
   } else {
      return <img className="faction-banner" src="/images/icons/soviet-banner.svg" 
      height="250px" alt="soviet-banner" />
   }
  }

  if (loaded === false) {
   return <section className='game-info-inner'></section>
  }

   return (
   <section className='game-info-inner'>
         {renderBanners()}
      <div>
      
      <UpgradeableItem
         productionTypeText="Propaganda Level"
         productionType="propagandaLevel"
         description="Increases the amount of points you get from productivity time by 0.1 per 30 mins"
         productionLevel={propagandaLevel}
         upgradeReduction={upgradeReduction}
         playerFaction={null}
         upgradeItem={()=>setPropagandaLevel(propagandaLevel+1)}
      />
      <UpgradeableItem
         productionTypeText="Economy Level"
         productionType="economyLevel"
         description="Increases the amount of points you naturally generate every hour by 10%."
         productionLevel={economyLevel}
         upgradeReduction={upgradeReduction}
         playerFaction={null}
         upgradeItem={()=>setEconomyLevel(economyLevel+1)}
      />
      <UpgradeableItem
         productionTypeText="Inftrastructure Level"
         productionType="infrastructureLevel"
         description="Increases the movement capabilities of your troops.
         (lvl1: 1 distance per move, 
         lvl2: +2 free moves, 
         lvl3: +1 distance, 
         lvl4: +1 FM,
         lvl5: +1 FM,
         lvl 6: +1 FM, 
         lvl 7: +1 distance,
         lvl 8: +1 FM,
         lvl9: +1 FM, lvl 10: unlimited free moves)"
         productionLevel={infrastructureLevel}
         upgradeReduction={upgradeReduction}
         playerFaction={null}
         upgradeItem={()=>setInfrastructureLevel(infrastructureLevel+1)}
      />
      <UpgradeableItem
         productionTypeText="Tank Production Level"
         productionType="tankProductionLevel"
         description="Reduces the amount of time it takes to automatically spawn new tank divisions by 5%."
         productionLevel={tankProductionLevel}
         upgradeReduction={upgradeReduction}
         playerFaction={null}
         upgradeItem={()=>setTankProductionLevel(tankProductionLevel+1)}
      />
      <UpgradeableItem
         productionTypeText="Tank Research Level"
         productionType="tankResearchLevel"
         description="Increases fighting strength of tank divisions by 20%."
         productionLevel={tankResearchLevel}
         upgradeReduction={upgradeReduction}
         playerFaction={playerFaction}
         upgradeItem={()=>setTankResearchLevel(tankResearchLevel+1)}
      />
      <UpgradeableItem
         productionTypeText="Recruitment Level"
         productionType="recruitmentLevel"
         description="Reduces the amount of time it takes to automatically spawn new infantry divisions by 5%."
         productionLevel={recruitmentLevel}
         upgradeReduction={upgradeReduction}
         playerFaction={playerFaction}
         upgradeItem={()=>setRecruitmentLevel(recruitmentLevel+1)}
      />
      <UpgradeableItem
         productionTypeText="Inf. Research Level"
         productionType="infantryResearchLevel"
         description="Increases fighting strength of infantry divisions by 20%."
         productionLevel={infantryResearchLevel}
         upgradeReduction={upgradeReduction}
         playerFaction={playerFaction}
         upgradeItem={()=>setInfantryResearchLevel(infantryResearchLevel+1)}
      />
      
      </div>
   </section>
   )
}

export default MultiplayerGameUpgrades

/*
<UpgradeableItem
         productionTypeText="Army Training Level"
         productionType="armyTrainingLevel"
         description="Offers diverse benefits to your troops.
         (lvl1: -1 cost to attack, lvl2: -1 cost to assault, lvl 3: -10% shatter probability, lvl4: +1 cost for enemy to attack,
         lvl5: -1 cost to attack, lvl 6: -1 cost to assault, lvl 7: -10% shatter probability, lvl 8: +1 cost for enemy to attack,
         lvl9: -1 cost to attack, lvl 10: -1 cost to attack)"
         productionLevel={armyTrainingLevel}
         upgradeReduction={upgradeReduction}
         playerFaction={playerFaction}
         upgradeItem={()=>setArmyTrainingLevel(armyTrainingLevel+1)}
      />
      {playerFaction === "germany" ? 
      <UpgradeableItem
         productionTypeText="Army Research"
         productionType="armyResearchLevel"
         description="Offers diverse benefits to your troops.
         (lvl2: -1 cost to upgrade, lvl 3: -1 cost of artillery barrage, lvl4: -1 cost to upgrade,
         lvl5: -1 cost to fortify, lvl 6: -1 cost to upgrade, lvl 7: +10 damage artillery barrage, lvl 8: -1 cost to upgrade,
         lvl9: -3 daily cost to prepare offensive, lvl 10: unlock counter attack ability)"
         productionLevel={armyResearchLevel}
         upgradeReduction={upgradeReduction}
         playerFaction={playerFaction}
         upgradeItem={()=>setArmyResearchLevel(armyResearchLevel+1)}
      />
      :
      <UpgradeableItem
         productionTypeText="Army Research Level"
         productionType="armyResearchLevel"
         description="Offers diverse benefits to your troops.
         (lvl2: -1 cost to upgrade, lvl 3: -5 cost on all actions, lvl4: +20% strength of fortifications
         lvl5: -1 cost to fortify, lvl 6: -1 cost of artillery barrage lvl 7: -1 cost to upgrade, lvl 8: +20% strength of fortifications,
         lvl9: -1 cost of artillery barrage, lvl 10: unlock the katyusha ability)"
         productionLevel={armyResearchLevel}
         upgradeReduction={upgradeReduction}
         playerFaction={playerFaction}
         upgradeItem={()=>setArmyResearchLevel(armyResearchLevel+1)}
      />
      }
*/