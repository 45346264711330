import React, {useState, useEffect} from 'react';
import SvgComponent from '../svgComponents/SvgZeichnung';
import { db } from '../../../../firebase-config';
import { doc, getDoc, query, collection, getDocs, setDoc, updateDoc, arrayUnion, arrayRemove, increment} from "firebase/firestore";
import { useLocation} from 'react-router-dom';
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import GameInfo from './GameInfo';
import TimeTracking from './TimeTrackingNavigation/TimeTracking/TimeTracking';
import { setPts } from "../../../../features/pts";
import { setDeployableDivs } from '../../../../features/deployableDivs.js';
import { setFaction } from '../../../../features/faction.js';
import { deleteGame } from '../../PreGame/deleteGame.js';
import { Generate } from './Generate.js';
import { ArchiveGame, ArchiveWin } from './Commands.js';
import { computeGame } from '../../PreGame/compute-game.js';
import GameNavigation from './GameNavigation.jsx';
import { useNavigate } from "react-router-dom";
import { AImoves } from './AImoves';
import ActionsHistory from './ActionsHistory';
import PreLoadedGame from './PreLoadedGame';
import TimeTrackingNav from './TimeTrackingNavigation/TimeTrackingNav';

const MultiplayerGameMain = (props) => {

   const location = useLocation();
   const dispatch = useDispatch();
   const navigate = useNavigate();
   const gameId = location.state.id;
   const user = useSelector((state) => state.user.value);
   const deployableDivs = useSelector((state) => state.deployableDivs.value);
   const faction = useSelector((state) => state.faction.value);

   const docRefGame = doc(db, "games", gameId);
   const docRefPlayers = doc(db, "games", gameId, "players", user.uid);
   const docRefGerman = doc(db, "games", gameId, "german", "territories");
   const docRefRussia = doc(db, "games", gameId, "russian", "territories");

   const [gameRerender, setGameRerender] = useState(false)
   const [gameCreationBar, setGameCreationBar] = useState(0)
   const [loaded, setLoaded] = useState(false);
   const [gameLoaded, setGameLoaded] = useState(false);
   const [selectedTerritories, setSelectedTerritories] = useState();
   const [russianOccupied, setRussianOccupied] = useState();
   const [showMap, setShowMap] = useState(true);
   const [victory, setVictory] = useState(1);
   const [deleting, setDeleting] = useState(false);
   const [lastOtherMapAction, setLastOtherMapAction] = useState(0)
   const [archiving, setArchiving] = useState(false)

   useEffect( () => {

      async function fetchDataGame (){
         
         const docSnapGame = await getDoc(docRefGame);

         //Hiding game and function if it is still initializing
         if (docSnapGame.data().loaded === true){
            const docSnapGerman = await getDoc(docRefGerman);
            const docSnapRussia = await getDoc(docRefRussia);
            setSelectedTerritories(docSnapGerman.data().territories);
            setRussianOccupied(docSnapRussia.data().territories);
            setGameLoaded(true)
            setLoaded(true)
         } else if (docSnapGame.data().loaded1 === true & docSnapGame.data().loaded2 === true & docSnapGame.data().loaded3 === 7 & docSnapGame.data().loaded4 === true & docSnapGame.data().loaded5 === true & docSnapGame.data().loaded6 === true){
            updateDoc(docRefGame, {
               loaded: true,
            })
            const docSnapGerman = await getDoc(docRefGerman);
            const docSnapRussia = await getDoc(docRefRussia);
            setSelectedTerritories(docSnapGerman.data().territories);
            setRussianOccupied(docSnapRussia.data().territories);
            setGameLoaded(true);
            setLoaded(true);
         } else if (docSnapGame.data().loaded === "deleting"){
            setDeleting(true)
            setLoaded(true)
            await deleteGame(user, gameId);
            navigate("/multiplayer")
         } else {
            
            const currentTime = Date.now();
            const gameCreationTime = docSnapGame.data().gameCreation;
            const timeSinceGameCreationMins = Math.floor((currentTime - gameCreationTime)/1000/60);
            setLoaded("creating")

            if (timeSinceGameCreationMins > 25/60){
               const docSnapPlayers = await getDoc(docRefPlayers);
               await computeGame("normal", gameId, user, docSnapPlayers.data().position, docSnapGame.data().playerCount, docSnapGame.data().timeframe, docSnapGame.data().state, docSnapGame.data().scenario)

            } else if (timeSinceGameCreationMins < 25/60){
            }

            const fetchDataGameBar = async() => {
               const docSnapComputation = await getDoc(docRefGame)
               let loadedCount = 0;
               if (docSnapComputation.data().loaded1){
                  loadedCount += 10
               }
               if (docSnapComputation.data().loaded2){
                  loadedCount += 10
               }
               if (docSnapComputation.data().loaded4){
                  loadedCount += 10
               }
               if (docSnapComputation.data().loaded5){
                  loadedCount += 10
               }
               if (docSnapComputation.data().loaded6){
                  loadedCount += 10
               }
               loadedCount += docSnapComputation.data().loaded3 * 20
               setGameCreationBar(loadedCount)
               if(loadedCount === 190){
                  updateDoc(docRefGame, {
                     loaded: true,
                  })
                  const docSnapPlayers = await getDoc(docRefPlayers);
                  if (docSnapPlayers.data().position === "Germany"){
                     dispatch(setFaction({ faction: "Germany", nonFaction: "Russia"}));
                  } else {
                     dispatch(setFaction({ faction: "Russia", nonFaction: "Germany"}));
                  }
                  const docSnapGerman = await getDoc(docRefGerman);
                  const docSnapRussia = await getDoc(docRefRussia);
                  setSelectedTerritories(docSnapGerman.data().territories);
                  setRussianOccupied(docSnapRussia.data().territories);
                  setGameLoaded(true)
                  setLoaded(true)
                  clearInterval(interval)
                  return;
               }
            }
            const interval = setInterval(()=> {
               if(loaded === true){
                  clearInterval(interval)
               }
               fetchDataGameBar()

            }, 3000) 
            return () => clearInterval(interval)

         }

         //Create redux variables
         const docSnapPlayers = await getDoc(docRefPlayers);
         if (docSnapPlayers.data().position === "Germany"){
            dispatch(setFaction({ faction: "Germany", nonFaction: "Russia"}));
         } else {
            dispatch(setFaction({ faction: "Russia", nonFaction: "Germany"}));
         }
         
         //victory
         setVictory(docSnapGame.data().victory)
         
         const loadedPoints = (Math.floor(docSnapPlayers.data().pointsToSpend).toFixed(1))
         dispatch(setPts({ pts: loadedPoints}));

         //generate new divisions and points
         const lastPayoutSec = docSnapPlayers.data().lastPayout;
         const currentTimeSec = Math.floor(Date.now()/1000);
         const timeDifferenceMins = (currentTimeSec-lastPayoutSec)/60;
         const docSnapGameData = await getDoc(docRefGame)
         console.log((currentTimeSec - docSnapGameData.data().lastRussianMapAction)/60/60)

         if (timeDifferenceMins > 60) {

            //Generate
            const docSnapPlayersWW2 = await getDoc(doc(db, "games", gameId, "players", user.uid, "ww2", "army"));
            const generated = await Generate(gameId, user, faction, docSnapPlayers.data().pointsToSpend, deployableDivs, currentTimeSec, timeDifferenceMins, lastPayoutSec);
            dispatch(setPts({ pts: generated.generatedPoints}));
            dispatch(setDeployableDivs({ infDivs: docSnapPlayersWW2.data().deployableInfDivs+generated.generatedInfDivs, tankDivs: docSnapPlayersWW2.data().deployableTankDivs+generated.generatedTankDivs}));

            //Check if player is AFK
            
            if (docSnapGameData.data().playerCount !== "Singleplayer" & docSnapGameData.data().players.length > 1){
               if(faction.faction === "Germany"){
                  if(((currentTimeSec - docSnapGameData.data().lastRussianMapAction)/60/60) > 48){
                     alert("Russian player is AFK for more than 48 hours and loses the game.")
                     updateDoc(docRefGame, {
                        victory: 10
                     })
                     setVictory(10)
                  }
               } else if(faction.faction === "Russia"){
                  if(((currentTimeSec - docSnapGameData.data().lastGermanMapAction)/60/60) > 48){
                     alert("German player is AFK for more than 48 hours and loses the game.")
                     updateDoc(docRefGame, {
                        victory: -10
                     })
                     setVictory(-10)
                  }
               }
            }

            //AI moves
            if (docSnapGame.data().playerCount === "Singleplayer"){
               const readyToUpdate = await AImoves(gameId, docSnapPlayers.data().position, user)
               if (readyToUpdate){
                  update()
               }
            }

         }

         //update activity for AFK detection
         if(faction.faction === "Germany"){
            setLastOtherMapAction(docSnapGame.data().lastRussianMapAction);
         } else if(faction.faction === "Russia"){
            setLastOtherMapAction(docSnapGame.data().lastGermanMapAction);
         }

         //check for changes if game is not AI
         /*
         if (docSnapGame.data().playerCount !== "Singleplayer"){
            const interval = setInterval(()=> {
               checkTerritoryChanges()
            }, 60000) 
            return () => clearInterval(interval)   
         }
         */
      }

      async function update () {
         console.log("check for changes..")
         const docSnapGerman = await getDoc(docRefGerman);
         const docSnapRussia = await getDoc(docRefRussia);
         setSelectedTerritories(docSnapGerman.data().territories);
         setRussianOccupied(docSnapRussia.data().territories);
      }

      fetchDataGame();

      async function checkTerritoryChanges () {
         const docSnapGame = await getDoc(docRefGame);
         console.log(docSnapGame.data())
         console.log(docSnapGame.data().lastGermanMapAction)

         if((faction.faction === "Russia" & docSnapGame.data().lastGermanMapAction !== lastOtherMapAction) ||
            (faction.faction === "Germany" & docSnapGame.data().lastRussianMapAction !== lastOtherMapAction)){
            setLastOtherMapAction()
            console.log("update triggered")
            update()
         }
      }
   }, [])


   function gameComputed(){
      //if(gameLoaded === true){
         return <section className='map-container-outer'>
         <SvgComponent
            gameid={gameId}
            selectedTerritories={selectedTerritories}
            russianOccupied={russianOccupied}
         />
         <ActionsHistory/>
         
      </section>
      //} else {
      //   return <h1>Creating. The map will be ready momentarily.</h1>
      //}
   }

   if (deleting){
      return <h1>DELETING... Please wait.</h1>
   }

   if (loaded === "creating"){
      return <PreLoadedGame percentage={gameCreationBar}/>
   }

   if (loaded === false ){
      return <h1>Loading...</h1>
   }

   function victoryTop (){
      if (victory === 10 ){
         return <>
         <h1>GERMANY wins!</h1>
         <iframe width="560" height="315" src="https://www.youtube.com/embed/vlDpF7jKGPA?autoplay=1&loop=1" title="Erika" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
         </>
      } else if (victory === -10 ){
         return <>
         <h1>THE SOVIET UNION wins!</h1>
         <iframe width="560" height="315" src="https://www.youtube.com/embed/SAzofLc3DMk?autoplay=1&loop=1" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
         </>
      }
      else if (victory === 15 ){
         return <>
         <h1>PEACE AGREEMENT REACHED!</h1>
         <img width="560" height="315" src="/images/components/Peace.jpg" title="peace" frameBorder="0" alt="peace"/>
         </>
      }
   }

   function victoryBottom (){
      if (archiving === true) {
         return <h4>Archiving...</h4>
      } else if (victory === 10 & faction.faction === "Germany" || victory === -10 & faction.faction === "Russia"){
         return <>
         <h1 className="continue_victory" onClick={()=>archive(true)}>Continue</h1>
         <h4>(Claim win and move game to archive)</h4>
         </>
      } else if (victory === 10 & faction.faction === "Russia" || victory === -10 & faction.faction === "Germany"){
         return <>
         <h1 className="continue_victory" onClick={()=>archive(false)}>Continue</h1>
         <h4>(Move game to archive)</h4>
         </>
      } else if (victory === 15){
         return <>
         <h1 className="continue_victory" onClick={()=>archive(false)}>Continue</h1>
         <h4>(Move game to archive)</h4>
         </>
      }
   }

   function victoryScreen (){
      if (victory === 10 || victory === -10 || victory === 15){
         return <>
         {victoryTop()}
         {victoryBottom()}
         </>
      }
   }
         
   function archive(win){
      setArchiving(true)
      if (win === true){
         ArchiveWin(gameId, faction.faction, user)
      } else {
         ArchiveGame(gameId, faction.faction, user)
      }

      const timeout = setTimeout(()=>{
         navigate("/multiplayer")
      }, 8000)
   }

   return <>
      <section className='game-main-container'>
         {gameComputed()}               
         <div className='game-right-side'>
         <div className='victory-screen'>
         {victoryScreen()}
         </div>
            <section className='gameInfoGrid'>
               <GameInfo />
            </section>
            <GameNavigation
               setVictory={(number)=>setVictory(number)}
               setSelectedTerritories={(selectedT)=>setSelectedTerritories(selectedT)}
               setRussianOccupied={(selectedRO)=>setRussianOccupied(selectedRO)}
            />
            <section>
               <TimeTrackingNav/>
            </section>
            
         </div>
      </section>
   </>
}

export default MultiplayerGameMain

/* TODO

list of open multiplayer games only showing open games
Still checking ai moves in multiplayer game?

1.1 ->   FIXED: Log -expand is the wrong way
1.2 ->   FIXED: Log doesnt show ai moves, should also show AI reinforcements, Artillery, assaults
1.6 ->   FIXED: Mypastgame collapseable
1.9 ->   FIXED: End the game if someone doesnt move for 2 days
1.13 ->  FIXED: Generating new divisions etc not working
1.7 ->   FIXED: Cookies notification properly
1.3 ->   FIXED FOR AI, MAYBE FOR HUMANS: Update changes not really working, map changes and uni changes
1.4 ->   IDK IF FIXED: AI shouldnt attack territory twice or smth when randomly chooses same constelation, maybe have the database call every time fresh or make it that if it was already then tae a new one
1.12 ->  FIXED: Archiving... in multiplayer game when archiving. Archiving doesnt work
1.11 ->  FIXED: End game by peace
2.2 ->   MAYBE FIXED: Delete game bug
2.3 ->   MAYBE FIXED: Add creating and deleting animations, Smoothen out the creation process, automatic rerender after creation is done
         FIXED: Cannot track time while game is creating
         FIXED: Automatically refresh when done rendering
2.6 ->   FIXED: Time tracking history
2.10 ->  FIXED: Should be able to see enemy productive hours
FIXED: Default setting singleplayer

1.5 ->   Test multiplayer games

Make past game screen little nicer

Make youtube tutorial video and make it playable on login screen
Add Discord icon


Subreddits: 

gamification
quantifiedself
webdev
getmotivated


getdisciplined
getstudying
selfimprovement
r/productivity

It started as a learn how to program project and took me about 6-8 months to create. The game is supposed to offer a better alternative to the typical productivity games out there that is more engaging and more motivating. I sometimes did challenges with other ambitious friends in which everyone tracked their time to see who was most productive to motivate each other. Also games always performed very well in triggering my motivation in the past, so now that it is connected to productivity time, 


2.7 ->   AI victory condition
2.8 ->   Simplified encirclements: none or only 1 neighboring territory 
2.9 ->   Total division count displayed in information tab, also destroyed divisions
//2.3 ->   Free attacks should be released every day still.?

2.1 ->   Firebase security rules
3.1 ->   Fortification delay
3.2 ->   Doubleclick on territory to select quicker
3.3 ->   Urban fighting bonuses
3.4 ->   Riverbonus check, with AI?

4.1 ->   Tryout restyling everything
4.2 ->   tank casualties taken from tanks still behind, not from attacking ones
4.3 ->   Cannot change start time of timer while it is going
4.4 ->   Center map on creation to be more centered, not finland
4.5 ->   Territory neighbors are correct?
4.6 ->   Remove that one cannot fortify the front line
4.7 ->   German territories going into minus
1.10 ->  Send an email that someone joined their game.

5.1 ->   Add profile picture
5.2 ->   Chat box functionality
5.3 ->   Quicker game mode
5.4 ->   Ddashboard graphics. npm chartjs
5.5 ->   Entrenchment bonus
5.6 ->   City images svg
5.7 ->   Retreat all function in python
5.8 ->   Leaderboard with AI times
5.9 ->   AI difficulty settings
5.10 ->  Enable encirclements
5.11 ->  Tanks move faster

6.1 ->   Reduce rerenders

?.? ->     railway movements

15.b  If at least 3 divisions in city -> requires 1 assault per city tier to be able to take the city
15.d  Cities should automatically get 1 assault thingy get applied every 2 days if they are encircled
15.e  Odessa should go at 1/3 the rate, as it gets supplied by sea, Sevastopol 1/5

*/
