import React, {useState, useEffect} from 'react'
import { db } from '../../../../../firebase-config';
import { doc, getDoc, increment, arrayRemove, arrayUnion, setDoc, updateDoc} from "firebase/firestore";
import { useLocation} from 'react-router-dom';
import { useSelector } from "react-redux"
import { useDispatch } from "react-redux"
import { setPts } from "../../../../../features/pts";
import { setDeployableDivs } from '../../../../../features/deployableDivs';
import { Bases } from '../../svgComponents/Bases.js';
import { daugavaLine, dniperLine, leningradArea, moscowArea, smolenskLine } from '../../svgComponents/ReinforcementLines';
import { CheckNeighbors } from '../CheckNeighbors';
import { AllTerritories } from '../../svgComponents/AllTerritories';

const MultiplayerGameActions = (props) => {

   //basic setup
   const location = useLocation();
   const dispatch = useDispatch();
   const gameId = location.state.id;
   const user = useSelector((state) => state.user.value)
   const pts = useSelector((state) => state.pts.value)
   const deployableDivs = useSelector((state) => state.deployableDivs.value)
   const faction = useSelector((state) => state.faction.value);
   const selection = useSelector((state) => state.selection.value);

   const retreatAllCost = 5;
   const reinforceDaugavaCost = 20;
   const reinforceDniperCost = 20;
   const reinforceSmolenskCost = 20;
   const reinforceLeningradCost = 30;
   const reinforceMoscowCost = 40;
   const randomReinforceCost = 10;

   const docRefGame = doc(db, "games", gameId);
   const docRefActions = doc(db, "games", gameId, "ww2", "actions");
   const docRefGerman = doc(db, "games", gameId, "german", "territories");
   const docRefRussian = doc(db, "games", gameId, "russian", "territories");

   const [reallySurrender, setReallySurrender] = useState(false)
   const [reallyPeace, setReallyPeace] = useState(false)
   const [peaceProposed, setPeaceProposed] = useState("no")
   const [playerCount, setPlayerCount] = useState("Singleplayer")

   const [retreatedAll, setRetreatedAll] = useState(false)
   const [daugavaRF, setdaugavaRF] = useState(false)
   const [smolenskRF, setSmolenskRF] = useState(false)
   const [dniperRF, setDniperRF] = useState(false)
   const [leningradFortified, setLeningradFortified] = useState(false)
   const [moscowFortified, setMoscowFortified] = useState(false)
   const [leningradRF, setLeningradRF] = useState(false)
   const [moscowRF, setMoscowRF] = useState(false)

   const [unlocked, setUnlocked] = useState(false);
   const [loaded, setLoaded] = useState(false);
   const [chooseRfLocationNorth, setChooseRfLocationNorth] = useState(false)
   const [chooseRfLocationCenter, setChooseRfLocationCenter] = useState(false)
   const [chooseRfLocationSouth, setChooseRfLocationSouth] = useState(false)
   const [chooseRfLocationDeepSouth, setChooseRfLocationDeepSouth] = useState(false)
   const [chooseRfLocationFinland, setChooseRfLocationFinland] = useState(false)
   const [chooseRfLocationDeployable, setChooseRfLocationDeployable] = useState(false)

   const [germanRfNorth, setGermanRfNorth] = useState("East_Prussia")
   const [germanRfCenter, setGermanRfCenter] = useState("Poland")
   const [germanRfSouth, setGermanRfSouth] = useState("Poland")
   const [germanRfDeepSouth, setGermanRfDeepSouth] = useState("Romania")

   const [northRFBases, setNorthRFBases] = useState(["East Prussia"])
   const [centerRFBases, setCenterRFBases] = useState(["Poland"])
   const [southRFBases, setSouthRFBases] = useState(["Poland"])
   const [deepSouthRFBases, setDeepSouthRFBases] = useState(["Romania"])

   const [russianBases, setRussianBases] = useState(["Moscow"], ["Stalingrad"])
   
   useEffect(()=>{
      
      async function fetchData(){

         const docSnapGame = await getDoc(docRefGame);
         setPlayerCount(docSnapGame.data().playerCount)
         setPeaceProposed(docSnapGame.data().peace)
         
         const docSnap = await getDoc(docRefActions)
         setRetreatedAll(docSnap.data().retreatedAll)
         setdaugavaRF(docSnap.data().daugavaRF)
         setSmolenskRF(docSnap.data().smolenskRF)
         setDniperRF(docSnap.data().dniperRF)
         setLeningradFortified(docSnap.data().leningradFortified)
         setMoscowFortified(docSnap.data().moscowFortified)
         setGermanRfNorth(docSnap.data().RFBaseNorth)
         setGermanRfCenter(docSnap.data().RFBaseCenter)
         setGermanRfSouth(docSnap.data().RFBaseSouth)
         setGermanRfDeepSouth(docSnap.data().RFBaseDeepSouth)
         setLeningradRF(docSnap.data().RFLeningrad)
         setMoscowRF(docSnap.data().RFMoscow)
         setRussianBases(docSnap.data().RussianBases)
         
         
         setUnlocked(docSnap.data().daugavaDniperBroken)
         const docRefArmy = doc(db, "games", gameId, "players", user.uid, "ww2", "army");
         const docSnapArmy = await getDoc(docRefArmy);
         const deployableInfNum = docSnapArmy.data().deployableInfDivs;
         const deployableTankNum = docSnapArmy.data().deployableTankDivs;
         dispatch(setDeployableDivs({infDivs: deployableInfNum , tankDivs: deployableTankNum}))
         setLoaded(true)
      }

      fetchData();
   }, [])
   

   async function applyTerritoryColor(territoryName){

      const docRefTerritory = doc(db, "games", gameId, "territories", territoryName);
      const docSnapTerritory = await getDoc(docRefTerritory);
      var type;
      var path;

      if(docSnapTerritory.data().tankDivs > 0){type = "tank"}
      else if(docSnapTerritory.data().infDivs > 0){type = "inf"}
      else {type = "empty"}

      for(let i = 0; i<Bases.length; i++){
         if (territoryName === Bases[i].id){
            path = Bases[i].d;
         }
      }
      if (faction.faction === "Germany"){
         await updateDoc(docRefGerman, {
         territories: arrayRemove({name: territoryName, path: path, type: type})
         });
         await updateDoc(docRefGerman, {
            territories: arrayUnion({name: territoryName, path: path, type: "tank"})
         });
         const docSnapGerman = await getDoc(docRefGerman)
         props.setSelectedTerritories(docSnapGerman.data().territories)
      } else {
         await updateDoc(docRefRussian, {
         territories: arrayRemove({name: territoryName, path: path, type: type})
         });
         await updateDoc(docRefRussian, {
            territories: arrayUnion({name: territoryName, path: path, type: "tank"})
         });
         const docSnapRussian = await getDoc(docRefRussian)
         props.setRussianOccupied(docSnapRussian.data().territories)
      }
      
   }
   function retreatAll(){
      if (pts.pts < retreatAllCost){alert("Retreating costs 5 points. You have " + pts.pts + ".")}
      else {
         updateDoc(docRefActions, {
            retreatedAll: retreatedAll+1
         })
         setRetreatedAll(retreatedAll+1)
         updateDoc(doc(db, "games", gameId, "players", user.uid),{
            pointsToSpend: increment(-5)
         })
         dispatch(setPts({ pts: pts.pts -retreatAllCost}));
      }
   }

   async function calibrateTerritories (array, infCount, tankCount, cost) {
      const docSnapRussian = await getDoc(docRefRussian)
      for(let i = 0; i<array.length;i++){
         const exists = docSnapRussian.data().territories.some((object)=>{
            if (object.name === array[i].name){
               if (object.type === "tank"){
                  return true
               } else if (object.type === "inf" || object.type === "empty"){
                  updateDoc(docRefRussian, {
                     territories: arrayRemove({name: object.name, path: object.path, type:object.type})
                  });
                  return false
               }
            } else {
               return false
            }
         })
         if (exists === false){
            updateDoc(docRefRussian, {
               territories: arrayUnion(array[i])
            });
         } 
         const docRefTerritory = doc(db, "games", gameId, "territories", array[i].name);
         updateDoc(docRefTerritory, {
            infDivs: increment(infCount),
            tankDivs: increment(tankCount),
         })
      }
      updateDoc(doc(db, "games", gameId, "players", user.uid),{
         pointsToSpend: increment(-cost)
      })
      dispatch(setPts({ pts: pts.pts -cost}));
      const docSnapTerritories = await getDoc(docRefRussian)
      props.setRussianOccupied(docSnapTerritories.data().territories)
   }
   async function reinforceDaugava(){

      if (pts.pts < reinforceDaugavaCost){
         alert("Reinforcing costs 20 points. You have " + pts.pts + ".")
         return
      }
      calibrateTerritories(daugavaLine, 3, 0, reinforceDaugavaCost)
      
      updateDoc(docRefActions, {
         daugavaRF: true
      })
      setdaugavaRF(true)
      
   }
   function reinforceDniper(){
      if (pts.pts < 20){
         alert("Reinforcing costs 20 points. You have " + pts.pts + ".");
         return;
      }
      calibrateTerritories(dniperLine, 3, 0, reinforceDniperCost)
      updateDoc(docRefActions, {
         dniperRF: true
      })
      setDniperRF(true)
      
   }
   function reinforceSmolenskGomel(){
      if (pts.pts < 20){
         alert("Reinforcing costs 20 points. You have " + pts.pts + ".");
         return
      }
      calibrateTerritories(smolenskLine, 3, 0, reinforceSmolenskCost)

      updateDoc(docRefActions, {
         smolenskRF: true
      })
      setSmolenskRF(true)
   }
   function reinforceLeningrad(){
      if (pts.pts < 20){
         alert("Reinforcing costs 20 points. You have " + pts.pts + ".");
         return;
      }
      calibrateTerritories(leningradArea, 2, 1, reinforceLeningradCost)

      updateDoc(docRefActions, {
         leningradRF: true
      })
      setLeningradRF(true)
   }
   function reinforceMoscow(){
      if (pts.pts < reinforceMoscowCost){
         alert("Reinforcing costs 20 points. You have " + pts.pts + ".")
      }

      calibrateTerritories(moscowArea, 4, 1, reinforceMoscowCost)
      
      updateDoc(docRefActions, {
         leningradRF: true
      })
      setLeningradRF(true)
   }
   function fortifyLeningrad(){
      if (pts.pts < 10){alert("Fortifying costs 10 points. You have " + pts.pts + ".")}
      else {
         updateDoc(doc(db, "games", gameId, "territories", "Petergof"), {
            defenceLevel: increment(2)
         })
         updateDoc(doc(db, "games", gameId, "territories", "Tosno"), {
            defenceLevel: increment(2)
         })
         updateDoc(doc(db, "games", gameId, "territories", "Kirovsk"), {
            defenceLevel: increment(1)
         })
         updateDoc(doc(db, "games", gameId, "territories", "Vsevolozhsk"), {
            defenceLevel: increment(4)
         })
         updateDoc(doc(db, "games", gameId, "territories", "Leningrad"), {
            defenceLevel: increment(6)
         })
         updateDoc(doc(db, "games", gameId, "territories", "Sanatoriy"), {
            defenceLevel: increment(1)
         })
         updateDoc(doc(db, "games", gameId, "territories", "Zaporozhskoye"), {
            defenceLevel: increment(1)
         })
         updateDoc(docRefActions, {
            leningradFortified: true
         })
         setLeningradFortified(true)
      }
   }
   function fortifyMoscow(){
      if (pts.pts < 10){alert("Fortifying costs 10 points. You have " + pts.pts + ".")}
      else {
         updateDoc(doc(db, "games", gameId, "territories", "Moscow"), {
            defenceLevel: increment(8)
         })
         updateDoc(doc(db, "games", gameId, "territories", "Podolsk"), {
            defenceLevel: increment(5)
         })
         updateDoc(doc(db, "games", gameId, "territories", "Odintsovo"), {
            defenceLevel: increment(4)
         })
         updateDoc(doc(db, "games", gameId, "territories", "Zelenograd"), {
            defenceLevel: increment(4)
         })
         updateDoc(doc(db, "games", gameId, "territories", "Klin"), {
            defenceLevel: increment(3)
         })
         updateDoc(doc(db, "games", gameId, "territories", "Elektrostal"), {
            defenceLevel: increment(3)
         })
         updateDoc(doc(db, "games", gameId, "territories", "Dmitrov"), {
            defenceLevel: increment(2)
         })
         updateDoc(doc(db, "games", gameId, "territories", "Sergiev Posad"), {
            defenceLevel: increment(2)
         })
         updateDoc(docRefActions, {
            moscowFortified: true
         })
         setMoscowFortified(true)
      }
   }
   async function germanReinforceNorth(){
      if (chooseRfLocationNorth === true){
            setChooseRfLocationNorth(!chooseRfLocationNorth)
      } else {
         if (pts.pts  > 10){
            const docSnap = await getDoc(docRefActions);
            setNorthRFBases(docSnap.data().RFBasesNorth)
            setChooseRfLocationNorth(!chooseRfLocationNorth)
         } else {
            alert("Reinforcing costs 10 points. You have " + pts.pts  + ".")
         }
      }
   }
   async function germanReinforceCenter(){
      if (chooseRfLocationCenter === true){
            setChooseRfLocationCenter(!chooseRfLocationCenter)
      } else {
         if (pts.pts  > 10){
            const docSnap = await getDoc(docRefActions);
            setCenterRFBases(docSnap.data().RFBasesCenter)
            setChooseRfLocationCenter(!chooseRfLocationCenter)
         } else {
            alert("Reinforcing costs 10 points. You have " + pts.pts  + ".")
         }
      }
   }
   async function germanReinforceSouth(){
      if (chooseRfLocationSouth === true){
            setChooseRfLocationSouth(!chooseRfLocationSouth)
      } else {
         if (pts.pts  > 10){
            const docSnap = await getDoc(docRefActions);
            setSouthRFBases(docSnap.data().RFBasesSouth)
            setChooseRfLocationSouth(!chooseRfLocationSouth)
         } else {
            alert("Reinforcing costs 10 points. You have " + pts.pts  + ".")
         }
      }

   }
   async function germanReinforceDeepSouth(){
      if (chooseRfLocationDeepSouth === true){
         setChooseRfLocationDeepSouth(!chooseRfLocationDeepSouth)
      } else {
         if (pts.pts  > 10){
            const docSnap = await getDoc(docRefActions);
            setDeepSouthRFBases(docSnap.data().RFBasesDeepSouth)
            setChooseRfLocationDeepSouth(!chooseRfLocationDeepSouth)
         } else {
            alert("Reinforcing costs 10 points. You have " + pts.pts  + ".")
         }
      }
   }
   async function germanReinforceFinland(){
      if (chooseRfLocationFinland === true){
            setChooseRfLocationFinland(!chooseRfLocationFinland)
      } else {
         if (pts.pts  > 10){
            const docSnap = await getDoc(docRefActions);
            setChooseRfLocationFinland(!chooseRfLocationFinland)
         } else {
            alert("Reinforcing costs 10 points. You have " + pts.pts  + ".")
         }
      }
   }
   async function reinforceRandomArea () {

      if (pts.pts < 10){
         alert("Not enough points. Need 10 points to reinforce at target location.");
         return;
      }
      let hostileFree = await CheckNeighbors(gameId, selection.name)
      if (hostileFree === false){
         alert("Target territory cannot border the enemy");
         return;
      }
      const docRefTerritory = doc(db, "games", gameId, "territories", selection.name);
      const docSnap = await getDoc(docRefTerritory);
      const neighbors = docSnap.data().neighbors;
      let reinforceTerritories = [];

      for (let i = 0; i < neighbors.length; i++){
         const docSnapTerritory = await getDoc(doc(db, "games", gameId, "territories", neighbors[i]));
         let type;
         const Object = AllTerritories.find((object) => {
            return object.id === neighbors[i]
         })
         reinforceTerritories.push({name: Object.id, path: Object.d, type: "inf"})
      }
      
      calibrateTerritories(reinforceTerritories, 1, 0, randomReinforceCost)
      const selectionArr = [{name: selection.name, path: selection.path, type:"tank"}]
      calibrateTerritories(selectionArr, 0, 1, 0)

   }
   async function triggerDeploy(){
      setChooseRfLocationDeployable(!chooseRfLocationDeployable)
      const docSnap = await getDoc(docRefActions);
      setCenterRFBases(docSnap.data().RFBasesCenter)
   }
   function deployToBase(base){

      if (deployableDivs.infDivs < 1 & deployableDivs.tankDivs < 1){
         alert("No divisions ready for deployment.")
         return;
      }
      const docRefBase = doc(db, "games", gameId, "territories", base);
      updateDoc(docRefBase, {
         infDivs: increment(deployableDivs.infDivs),
         tankDivs: increment(deployableDivs.tankDivs),
      })
      const docRefArmy = doc(db, "games", gameId, "players", user.uid, "ww2", "army");
      updateDoc(docRefArmy, {
         deployableInfDivs: 0,
         deployableTankDivs: 0,
      })
      dispatch(setDeployableDivs({ infDivs: 0, tankDivs: 0}));
      applyTerritoryColor(base)
   }
   function reinforceBase(base){
      const docRefBase = doc(db, "games", gameId, "territories", base);
      updateDoc(docRefBase, {
         infDivs: increment(5),
         tankDivs: increment(1),
      })
      const newPoints = Number((pts.pts-20).toFixed(1))
      updateDoc(doc(db, "games", gameId, "players", user.uid),{
         pointsToSpend: newPoints
      })
      dispatch(setPts({ pts: newPoints}));
      applyTerritoryColor(base)
   }
   async function surrender(){
      var number = 0;
      if(faction.faction === "Germany"){
         number = -10;
         updateDoc(docRefGame, {
            victory: number
         })
      } else if (faction.faction === "Russia"){
         number = 10;
         updateDoc(docRefGame, {
            victory: number
         })
      }
      props.setVictory(number)
   }

   async function signPeace(){
      
      const docSnapGame = await getDoc(docRefGame)

      if (docSnapGame.data().peace === "no"){
         updateDoc(docRefGame, {
            peace: faction.faction
         })
         setReallyPeace(false)
         setPeaceProposed(faction.faction)
      } else if (faction.faction === "Germany"){
         if (docSnapGame.data().peace === "Russia"){
            updateDoc(docRefGame, {
               victory: 15,
               peace: "accepted",
            })
            setReallyPeace(false)
            setPeaceProposed("accepted")
            props.setVictory(15)
         }
      } else if (faction.faction === "Russia"){
         if (docSnapGame.data().peace === "Germany"){
            updateDoc(docRefGame, {
               victory: 15,
               peace: "accepted",
            })
            setReallyPeace(false)
            setPeaceProposed("accepted")
            props.setVictory(15)
            
         }
      }
     
      
   }

   async function unsignPeace () {

      const docSnapGame = await getDoc(docRefGame)

      if (docSnapGame.data().peace === faction.faction){

         updateDoc(docRefGame, {
            peace: "no"
         })

         setPeaceProposed("no")
      }         
   }

   function renderSurrender () {

      if (reallySurrender){
         return <section className='basic-flex-row surrender-button'>
            <h3>Are you sure you want to surrender?</h3>
            <h1 onClick={surrender} > Yes </h1>
            <img onClick={()=>setReallySurrender(false)}src="/images/icons/delete.png" width="20px" alt="cancel" />
         </section>
      } else {
         return <h3 onClick={()=>setReallySurrender(true)} className='surrender-button'>Surrender</h3>
      }
   }

   function renderPeace () {

      if(playerCount !== "Singleplayer"){

         if (peaceProposed === "accepted"){
            return <h3>Peace accepted.</h3>
         } else if (reallyPeace & peaceProposed === "no"){
            return <section className={`basic-flex-row surrender-button`}>
               <h3>Are you sure you want to sign a peace agreement?</h3>
               <h1 onClick={signPeace}>Yes</h1>
               <img onClick={()=>setReallyPeace(false)}src="/images/icons/delete.png" width="20px" alt="cancel"/>
            </section>
         } else if ((reallyPeace & faction.faction === "Germany" & peaceProposed === "Russia") || (reallyPeace & faction.faction === "Russia" & peaceProposed === "Germany")){
            return <section className={`basic-flex-row surrender-button`}>
               <h3>Are you sure you want to accept the peace agreement?</h3>
               <h1 onClick={signPeace}>Yes</h1>
               <img onClick={()=>setReallyPeace(false)}src="/images/icons/delete.png" width="20px" alt="cancel"/>
            </section>
         }
         else if ((faction.faction === "Germany" & peaceProposed === "Germany") || (faction.faction === "Russia" & peaceProposed === "Russia")){
            return <h3 onClick={()=>unsignPeace()} className={`surrender-button peace-initiated`}>Withdraw peace proposal</h3>
         }  
         
         else if (reallyPeace === false ) {
            return <h3 onClick={()=>setReallyPeace(true)} className={`surrender-button`}>Sign peace</h3>
         } else if (((reallyPeace === false & faction.faction === "Germany" & peaceProposed === "Russia") || (reallyPeace === false & faction.faction === "Russia" & peaceProposed === "Germany"))) {
            return <h3 onClick={()=>setReallyPeace(true)} className={`surrender-button`}>The enemy offers peace. Accept peace agreement.</h3>
         } 
      } else {
         return null
      }
   }

   if (loaded === false){
      return <div>Loading...</div>
   }

   return (
   <div className='game-info-inner'>
      <div>
         {faction.faction === "Russia" ?
         (<>
            {/*{retreatedAll < 6 ? (<></>) :
            <h3 className="game-actions" onClick={retreatAll}>Retreat all front units by 1 territory. (5)</h3>}
            */}
            {daugavaRF ? (<></>) :
            <h3 className="game-actions" onClick={reinforceDaugava}>Reinforce the Daugava river river ({reinforceDaugavaCost}) </h3>}
            {dniperRF ? (<></>) :
            <h3 className="game-actions" onClick={reinforceDniper}>Reinforce the Dnieper river line ({reinforceDniperCost})</h3>}
            {smolenskRF ? (<></>) :
            <h3 className="game-actions" onClick={reinforceSmolenskGomel}>Reinforce the Smolensk-Golem line ({reinforceSmolenskCost})</h3>}

            {unlocked ?
            <div>
               {leningradFortified ? (<></>) :
               <h3 className="game-actions" onClick={fortifyLeningrad}>Fortify the Leningrad area (10)</h3>}
               {moscowFortified ? (<></>) :
               <h3 className="game-actions" onClick={fortifyMoscow}>Fortify the Leningrad area (10)</h3>}
               {leningradRF ? (<></>) :
               <h3 className="game-actions" onClick={reinforceLeningrad}>Reinforce the Leningrad area (20)</h3>}
               {moscowRF ? (<></>) :
               <h3 className="game-actions" onClick={reinforceMoscow}>Reinforce the Moscow area (20)</h3>}
               
               {/* <h3>Reinforce Pskov, Tallinn and Narva (10)</h3>
               <h3>Reinforce Leningrad (10)</h3>
               <h3>Reinforce the Crimea and Sevastopol (10)</h3>
               <h3>Build up the Crimea and Sevastopol (5)</h3>
               <h3>Reinforce the Vyazma and Tula areas (20)</h3>
               <h3>Reinforce Kursk and Kharkov (20)</h3>
               <h3>Reinforce the Voronezh-Rostov River line (20)</h3>
               <h3>Reinforce the Moscow area (40)</h3>
               <h3>Reinforce Stalingrad (30)</h3>
               <h3>Reinforce the Saratow-Astrakhan river line (20)</h3>
               <h3>Reinforce Maikop and Grozny (20)</h3>
               */}
            </div>:<h3 className='game-actions-notification'>Germany must cross the the first Riga-Smolensk-Kiev-Dnipro Line for more options to be unlocked.</h3>}

            <h3 className="game-actions" onClick={reinforceRandomArea}>Reinforce target area: {selection.name}</h3>

            <h3 className="game-actions" onClick={triggerDeploy}>Deployable production: {deployableDivs.infDivs} Infantry and {deployableDivs.tankDivs} Tanks</h3>
            {chooseRfLocationDeployable ?
            russianBases.map((base)=>{
               return <h3 className="reinforceButton" onClick={()=>deployToBase(base)}>{base}</h3>
            })
            :null}
         </>)
         :
         (<>
            <h3 className="game-actions" onClick={germanReinforceNorth}>Reinforce the northern front.</h3>
            {chooseRfLocationNorth ?
               northRFBases.map((base)=>{
                  return <h3 className="reinforceButton" onClick={()=>reinforceBase(base)}>{base}</h3>
               })
            :null}

            <h3 className="game-actions" onClick={germanReinforceCenter}>Reinforce the central front.</h3>
            {chooseRfLocationCenter ?
               centerRFBases.map((base)=>{
                  return <h3 className="reinforceButton" onClick={()=>reinforceBase(base)}>{base}</h3>
               })
            :null}
            <h3 className="game-actions" onClick={germanReinforceSouth}>Reinforce the southern front.</h3>
            {chooseRfLocationSouth ?
               southRFBases.map((base)=>{
                  return <h3 className="reinforceButton" onClick={()=>reinforceBase(base)}>{base}</h3>
               })
            :null}
            <h3 className="game-actions" onClick={germanReinforceDeepSouth}>Reinforce the southern coastal front.</h3>
            {chooseRfLocationDeepSouth ?
               deepSouthRFBases.map((base)=>{
                  return <h3 className="reinforceButton" onClick={()=>reinforceBase(base)}>{base}</h3>
               })
            :null}
            <h3 className="game-actions" onClick={germanReinforceFinland}>Reinforce the finnish front.</h3>
            {chooseRfLocationFinland ?
            <h3 className="reinforceButton" onClick={()=>reinforceBase("Finland")}>Finland</h3>
            :null}
            <h3 className="game-actions" onClick={triggerDeploy}>Deployable production: {deployableDivs.infDivs} Infantry and {deployableDivs.tankDivs} Tanks</h3>
            {chooseRfLocationDeployable ?
            centerRFBases.map((base)=>{
               return <h3 className="reinforceButton" onClick={()=>deployToBase(base)}>{base}</h3>
            })
            :null}
         </>) 
         }
         
         {renderPeace()}
         {renderSurrender()}
         
         
      </div>
   </div>
   )
}

export default MultiplayerGameActions

//

//Garrisons at Minsk, Kiev and Odessa.

// Give murmansk area high defence bonuses
// Option to assault a city which reduces defence bonuses
// -> every 10h attack that requires 5 points already budgeted before, defender has
// possibility to match with 5 points

//Free 15 moves per day for Soviet
//Free 5 moves per day for Germany

//Reinforce place of selected territory and neigbors also -> costs more than other
// actions and can only be done if neigbor isnt german

//Have divisions counts in game info Economy of both nations
// Attacking should always


/*
 const docSnapGame = await getDoc(docRefGame)
      
      if (docSnapGame.data().host.playerCount === "Singleplayer"){
         return <section className='basic-flex-row surrender-button'>
            <h3>Are you sure you want to delete the game?</h3>
            <h1 onClick={()=>deleteGame(user, gameId)} > Yes </h1>
            <img onClick={()=>setReallySurrender(false)}src="/images/icons/delete.png" width="20px" alt="cancel" />
         </section>
      } else if () {
         return <h3 onClick={()=>setReallySurrender(true)} className='surrender-button'>Delete game</h3>
      } else 
*/

//