import React, {useState, useRef, useEffect} from 'react';
import { db } from '../../../../../firebase-config';
import { doc, getDoc, setDoc, arrayUnion, updateDoc, increment} from "firebase/firestore";
import { useLocation} from 'react-router-dom';
import { useSelector } from "react-redux"
import { useDispatch } from 'react-redux'
import { setPts } from "../../../../../features/pts";
import { addFreeMoves } from '../../../../../features/freeMoves';
import { CheckBordering, CheckNeighbors } from '../CheckNeighbors';
import { RiverBonus } from '../RiverBonus';
import { MoveCommand, AttackCommand, AttackCommand2 } from '../Commands';

const TerritorySelection = (props) => {

   //basic setup
   let location = useLocation();
   let dispatch = useDispatch();
   const user = useSelector((state) => state.user.value)
   const selection = useSelector((state) => state.selection.value)
   const pts = useSelector((state) => state.pts.value)
   const freeMoves = useSelector((state) => state.freeMoves.value)
   const faction = useSelector((state) => state.faction.value)
   const gameId = location.state.id;
   const docRefPlayers = doc(db, "games", gameId, "players", user.uid);
   const costArtilleryBarrage = 5;
   const costAssault = 5;
   const costFortify = 3;
   const firstUpdate = useRef(true);
   
   const [tanksNum, setTanksNum] = useState(0);
   const [infantryNum, setInfantryNum] = useState(0);
   const [fortificationNum, setFortificationNum] = useState(0);
   const [attack, setAttack] = useState(false);
   const [tankInput, setTankInput] = useState(0);
   const [infantryInput, setInfantryInput] = useState(0);
   const [oldSelection, setOldSelection] =useState(0);
   const [oldSelectionName, setOldSelectionName] = useState(false)
   const [oldTankNum, setOldTankNum] = useState(0);
   const [oldInfantryNum, setOldInfantryNum] = useState(0);
   const [factionStance, setFactionStance] = useState("friendly");
   const [power, setPower] = useState(0);
   const [powerDefence, setPowerDefence] = useState(0);
   const [costAttack, setCostAttack] = useState(0);
   const [view, setView] = useState("normal");
   const [battleReport, setBattleReport] = useState(0);
   const [controlledBy, setControlledBy] = useState("Germany")
   const [inbetweenStance, setInbetweenStance] = useState(false)
   const [inbetweenSelection, setInbetweenSelection] = useState()
   const [freeDescription, setFreeDescription] = useState(false)

   useEffect(() => {
      if (firstUpdate.current) {
         firstUpdate.current = false;
         return;
      }
      
      async function getDataSelection(){

         if (view === "empty"){
            setView("attack")
         } else if (view === "report"){
            setView("normal")
         }
         const docRefTerritory = doc(db, "games", gameId, "territories", selection.name)
         const docSnapTerritory = await getDoc(docRefTerritory);
         var factionStanceVar = "";

         if (faction.faction === docSnapTerritory.data().controlledBy){
            factionStanceVar = "friendly";
            setFactionStance("friendly");
         } else {
            factionStanceVar = "enemy";
            setFactionStance("enemy")
         }

         if(factionStanceVar === "enemy"){
            let hostileFree = await CheckNeighbors(gameId, selection.name);
            if (hostileFree){
               setInfantryNum("?")
               setTanksNum("?")
               setFortificationNum("?")
               setPower("?")
               setPowerDefence("?")
               setControlledBy(docSnapTerritory.data().controlledBy)
            } else {
               fullInformation("enemy");
            }
         } else {
            fullInformation("friendly");
         }

         async function fullInformation(type){

            const docSnapGame = await getDoc(doc(db, "games", gameId));
            let playerUid = "";
            if(type === "friendly"){
               playerUid = user.uid
            } else {
               const playersArray = docSnapGame.data().players
               for (let i = 0; i< playersArray.length; i++){
                  if (playersArray[i] !== user.uid){
                     playerUid = playersArray[i].uid;
                  }
               }
            }
            const docRefPlayer = doc(db, "games", gameId, "players", playerUid, "ww2", "economy");
            const docSnapPlayer = await getDoc(docRefPlayer) 
            const infantryResearchLevel = docSnapPlayer.data().infantryResearchLevel;
            const tankResearchLevel = docSnapPlayer.data().tankResearchLevel;

            const tankNum = docSnapTerritory.data().tankDivs;
            const infNum = docSnapTerritory.data().infDivs;
            const fortificationNum = docSnapTerritory.data().defenceLevel;
            const basicDefenceBonus = 1.3;

            setTanksNum(tankNum);
            setInfantryNum(infNum);
            setFortificationNum(fortificationNum);
            setControlledBy(docSnapTerritory.data().controlledBy)

            if (view === "attack" || view === "empty"){
               const freeAttacksNum = freeMoves.freeAttacks;
               const riverDefenceBonus = RiverBonus(oldSelectionName, selection.name);
               let powerDefenceVar = 0;
               if (fortificationNum >= 0) {
                  powerDefenceVar = Number((tankNum*5*(1+0.2*tankResearchLevel)+infNum*(1+0.2*infantryResearchLevel)+(2*fortificationNum)*riverDefenceBonus)*basicDefenceBonus*(1+0.4*fortificationNum)).toFixed(1)
               } else {
                  powerDefenceVar = Number((tankNum*5*(1+0.2*tankResearchLevel)+infNum*(1+0.2*infantryResearchLevel)+(2*fortificationNum)*riverDefenceBonus)*basicDefenceBonus*(1+(0.15)*fortificationNum)).toFixed(1)
               }

               setPowerDefence(powerDefenceVar)
               var cost = Math.floor((powerDefenceVar*riverDefenceBonus/power)*4+1);
               setCostAttack(cost)
               
            } else {
               setPower(Number(tankNum*5*(1+0.2*tankResearchLevel)+infNum*(1+0.2*infantryResearchLevel)).toFixed(1));
               
               if (fortificationNum >= 0) {
                  setPowerDefence(Number((tankNum*5*(1+0.2*tankResearchLevel)+infNum*(1+0.2*infantryResearchLevel)+(2*fortificationNum))*basicDefenceBonus*(1+0.4*fortificationNum)).toFixed(1))
               } else {
                  //negative fortification number fix
                  setPowerDefence(Number((tankNum*5*(1+0.2*tankResearchLevel)+infNum*(1+0.2*infantryResearchLevel)+(2*fortificationNum))*basicDefenceBonus*(1+(0.15)*fortificationNum)).toFixed(1))
               }
            }
         }
      }
      getDataSelection()
   }, [selection])

   async function reducePoints(cost){
      const points = Number((pts.pts -cost).toFixed(1))
      await updateDoc(docRefPlayers, {
         pointsToSpend: points,
      })
      dispatch(setPts({ pts: points}));
   }
   async function getTimeSinceLastAttack(){
      const docRefTerritory = doc(db, "games", gameId, "territories", selection.name);
      const docSnap = await getDoc(docRefTerritory)
      const lastAttack = docSnap.data().lastAttack;
      const currentTime = Date.now();
      const timeSinceLastAttack = Math.floor((currentTime - lastAttack)/1000/60);
      return timeSinceLastAttack;
   }
   async function updateLastAttack(){
      const docRefTerritory = doc(db, "games", gameId, "territories", selection.name);
      await updateDoc(docRefTerritory, {
         lastAttack: Date.now()
      })
   }
   async function triggerAttack () { //select

      const docRefTerritory = doc(db, "games", gameId, "territories", selection.name)
      const docSnapTerritory = await getDoc(docRefTerritory);

      if (docSnapTerritory.data().controlledBy !== faction.faction){
         alert("Select a friendly territory.");
         return;
      }

      setView("empty")
      setPower(power)
      setOldTankNum(tanksNum)
      setOldInfantryNum(infantryNum)
      setOldSelectionName(selection.name)
      setOldSelection(selection)
      setAttack(true)
   }
   async function inbetweenMove () {
      setInbetweenStance(true)
      setInbetweenSelection(selection)
   }
   async function confirmAttack(distance){
      
      if (oldSelectionName === selection.name){
         alert("Cannot move to same territory.");
         return;
      } 
      if (tankInput < 0 || infantryInput < 0){
         alert("Input cannot be negative");
         return;
      }
      if ((tankInput > oldTankNum) || (infantryInput > oldInfantryNum)){
         alert("Invalid attack. Not enough divisions.");
         return;
      } 
      const docRefFrom = doc(db, "games", gameId, "territories", oldSelectionName);
      const docSnapFrom = await getDoc(docRefFrom);
      const lastAttack = docSnapFrom.data().lastAttack;
      const currentTime = Date.now();
      const timeSinceLastAttack = Math.floor((currentTime - lastAttack)/1000/60);
      const shortCooldown = 150;

      if (timeSinceLastAttack < shortCooldown){
         alert("Still on cooldown. " + timeSinceLastAttack + " / "+ shortCooldown +" minutes have passed.")
         return;
      }

      const docRefTerritory = doc(db, "games", gameId, "territories", oldSelection.name);
      const docSnap = await getDoc(docRefTerritory);
      const neighbors = docSnap.data().neighbors;

      const factionFrom = docSnapFrom.data().controlledBy;
      const docRefTo = doc(db, "games", gameId, "territories", selection.name);
      const docSnapTo = await getDoc(docRefTo);   
      const factionTo = docSnapTo.data().controlledBy;
      var cost;
      
      if (factionTo === factionFrom) { 

         //freeMoves and move cost
         const freeMovesNum = freeMoves.freeMoves;
         cost = 2;
         if (freeMovesNum > 0){
            cost = 0;
            dispatch(addFreeMoves({ freeAttacks: freeMoves.freeAttacks, freeMoves: freeMovesNum-1, distance: 1}));
            const docRefArmy = doc(db, "games", gameId, "players", user.uid, "ww2", "army");
            updateDoc(docRefArmy, {
               freeMoves: increment(-1)
            })
         }
         if (pts.pts < cost){
            alert("Not enough points.");
            return null;
         }

         

         await updateDoc(doc(db, "games", gameId, "players", user.uid), {
            pointsToSpend: pts.pts-cost,
         })
         dispatch(setPts({ pts: pts.pts-cost})); 

         if (distance === 2){
            const bordering1 = await CheckBordering(oldSelectionName, inbetweenSelection.name)
            const bordering2 = await CheckBordering(inbetweenSelection.name, selection.name)
            if (bordering1 === false){
               alert("Start and inbetween step not bordering.")
               return;
            } else if (bordering2 === false){
               alert("Inbetween step and end not bordering.")
               return;
            }
            
         } else if (distance ===1){
            const bordering = await CheckBordering(oldSelectionName, selection.name)
            if (bordering === false){
               alert("Start and end not bordering.")
               return;
            }
         }
         const selectedT = await MoveCommand(gameId, faction.faction, user, pts, selection, cost, tankInput, infantryInput, oldSelectionName, selection.name, oldSelection);

         if (faction.faction === "Germany"){
            props.setSelectedTerritories(selectedT);
         } else if (faction.faction === "Russia"){
            props.setRussianOccupied(selectedT);
         }

      } else if(factionTo !== factionFrom){

         if (neighbors.includes(selection.name) === false){
            alert("Not bordering the selected territory.")
            return;
         }
      
         //freeAttacks and attack cost
         const freeAttacksNum = freeMoves.freeAttacks;
         const riverDefenceBonus = RiverBonus(oldSelectionName, selection.name);
         cost = Math.floor((powerDefence*riverDefenceBonus/power)*4+1);

         if (freeAttacksNum > 0){
            
            if (cost <= 2){
               cost = 0;
            } else {
               cost = cost - 2;
            }
            dispatch(addFreeMoves({ freeAttacks: freeAttacksNum-1, freeMoves: freeMoves.freeMoves, distance: 1}));
            const docRefArmy = doc(db, "games", gameId, "players", user.uid, "ww2", "army");
            updateDoc(docRefArmy, {
               freeAttacks: increment(-1)
            })
         }
         if (pts.pts < cost){
            alert("Not enough points.");
            return null;
         }

         await updateDoc(docRefTo, {
            defenceLevel: 0,
         })
         await updateDoc(doc(db, "games", gameId, "players", user.uid), {
            pointsToSpend: pts.pts-cost,
         })
         dispatch(setPts({ pts: pts.pts-cost})); 
         
         const attackValues = await AttackCommand(gameId, user, pts, selection, cost, tankInput, infantryInput, power, powerDefence, oldSelectionName, selection.name, oldSelection)
         
         setBattleReport(attackValues)
         AttackCommand2(gameId, oldSelectionName, selection.name)

         const docSnapGerman = await getDoc(doc(db, "games", gameId, "german", "territories"));
         const docSnapRussia = await getDoc(doc(db, "games", gameId, "russian", "territories"));
         props.setSelectedTerritories(docSnapGerman.data().territories);
         props.setRussianOccupied(docSnapRussia.data().territories);

         if (faction.faction === "Germany"){
            await updateDoc(doc(db, "games", gameId), {
               germanTerritories: increment(1),
               sovietTerritories: increment(-1),
            })
         } else {
            //check victory
            if(selection.name === "Germany"){
               updateDoc(doc(db, "games", gameId), {
                  victory: -10
               })
            }
            
            await updateDoc(doc(db, "games", gameId), {
               germanTerritories: increment(-1),
               sovietTerritories: increment(1),
            })
         }
         
      }

      setView("report")
      //setAttack(false)
      setOldSelectionName(null)
   }
   function cancelAttack(){
      setOldSelectionName(false)
      setView("normal")
      setInbetweenStance(false)
      //setAttack(false)
   }
   async function fortify(){
      
      if (pts.pts < costFortify){
         alert("Not enough points.");
         return;
      } 
      let hostileFree = await CheckNeighbors(gameId, selection.name);
      if ( hostileFree === false) {
         alert("Cannot fortify at the front line.");
         return;
      }

      const docRefTerritory = doc(db, "games", gameId, "territories", selection.name);
      const docSnap = await getDoc(docRefTerritory)
      const lastFortified = docSnap.data().lastFortified;

      const currentTime = Date.now();
      const timeSinceLastFortifiedHours = Math.floor((currentTime - lastFortified)/1000/60/60);

      if (timeSinceLastFortifiedHours < 24){
         alert("You already fortified. " + timeSinceLastFortifiedHours + " / 24 hours." )
         return;
      }

      const docRefFrom = doc(db, "games", gameId, "territories", selection.name);
      updateDoc(docRefFrom, {
         defenceLevel: increment(1)
      })
      setFortificationNum(fortificationNum+1)
      reducePoints(costFortify)

      updateDoc(doc(db, "games", gameId), {
         actionsLog: arrayUnion({faction: faction.faction, type: "fortification", time: Date.now(), territory: selection.name})
      })

   }
   async function assault(){

      let timeSinceLastAttack = getTimeSinceLastAttack()
      const docRefTerritory = doc(db, "games", gameId, "territories", selection.name);
      const docSnap = await getDoc(docRefTerritory)

      if (pts.pts < costAssault){
         alert("Not enough points. Need " + costAssault + " points to assault a territory.")
         return;
      } if (timeSinceLastAttack < 300 ){
         alert("Troops have not yet reorganized since the last attack. " + timeSinceLastAttack + "/300 minutes have passed.")
         return;
      } if (docSnap.data().defenceLevel < -4){
         alert("Cannot assault this territory again. Maximum debuf level reached.")
         return;
      }

      //reduce Defence level
      updateDoc(docRefTerritory, {
            defenceLevel: increment(-1)
         })

      updateLastAttack()
      reducePoints(costAssault)
      setFortificationNum(fortificationNum-1)

      updateDoc(doc(db, "games", gameId), {
         actionsLog: arrayUnion({faction: faction.faction, type: "assault", time: Date.now(), territory: selection.name})
      })

   }
   async function artilleryBarrage(){
      if(pts.pts < costArtilleryBarrage){
         alert("Not enough points.")
         return;
      }
      if (selection.name === "Finland" || selection.name === "Romania" || selection.name === "East Prussia" || selection.name === "Poland" || selection.name === "Czechoslovakia"){
         alert("Cannot call artillery onto this territory.")
         return;
      }
      const docRefTerritory = doc(db, "games", gameId, "territories", selection.name);
      const docSnapTerritory = await getDoc(docRefTerritory);

      //calculate shattered divisions
      let shatteredInfDivs=Math.floor(docSnapTerritory.data().infDivs*Math.random()*0.25);
      let shatteredTankDivs=Math.floor(docSnapTerritory.data().infDivs*Math.random()*0.1)
      
      //reduce divisions
      updateDoc(docRefTerritory, {
         infDivs: increment(-shatteredInfDivs)
      })
      updateDoc(docRefTerritory, {
         tankDivs: increment(-shatteredTankDivs)
      })

      setBattleReport({
         shattAttInf: 0,
         shattAttTank: 0,
         shattDefInf: shatteredInfDivs,
         shattDefTank: shatteredTankDivs,
      })
      setView("report")
      reducePoints(costArtilleryBarrage)

      updateDoc(doc(db, "games", gameId), {
         actionsLog: arrayUnion({faction: faction.faction, type: "artillery", time: Date.now(), territory: selection.name})
      })
      
      
   }

   function renderButtons () {
      if (factionStance === "friendly" & inbetweenStance === false){
         return <>
         <button className="buttonSVG" onClick={()=>confirmAttack(1)}>Move</button>
         <button className="buttonSVG" onClick={()=>inbetweenMove()}>Add step</button>
         <button className="buttonSVG" onClick={cancelAttack}>Cancel</button>
         </>
      } else if (factionStance === "friendly" & inbetweenStance === true){
         return <>
         <button className="buttonSVG" onClick={()=>confirmAttack(2)}>Move</button>
         <button className="buttonSVG" onClick={cancelAttack}>Cancel</button>
         </>
      } else {
         return <>
         <button className="buttonSVG" onClick={()=>confirmAttack()}>Attack</button>
         <button className="buttonSVG" onClick={cancelAttack}>Cancel</button>
         </>
      }
   }

   function renderView () {
      
      if (view === "empty"){
         return <>
         <h4>Select another territory.</h4>
         <button className="buttonSVG" onClick={cancelAttack}>Cancel</button>
         </>
      } else if (view === "attack"){
         return <>
         <div>
            <section className='selection-input-container'>
               <div className='info-divisions-start'><h4>Tank divisions: </h4></div>
               <input className='inputInfo' type="number" min="0" max="99"
                  onChange={e=>setTankInput(e.target.value)}
                  value={tankInput}
               />
               <h4>({oldTankNum})</h4>
               <img src="../images/icons/ContinueIconRound.svg" height="15px" alt="continueIcon" />
               <h4>{tanksNum}</h4>
               <section className='info-divisions-end'>
                  <h4>Strength: {power} </h4>
                  {factionStance === "friendly" ? null :
                  <>
                  <img src="../images/icons/ContinueIconRound.svg" height="15px" alt="continueIcon" /> 
                  <h4>{powerDefence}</h4>
                  </>
                  }
               </section>
            </section>
            <section className='selection-input-container'>
               <div className='info-divisions-start'><h4>Infantry divisions: </h4></div>
               <input className='inputInfo' type="number" min="0" max="99"
                  onChange={e=>setInfantryInput(e.target.value)}
                  value={infantryInput}
               />
               <h4>({oldInfantryNum})</h4>
               <img src="../images/icons/ContinueIconRound.svg" height="15px" alt="continueIcon" />
               <h4>{infantryNum}</h4>

               <div className='info-divisions-end'>

               {factionStance === "friendly" ?
               <>{freeMoves.freeMoves > 0 ? <h4>Cost: 0p.</h4>:<h4>Cost: 2p</h4>}</>
               :<h4>Cost: {costAttack}p.</h4>}
               </div>
            </section>
         </div>
         <section >

         {renderButtons()}
            
         </section>
         </>
      } else if (view === "normal"){
         return <>
         <section className='selection-bottom-container'>
            <div className='selection-sub-container'>
               <section>
                  <h4 className='text-left'>Tank divisions: </h4>
                  <h4 className='text-right'>{tanksNum}</h4>
               </section>
               <section>
                  <h4 className='text-left'>Off. strength: </h4>
                  <h4 className='text-right'>{power}</h4>
               </section>
               <section className='faction-responsive'>
                  <h4 className='text-left'>Controlled by:</h4>
                  {controlledBy === "Germany" ?
                     <h4 className='text-right'>Ger</h4>
                  :
                     <h4 className='text-right'>Rus</h4>
                  }
               </section>
            </div>

            <div className='selection-sub-container'>
               <section >
                  <h4 className='text-left'>Inf. divisions: </h4>
                  <h4 className='text-right'>{infantryNum}</h4>
               </section>
               <section >
                  <h4 className='text-left'>Def. strength: </h4>
                  <h4 className='text-right'>{powerDefence}</h4>
               </section>
               <section >
                  <h4 className='text-left'>Fortification: </h4>
                  <h4 className='text-right'>{fortificationNum}</h4>
               </section>
            </div>
            {factionStance === "friendly" ?
            (<>
            <div className='selection-sub-container selection-btn-container'>
               <button className="buttonSVG" onClick={fortify}>Fortify</button>
               <button className="buttonSVG" onClick={()=>triggerAttack()}>Select</button>
            </div>
            </>)
            :
            (<>
            <div className='selection-sub-container selection-btn-container'>
               <button className="buttonSVG" onClick={assault}>Assault</button>
               <button className="buttonSVG" onClick={artilleryBarrage}>Artillery</button>
            </div>
            </>)
            }
            {/*
            <div className='selection-sub-container selection-btn-container' >
               <button className="buttonSVG" onClick={fortify}>Fortify</button>
               <button className="buttonSVG" onClick={()=>triggerAttack()}>Select</button>
               <button className="buttonSVG" onClick={()=>setHidden(true)}>Set fixed</button>
            </div>
            */}
         </section>
         </>
      } else if (view === "report"){

         return <>
         <section className='selection-bottom-container'>
            <div className='selection-sub-container'>
               <h4>Attacking infantry shattered: {battleReport.shattAttInf}</h4>
               <h4>Attacking tanks shattered: {battleReport.shattAttTank}</h4>
            </div>
            <div>
               <h4>Defending infantry shattered: {battleReport.shattDefInf}</h4>
               <h4>Defending tanks shattered: {battleReport.shattDefTank}</h4>
            </div>
         </section>
         <button className="buttonSVG" onClick={cancelAttack}>Ok</button>
         </>
      }
      /*
      else if (){
         <>
         <div>
            <button className="buttonSVG" onClick={assault}>Assault</button>
            <button className="buttonSVG" onClick={artilleryBarrage}>Artillery</button>
         </div>
         </>
      }
      */
      
   }

   return (
      <div >
      <section className='selection-container'>
         <section className='hozcenter space-between'> 
            <h4>Points: {pts.pts}</h4>

               { oldSelectionName ? 
               <section className='selection-names'>
                  <h4>{oldSelectionName} </h4>
                  <img className='selection-names-divider'
                     src="../images/icons/ContinueIconRound.svg" height="15px" alt="continueIcon" 
                  />

                  {inbetweenStance ? 
                  <>
                     <h4>{inbetweenSelection.name} </h4>
                     <img className='selection-names-divider'
                        src="../images/icons/ContinueIconRound.svg" height="15px" alt="continueIcon" 
                     />
                  </>
                  : null}

                  <h4>{selection.name}</h4>
               </section>  :  <h4>{selection.name}</h4>}
                  

            <h4 onClick={()=>setFreeDescription(!freeDescription)} className='free-moves'>FA/FM: {freeMoves.freeAttacks}/{freeMoves.freeMoves}</h4>
            {freeDescription ? 
            <section className='free-moves-description' onClick={()=>setFreeDescription(!freeDescription)}>
               <p>Number of free attacks from faction bonuses and free moves from faction bonuses or infrastructure level.</p>
            </section>
            : null}
         </section >
         {renderView()}
         
   </section>
   </div>
   )
}

export default TerritorySelection




//setPower(Number(tankInput*5*(1+0.2*tankResearchLevel)+infantryInput*(1+0.2*infantryResearchLevel)).toFixed(1));
                  //setPowerDefence(Number((tankNum*5*(1+0.2*tankResearchLevel)+infantryNum*(1+0.2*infantryResearchLevel)+(2*fortificationNum))*basicDefenceBonus*(1+0.4*fortificationNum)).toFixed(1))

//setPower(Number(tankNum*5*(1+0.2*enemyTankResearchLevel)+infantryNum*(1+0.2*enemyInfantryResearchLevel)).toFixed(1));
                  //setAttackPower(Number(tankInput*5*(1+0.2*tankResearchLevel)+infNum*(1+0.2*enemyInfantryResearchLevel)).toFixed(1));
                  //setTotalDefence(powerDefence*riverDefenceBonus)

/*
         if (factionStanceVar === "enemy"){
            setPower(power)
            setPowerDefence(powerDefenceVar)
            if (freeAttacksNum > 0){
               cost = cost -2;
            }
         } else if (factionStanceVar === "friendly"){
            //check with free moves?
            if (freeMoves.freeMoves > 0){
               cost = 0
            }
         }
               
         setCostAttack(cost)
         */