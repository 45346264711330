import React, { useState } from 'react'
import { useNavigate } from "react-router-dom"
import { getAuth, sendPasswordResetEmail, createUserWithEmailAndPassword, signInWithEmailAndPassword, onAuthStateChanged } from 'firebase/auth';
import { db } from '../firebase-config';
import { doc, getDoc, setDoc } from 'firebase/firestore';
import { useDispatch } from 'react-redux'
import { login } from "../features/users";
import { CookiesProvider, useCookies } from 'react-cookie';

const Login = () => {

   const auth = getAuth();
   const navigate = useNavigate();
   const dispatch = useDispatch();

   const [cookies, setCookie] = useCookies(['cookie-name']);
   const [userState, setUserState] = useState(true);
   const [email, setEmail] = useState('');
   const [password, setPassword] = useState('');
   const [nickName, setNickName] = useState('');
   const [confirmPassword, setConfirmPassword] = useState('');
   const [cookiesResolved, setCookiesResolved] = useState(false);
   const [passwordResetEmail, setPasswordResetEmail] = useState('');
   const [emailTwo, setEmailTwo] = useState('');

   function handleLogin (e) {
      e.preventDefault();

      signInWithEmailAndPassword(auth, email, password)
         .then((userCredential) => {
            const user = userCredential.user;
            if (user) {
               
               async function checkNickName(){
                  const docSnapName = await getDoc(doc(db, "users", user.uid))
                  dispatch(login({ email: user.email, uid: user.uid, nickName: docSnapName.data().nickName}));
                  setCookie("user", {email: user.email, uid: user.uid, nickName: docSnapName.data().nickName}, {path: "/"})
                  navigate('/multiplayer');
               }
               checkNickName();
            }
         })
         .catch(error => alert(error.message));
   }

   function handleRegister (e) {
      e.preventDefault();

      createUserWithEmailAndPassword(auth, email, password)
         .then( (userCredential) => {
            const user = userCredential.user;
            dispatch(login({ name: user.email, uid: user.uid, nickName: nickName }));
            setCookie("user", {email: user.email, uid: user.uid, nickName: nickName}, {path: "/"})
            if (user) {
               setDoc(doc(db, "users", user.uid), {
                  email: user.email,
                  userId: user.uid,
                  nickName: nickName,
                  games: 0,
                  wins: 0,
                  timeAi: 0,
                  createdAt: new Date(),
                  totalTime: 0,
               });
                  navigate('/multiplayer');
            }
         })
         .catch( error => alert(error));
   }

   function cookiesBanner(){

      if (cookies.user == undefined & cookiesResolved !== true) {
         return <div className='cookies'>
            <p className='hiddenDescription'>A new level of gamified productivity. Defeat your friends through a combination of game strategy and tracking more productive hours.</p>
         <h3>THIS WEBSITE USES COOKIES</h3>
         <p>We use cookies to personalise content and to analyse our traffic.
            By logging in you consent to our use of cookies.</p>
            <button className="cookies-btn" onClick={acceptCookies}>
            OK
            </button>
         </div>
      } else {
         return null
      }
      
   }

   function acceptCookies(){
      setCookiesResolved(true)
   }

   function switchToRegister() {
      {userState? (setUserState(false)) : (setUserState(true))}
   }
   
   function handlePasswordReset () {
      setPasswordResetEmail(!passwordResetEmail)
      sendPasswordResetEmail(auth, emailTwo)
         .then(() => {
            // Password reset email sent!
            // ..
         })
         .catch((error) => {
            const errorCode = error.code;
            const errorMessage = error.message;
            // ..
         });
   }

   return (
      <div className='main background'>
         {cookiesBanner()}
         
         <section className='hzcenter'>
            <div>
               <img className='logoLogin' src="/images/logo/pwLogo1.5blue.svg" alt="pwLogo" />
            </div>
         </section>
         
         <section className='hzcenter'>

               <img className='login-screenshots'
               src="images\login\screenshot5.png" alt="screenshot" width="250px"/>
            
               {userState ?
               <div className='loginCont'>
                  <h1>Login</h1>
                  <form action="" onSubmit={handleLogin}>
                     <h4>E-mail</h4>
                     <input type="text" value={email} onChange={ (e) => {setEmail(e.target.value)}} />
                     <h4>Password</h4>
                     <input type="password" value={password} onChange={ (e) => {setPassword(e.target.value)}} />
                     <button>Login</button>
                  </form>
                  <button onClick={switchToRegister}>Switch to Register</button>
                  <h5 className='forgot-password' onClick={handlePasswordReset}>Forgot password?</h5>
                  {passwordResetEmail ? 
                  <form action="" onSubmit={handlePasswordReset}>
                     <h4>Reset E-mail:</h4>
                     <input type="text" value={emailTwo} onChange={ (e) => {setEmailTwo(e.target.value)}} />
                     <button className="reset-button">Send</button>
                  </form>:null}
               </div>
               :
               <div className='loginContRegister loginCont'>
                  <h1>Register</h1>
                  <form action="" onSubmit={handleRegister}>
                     <h4>E-mail</h4>
                     <input required id="name" type="text" value={email} onChange={ (e) => {setEmail(e.target.value)}} />
                     <h4>Password</h4>
                     <input required id="password" type="password" minlength="8" value={password} onChange={ (e) => {setPassword(e.target.value)}} />
                     <h4>Confirm Password</h4>
                     <input required id="confirm-password" type="password" minlength="8" value={confirmPassword} onChange={ (e) => {setConfirmPassword(e.target.value)}} />
                     <h4>Choose a Name</h4>
                     <input required id="nickName" type="text" value={nickName} onChange={ (e) => {setNickName(e.target.value)}} />
                     <button>Register</button>
                  </form>
                  <button onClick={switchToRegister}>Switch to Login</button>
               </div>
               }

               <img className='login-screenshots'
               src="images\login\screenshot3.png" alt="screenshot" width="250px"/>
            
         </section>
         <section className='hzcenter'>
            <a href="https://discord.com/channels/1019887659285487616">
            <img  className="icon-links" src="/images/icons/discord.svg" alt="discord-icon" height="50px" />
            </a>
         </section>
      </div>
   )
};

export default Login;
