import React from 'react'
import { db } from '../../../../firebase-config';
import { doc, getDoc, setDoc, updateDoc, arrayUnion, arrayRemove, increment} from "firebase/firestore";
import { CheckNeighbors } from './CheckNeighbors'
import { RiverBonus } from './RiverBonus';
import { AttackCommand, AttackCommand2 } from './Commands';

export async function AImoves(gameId, factionPlayer, user){

   async function hostileNeighbors(gameId, neighbors, factionPlayer){
      var hostileBorderTerritories = [];
      
      for (let i = 0; i < neighbors.length; i++){
         const docSnap = await getDoc(doc(db, "games", gameId, "territories", neighbors[i]))
         if (docSnap.data().controlledBy === factionPlayer){
            hostileBorderTerritories.push(neighbors[i])
         }
      }
      return hostileBorderTerritories
   }
   async function AttackableTerritory(chosenTerr, neighbors){

      const tankResearchLevel = docSnapPlayerWW2.data().infantryResearchLevel;
      const infantryResearchLevel = docSnapPlayerWW2.data().tankResearchLevel;
      const docRefTerr = doc(db, "games", gameId, "territories", chosenTerr)
      const docSnapTerr = await getDoc(docRefTerr)
      const offInfs = docSnapTerr.data().infDivs
      const offTanks = docSnapTerr.data().tankDivs
      const offStrength = offInfs + offTanks*5

         for(let i = 0; i < neighbors.length; i++){
            const docRefAttTerr = doc(db, "games", gameId, "territories", neighbors[i])
            const docSnapAttTerr = await getDoc(docRefAttTerr)    //no await, problem?
            //no riverDefenceBonus *riverDefenceBonus
            const tanks = docSnapAttTerr.data().tankDivs;
            const infs = docSnapAttTerr.data().infDivs;
            const fortifications = docSnapAttTerr.data().defenceLevel;
            const tankStrength = tanks*5*(1+0.2*tankResearchLevel)
            const infStrength = infs*(1+0.2*infantryResearchLevel)
            let powerDefence = (tankStrength+infStrength)+(2*fortifications)
            powerDefence = powerDefence*basicDefenceBonus*(1+0.4*fortifications)
            powerDefence = Number(powerDefence.toFixed(1))
            if (powerDefence < offStrength*1.5){
               return {name: neighbors[i], powerDefence: powerDefence, power: offStrength, 
                  infInput: Math.ceil(offInfs*0.3), tankInput: Math.ceil(offTanks*0.2)}
            } 
         }
         
      return false
   }
   async function ReinforceTerritory(territory){
      
      const docRefTerr = doc(db, "games", gameId, "territories", territory)
      const docSnapTerr = getDoc(docRefTerr)
      const randomInf = 1+Math.floor(Math.random()*3)
      const randomTank = Math.floor(Math.random()*1.2)

      updateDoc(docRefTerr, {
         infDivs: increment(randomInf),
         tankDivs: increment(randomTank),
      })
      console.log("Reinforce" + territory + randomInf + " & " + randomTank + " divisions")
   }

   const docRefAI = doc(db, "games", gameId, "players", "AI");
   const docSnapAI = await getDoc(docRefAI);
   const lastAIMove = docSnapAI.data().lastPayout;
   const currentTimeSec = Math.floor(Date.now()/1000);
   const timeDifferenceMins = (currentTimeSec - lastAIMove)/60;
   console.log(timeDifferenceMins, currentTimeSec, lastAIMove)
   
   if (timeDifferenceMins < 60) {
      return null;
   }

   const numberAIActions = Number(Math.floor(timeDifferenceMins/120).toFixed(1))
   updateDoc(docRefAI, {
      lastPayout: Math.floor(Date.now()/1000)
      //lastPayout: currentTimeSec-(timeDifferenceMins*60)+numberAIActions*120
   });

   const docRefPlayerWW2 = doc(db, "games", gameId, "players", user.uid, "ww2", "economy");
   const docSnapPlayerWW2 = await getDoc(docRefPlayerWW2);
   const docRefGermanFront = doc(db, "games", gameId, "frontline", "german");
   const docSnapGermanFront = await getDoc(docRefGermanFront);
   const docRefRussianFront = doc(db, "games", gameId, "frontline", "soviet");
   const docSnapRussianFront = await getDoc(docRefRussianFront);
   const docRefGerman = doc(db, "games", gameId, "german", "territories");
   const docSnapGerman = await getDoc(docRefGerman)
   const docRefRussian = doc(db, "games", gameId, "russian", "territories");
   const docSnapRussian = await getDoc(docRefRussian)

   const basicDefenceBonus = 1.3;
   
   console.log("number of actions" + numberAIActions)

   const totalActionsNum = numberAIActions + docSnapAI.data().AIopenMoves

   await updateDoc(docRefAI, {
      AIopenMoves: totalActionsNum,
   })

   for (let i = totalActionsNum; i > 0; i--){
      
      if (factionPlayer === "Germany"){

         //territory to reinforce / attack from
         const sovietTerritories = docSnapRussianFront.data().territories;
         const randomTerritoryNumber = Math.floor(Math.random()*sovietTerritories.length);
         const chosenTerritory = sovietTerritories[randomTerritoryNumber];
         const docRefChosen = doc(db, "games", gameId, "territories", chosenTerritory)
         const docSnapChosen = await getDoc(docRefChosen);

         //territory to attack
         let neighbors = docSnapChosen.data().neighbors;
         neighbors = await hostileNeighbors(gameId, neighbors, factionPlayer)

         //attackable?
         const attackTerritory = await AttackableTerritory(chosenTerritory, neighbors)

         if (attackTerritory === false){
            await ReinforceTerritory(chosenTerritory)
         } else {
            const chosenShort = await docSnapRussian.data().territories.find((object)=>{
            return object.name === chosenTerritory
               
            })
            const attackShort = await docSnapGerman.data().territories.find((object)=>{
               return object.name === attackTerritory.name
            })

            const attackValues = await AttackCommand(gameId, "AI", 100, attackShort, 0, attackTerritory.tankInput, attackTerritory.infInput, attackTerritory.power, attackTerritory.powerDefence, chosenTerritory, attackShort.name, chosenShort)
            await AttackCommand2(gameId, chosenTerritory, attackTerritory.name)

            await updateDoc(doc(db, "games", gameId, "territories", attackTerritory.name), {
               defenceLevel: 0,
            })
            await updateDoc(doc(db, "games", gameId), {
               germanTerritories: increment(-1),
               sovietTerritories: increment(1),
            })
            console.log("Attacked " + attackShort.name + " from " + chosenShort.name)
         }
      } else if (factionPlayer === "Russia"){

         //territory to reinforce / attack from
         const germanTerritories = docSnapGermanFront.data().territories;
         const randomTerritoryNumber = Math.floor(Math.random()*germanTerritories.length);
         const chosenTerritory = germanTerritories[randomTerritoryNumber];
         const docRefChosen = doc(db, "games", gameId, "territories", chosenTerritory)
         const docSnapChosen = await getDoc(docRefChosen);

         //territory to attack
         let neighbors = docSnapChosen.data().neighbors;
         neighbors = await hostileNeighbors(gameId, neighbors, factionPlayer)

         //attackable?
         
         
         const attackTerritory = await AttackableTerritory(chosenTerritory, neighbors)
         console.log(chosenTerritory)
         console.log(attackTerritory)
         if (attackTerritory === false){
            await ReinforceTerritory(chosenTerritory)
         } else {
            const chosenShort = await docSnapGerman.data().territories.find((object)=>{
               return object.name === chosenTerritory
            })
            const attackShort = await docSnapRussian.data().territories.find((object)=>{
               return object.name === attackTerritory.name
            })
            const attackValues = await AttackCommand(gameId, "AI", 100, attackShort, 0, attackTerritory.tankInput, attackTerritory.infInput, attackTerritory.power, attackTerritory.powerDefence, chosenTerritory, attackShort.name, chosenShort)
         
            await AttackCommand2(gameId, chosenTerritory, attackTerritory.name)

            await updateDoc(doc(db, "games", gameId, "territories", attackTerritory.name), {
               defenceLevel: 0,
            })
            await updateDoc(doc(db, "games", gameId), {
               germanTerritories: increment(1),
               sovietTerritories: increment(-1),
            })
            
         }
      }

      console.log(i)
      await updateDoc(docRefAI, {
         AIopenMoves: i-1
      })
      
   }
   return true
}
     
//check victory
/*
if(selection.name === "Germany"){
   updateDoc(doc(db, "games", gameId), {
      victory: -10
   })
}
*/




