import React, {useEffect, useState} from 'react'
import { getAuth, updateProfile} from 'firebase/auth'
import { db, upload } from '../firebase-config';
import { doc, getDoc, setDoc, updateDoc} from "firebase/firestore";
import { useSelector } from "react-redux";
import TrackingData from './TrackingData';
import { current } from '@reduxjs/toolkit';
import Crop from './crop/Crop';
import { getDownloadURL, getStorage, ref, uploadBytes } from 'firebase/storage'
import OutsideClickHandler from 'react-outside-click-handler';
//import { getStorage } from "firebase/getStorage"
//import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';

const Profile = () => {

  const user = useSelector((state) => state.user.value);
  const currentUser = getAuth(); 
  const storage = getStorage();

  const [numberGames, setNumberGames] = useState(0);
  const [numberWon, setNumberWon] = useState(0);
  const [bestTimeAi, setBestTimeAi] = useState(0);
  const [userCreatedAt, setUserCreatedAt] = useState(0);
  const [hours, setHours] = useState(0);
  const [minutes, setMinutes] = useState(0);
  const [achievementLevel, setAchievmentLevel] = useState(1);
  const [photoURL, setPhotoURL] = useState("./images/icons/profile-placeholder.svg")
  const [photo, setPhoto] = useState(null);
  const [loading, setLoading] = useState(false);
  const [imageChange, setImageChange] = useState(false);
  //const [openCrop, setOpenCrop] = useState(false);
  //const [file, setFile] = useState(null);
  const [avatarState, setAvatarState] = useState(true)
  const [preview, setPreview] = useState(null)

  const docRefUser = doc(db, "users", user.uid);

  useEffect( () => {

    async function fetchData(){
      const docSnapUser = await getDoc(docRefUser);
      var date = new Date(docSnapUser.data().createdAt.seconds*1000).toLocaleDateString("en-DE")
      setUserCreatedAt(date);
      setNumberGames(docSnapUser.data().games)
      setNumberWon(docSnapUser.data().games-docSnapUser.data().losses)
      setHours(Math.floor(docSnapUser.data().totalTime/60))
      setMinutes((docSnapUser.data().totalTime%60))
      
      const storageRef = ref(storage, "profiles/"+user.uid);  //currentUser.uid
      const URLPhoto = await getDownloadURL(storageRef);
      setPhotoURL(URLPhoto);
    }

    fetchData();
  }, [])

  function handleImageChange (e) {
    const file = e.target.files[0];
    if (file) {
      //setFile(file)
      setPhoto(file)
      setPhotoURL(URL.createObjectURL(file));
      //setOpenCrop(true);
    }
  }
  
  function handleUpload () {
    upload(photo)
  }

  function triggerImageChange () {
    setAvatarState(false)
    setImageChange(!imageChange)
  }

  async function upload(file) {
    const storageRef = ref(storage, "profiles/"+user.uid);  //currentUser.uid
  
    const snapshot = await uploadBytes(storageRef, file)
    const URLPhoto = await getDownloadURL(storageRef);
    
    setPhotoURL(URLPhoto);
    //updateProfile(user, {photoURL: URLPhoto})
  }

  function renderAvatar () {

    if (avatarState){
      return <>
        {imageChange? <div className='profile-upload-container'>
        <input type="file" onChange={handleImageChange}/>
        </div>:null}

        <img onClick={triggerImageChange}
        className="profile-placeholder" height="200px" width="200px"
        src={photoURL}  alt="profile" />
      </>
    } else {
      return <>
      <OutsideClickHandler
        onOutsideClick={()=>setAvatarState(!avatarState)}
      >
        <Crop
        setPreview={(view)=>setPreview(view)}
        />
        <button onClick={handleUpload}>Upload</button>
      </OutsideClickHandler>
      </>
    }
  }

  return (<>
    <div className='profile-container'>
      <section className='profile-top'>
        <img
        className="profile-placeholder" height="200px" width="200px"
        src="/images/icons/profile-placeholder.svg"  alt="profile" />
        <div className='profile-top-sub'>
          <h1 className='profile-name'>Profile: {user.nickName}</h1>
          <section className='profile-time-container'>
            <h4>Productive time tracked</h4>
            {hours < 10 ?
            (<><h4>: 0{hours}</h4></>):(<><h4>: {hours}</h4></>)
            }
            <h4>:</h4>
            {minutes < 10 ?
            (<><h4>0{minutes}</h4></>):(<><h4>{minutes}</h4></>)
            }
          </section>
        </div>
      </section>

      <div className='profile-bottom'>
        <h4>Member since: {userCreatedAt}</h4>
        <h4>Number of games: {numberGames}</h4>
        <h4>Number of won games: {numberWon}</h4>
      </div>
    </div>
    
    </>)
}

export default Profile
/*
<h4>Settings</h4>
<h4>Dark Mode</h4>
<h4>Language</h4>
<h4>Logout</h4>
<h4>SOON: Best time to beat AI: {bestTimeAi}</h4>
*/