import React from 'react'
import TimeTracking from './TimeTrackingNavigation/TimeTracking/TimeTracking'
import GameInfo from './GameInfo'
import ActionsHistory from './ActionsHistory'
import GameNavigation from './GameNavigation'
import { Link } from 'react-router-dom'
import TimeTrackingNav from './TimeTrackingNavigation/TimeTrackingNav'

const PreLoadedGame = (props) => {

   function setVictory () {
      return null
   }


   return <section className='game-main-container'>
      <div className='map-container-outer'>
         <section className='stacked'>
            <h3 className='map-notification'>We have a map with lots of territories! Loading them for the first time takes a few minutes.
               In the meantime you can already get started by tracking your time to get points or buy upgrades!
               You can also check out how to play the game <Link to="/help"><span>here</span></Link>
               <hr className='horizontal-rule'/>
               GAME LOADED : {(props.percentage/190*100).toFixed(1)} %
            </h3>
            <img src="/images/components/previewMap.PNG" alt="preview-map" className='preview-map'/>
         </section>
         
      <ActionsHistory/>
      
      </div>
      <div className='game-right-side'>
      <div className='victory-screen'>
      </div>
         <section className='gameInfoGrid'>
            <GameInfo />
         </section>
            <GameNavigation
            //setVictory={(number)=>setVictory(number)}
            //setSelectedTerritories={(selectedT)=>setSelectedTerritories(selectedT)}
            //setRussianOccupied={(selectedRO)=>setRussianOccupied(selectedRO)}
         />
         <section>
            <TimeTrackingNav/>
         </section>
         
      </div>
   </section>
}

export default PreLoadedGame