import React, {useEffect, useState} from 'react'
import TimeTracking from './TimeTracking/TimeTracking'
import TimeTrackingHistory from './TimeTrackingHistory/TimeTrackingHistory';
import { db } from '../../../../../firebase-config';
import { doc, getDoc, increment, setDoc, updateDoc} from "firebase/firestore";
import { useLocation} from 'react-router-dom';
import { useSelector } from "react-redux"

const TimeTrackingNav = (props) => {

   const location = useLocation();
   const gameId = location.state.id;
   const user = useSelector((state) => state.user.value);

   const [showTab, setShowTab] = useState("timeTracking")
   const [enemyPlayerUid, setEnemyPlayerUid] = useState({})
   const [userData, setUserData] = useState([])
   const [enemyUserData, setEnemyUserData] = useState([])
   const [loaded, setLoaded] = useState(false)

   const docRefGame = doc(db, "games", gameId);


   useEffect(()=>{
      async function fetchData() {
         const docSnapGame = await getDoc(docRefGame)
         const players = docSnapGame.data().players

         let enemyPlayer = (players.filter((player)=>{
            return player.uid !== user.uid
         }))
         setEnemyPlayerUid(enemyPlayer[0])

         const docSnapHistoryUser = await getDoc(doc(db, "games", gameId, "players", user.uid, "time", "history"));

         if (docSnapHistoryUser.data().logs.length !== 0){
            setUserData(docSnapHistoryUser.data().logs)
         } else {
            setUserData([{day: 1, productiveTime: 0, maintenanceTime: 0}])
         }
         
         console.log(enemyPlayer)
         console.log(enemyPlayer[0].uid)
         const docSnapHistoryEnemy = await getDoc(doc(db, "games", gameId, "players", enemyPlayer[0].uid, "time", "history"));

         if (docSnapHistoryEnemy.data().logs.length !== 0){
            setEnemyUserData(docSnapHistoryEnemy.data().logs)
         } else {
            setEnemyUserData([{day: 1, productiveTime: 0, maintenanceTime: 0}])
         }
         
         setLoaded(true)
      }
             

      fetchData()
   },[])

   if(loaded === false){
      return null;
   }
   

   function renderTab () {
      if (showTab === "timeTracking"){
         return <TimeTracking/>
      } else if (showTab === "yourHistory"){
         return <TimeTrackingHistory
            state="standard"
            player={user.uid}
            userData={userData} //[{day:1, productiveTime: 3, maintenanceTime: 5}]
         />
      } else if (showTab === "enemyHistory"){
         return <TimeTrackingHistory
            state="enemy"
            player={enemyPlayerUid.uid}
            userData={enemyUserData}
         />
      } 
   }

return <>
    <section className='game-info-container'>
        <h3 className='game-nav-items' onClick={()=>setShowTab("timeTracking")}>Time Tracking</h3>
        <h3 className='game-nav-items' onClick={()=>setShowTab("yourHistory")}>Your tracking history</h3>
        <h3 className='game-nav-items' onClick={()=>setShowTab("enemyHistory")}>Enemy tracking history</h3>
        </section>
    <section>{renderTab()}</section>
</>
}

export default TimeTrackingNav