import React, {useState, useRef, useEffect} from 'react';
import { db } from '../../../../firebase-config';
import { doc, getDoc, setDoc, updateDoc, arrayUnion, arrayRemove, increment} from "firebase/firestore";
import { AllTerritories } from '../svgComponents/AllTerritories';


export async function CheckNeighborsSubtracted(gameId, territory, attackedTerritory, factionAttacked){

   const docRefTerritory = doc(db, "games", gameId, "territories", territory);
   const docSnap = await getDoc(docRefTerritory);
   let neighbors = docSnap.data().neighbors;
   neighbors = neighbors.filter((name)=>{
      return name !== attackedTerritory
   })

   for (let i = 0; i < neighbors.length; i++){
      const docSnap = await getDoc(doc(db, "games", gameId, "territories", neighbors[i]))
      if (factionAttacked !== docSnap.data().controlledBy){
         return false
      }
   }
   return true
}

export async function CheckNeighborsSpecial(gameId, territory, faction){

   const docRefTerritory = doc(db, "games", gameId, "territories", territory);
   const docSnap = await getDoc(docRefTerritory);
   const neighbors = docSnap.data().neighbors;

   for (let i = 0; i < neighbors.length; i++){
      const docSnap = await getDoc(doc(db, "games", gameId, "territories", neighbors[i]))
      if (faction !== docSnap.data().controlledBy){
         return false
      }
   }
   return true
}

export async function CheckNeighbors(gameId, territory){

   const docRefTerritory = doc(db, "games", gameId, "territories", territory);
   const docSnap = await getDoc(docRefTerritory);
   const neighbors = docSnap.data().neighbors;
   let faction = docSnap.data().controlledBy;

   for (let i = 0; i < neighbors.length; i++){
      const docSnap = await getDoc(doc(db, "games", gameId, "territories", neighbors[i]))
      if (faction !== docSnap.data().controlledBy){
         return false
      }
   }
   return true
}

export async function CheckBordering(territory1, territory2){


   const territory = AllTerritories.find((obj) => {
      return obj.id === territory1
   });
   const neighbors = territory.ct;

   for (let i = 0; i < neighbors.length; i++){
      if (neighbors[i] === territory2){
         return true
      } else if (i === neighbors.length - 1){
         return false
      }
   }
}