import React, {useState, useEffect} from 'react'
import { UpgradeableItem } from './../UpgradeableItem';
import { useLocation} from 'react-router-dom';
import { useSelector } from "react-redux"
import { db } from '../../../../../firebase-config';
import { doc, getDoc, setDoc, updateDoc, arrayUnion} from "firebase/firestore";

const EnemyInformation = () => {

   const location = useLocation();
   const gameId = location.state.id;
   const user = useSelector((state) => state.user.value)
   const pts = useSelector((state) => state.pts.value)
   const faction = useSelector((state) => state.faction.value);

   const docRefGame = doc(db, "games", gameId);

   const [economyLevelEnemy, setEconomyLevelEnemy] = useState(1)
   const [infrastructureLevelEnemy, setInfrastructureLevelEnemy] = useState(1)
   const [tankProductionLevelEnemy, setTankProductionLevelEnemy] = useState(1)
   const [armyTrainingLevelEnemy, setArmyTrainingLevelEnemy] = useState(1)
   const [recruitmentLevelEnemy, setRecruitmentLevelEnemy] = useState(1)
   const [propagandaLevelEnemy, setPropagandaLevelEnemy] = useState(1)
   const [tankResearchLevelEnemy, setTankResearchLevelEnemy] = useState(1)
   const [armyResearchLevelEnemy, setArmyResearchLevelEnemy] = useState(1)
   const [infantryResearchLevelEnemy, setInfantryResearchLevelEnemy] = useState(1)
   const [enemyPlayerPosition, setEnemyPlayerPosition] = useState(0)
   const [moreThanTwoEnabled, setMoreThanTwoEnabled] = useState("Singleplayer")
   const [loaded, setLoaded] = useState(false)

   var enemyPlayersArray = [];

   useEffect(()=>{

      async function fetchData(){

         const docSnapGame = await getDoc(docRefGame)
         const playersArray = docSnapGame.data().players;
         for (let i = 0; i<playersArray.length; i++){
            if (user.uid !== playersArray[i].uid){
               enemyPlayersArray.push(playersArray[i].uid)
            }
         }
         setMoreThanTwoEnabled(docSnapGame.data().playerCount)
         getEnemyInformation()
         setLoaded(true)
      }

      fetchData();

   },[])

   async function getEnemyInformation () {
      const docRefGameEcoEnemy = doc(db, "games", gameId, "players", enemyPlayersArray[enemyPlayerPosition], "ww2", "economy");
      const docSnapEnemy = await getDoc(docRefGameEcoEnemy);
      setEconomyLevelEnemy(docSnapEnemy.data().economyLevel)
      setInfrastructureLevelEnemy(docSnapEnemy.data().infrastructureLevel)
      setTankProductionLevelEnemy(docSnapEnemy.data().tankProductionLevel)
      setArmyTrainingLevelEnemy(docSnapEnemy.data().armyTrainingLevel)
      setRecruitmentLevelEnemy(docSnapEnemy.data().recruitmentLevel)
      setTankResearchLevelEnemy(docSnapEnemy.data().tankResearchLevel)
      setPropagandaLevelEnemy(docSnapEnemy.data().propagandaLevel)
      setArmyResearchLevelEnemy(docSnapEnemy.data().armyResearchLevel)
      setInfantryResearchLevelEnemy(docSnapEnemy.data().infantryResearchLevel)
   }

   function swipeable () {
      if (moreThanTwoEnabled === "Singleplayer" || moreThanTwoEnabled < 3){
         return null
      } else {
         return <section>
            <h4 onClick={()=>changeEnemyUpgrades(-1)}>previous</h4>
            <h4 onClick={()=>changeEnemyUpgrades(1)}>next</h4>
         </section>
      }
   }

   function changeEnemyUpgrades (change){
      setEnemyPlayerPosition(enemyPlayerPosition + change);
      getEnemyInformation();
   }

   function renderBanners (){
      if(faction.faction === "Germany"){
         return <img className="faction-banner" src="/images/icons/soviet-banner.svg" 
         height="250px" alt="soviet-banner" />
      } else {
         return <img className="faction-banner" src="/images/icons/german-banner.svg" 
         height="250px" alt="german-banner" />
      }
   }

   if (loaded === false) {
      return <section className='game-info-inner'></section>
   }

   return (
      <div className='game-info-inner'>
         {renderBanners()}
         {swipeable()}    
         <div>
            <section className='economy-items'>
               <h4 className="economy-text">Propaganda Level:</h4>
               <h4 className="enemy-economy-lvl">{propagandaLevelEnemy}</h4>
            </section>
            <section className='economy-items'>
               <h4 className="economy-text">Economy Level: </h4>
               <h4 className="enemy-economy-lvl">{economyLevelEnemy}</h4>
            </section>
            <section className='economy-items'>
               <h4 className="economy-text">Inftrastructure Level: </h4>
               <h4 className="enemy-economy-lvl">{infrastructureLevelEnemy}</h4>
            </section>
            <section className='economy-items'>
               <h4 className="economy-text">Tank Production Level: </h4>
               <h4 className="enemy-economy-lvl">{tankProductionLevelEnemy}</h4>
            </section>
            <section className='economy-items'>
               <h4 className="economy-text">Tank Research Level: </h4>
               <h4 className="enemy-economy-lvl">{tankResearchLevelEnemy}</h4>
            </section>
            <section className='economy-items'>
               <h4 className="economy-text">Recruitment Level: </h4>
               <h4 className="enemy-economy-lvl">{recruitmentLevelEnemy}</h4>
            </section>
            <section className='economy-items'>
               <h4 className="economy-text">Infantry Research Level: </h4>
               <h4 className="enemy-economy-lvl">{infantryResearchLevelEnemy}</h4>
            </section>
            
         </div>
      </div>
   )
}

export default EnemyInformation

/*
<section className='economy-items'>
   <h4 className="economy-text">Army Training Level: </h4>
   <h4 className="enemy-economy-lvl">{armyTrainingLevelEnemy}</h4>
</section>
<section className='economy-items'>
   <h4 className="economy-text">Army Research Level: </h4>
   <h4 className="enemy-economy-lvl">{armyResearchLevelEnemy}</h4>
</section>
*/