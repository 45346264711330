export const daugavaLine = [
   {
      name:"Riga",
      path:"M 87.788543,178.78355 C 87.32197,179.37574 86.54532,180.11095 86.00726,180.7106 L 88.32495,183.12699 C 89.353608,183.14369 90.379423,182.99988 91.339744,183.23279 C 91.435944,183.77008 91.394464,184.37503 91.388754,184.85411 L 92.952481,184.55386 C 92.947281,184.31969 92.939631,184.08572 92.931841,183.84848 L 94.171557,181.67395 L 91.318502,179.77948 L 89.401863,178.83745 C 88.935045,178.82945 88.240645,178.81045 87.788543,178.78355 Z",
      type:"inf",
   },
   {
      name:"Aizkraukle",
      path:"M 97.815318,179.21294 L 96.580251,179.22194 L 95.715188,180.39137 L 94.171611,181.67761 L 92.931896,183.85214 C 92.947856,184.35598 92.959946,184.77325 92.968106,185.2252 L 94.49876,184.90479 L 95.972572,185.65308 L 96.860892,184.92805 L 98.194143,185.04483 L 98.521255,185.79313 L 99.667438,185.20866 L 100.62603,181.84091 L 98.076837,179.82966 Z",
      type:"inf",
   },
   {
      name:"Jekabpils",
      path:"M 100.626,181.84075 L 99.667401,185.2085 L 103.52608,187.24298 L 105.88821,188.76331 L 107.64211,187.61713 L 108.0395,184.92788 L 104.32086,183.66492 L 104.04026,181.84075 C 102.90366,181.69115 101.76576,181.79117 100.626,181.84075 Z",
      type:"inf",
   },
   {
      name:"Daugavpils",
      path:"M 104.75753,188.03566 C 104.54132,188.46596 104.5687,188.98222 104.5162,189.4547 L 105.44224,189.5539 L 105.2438,190.90989 L 106.1037,191.04218 L 106.56672,193.12577 L 109.67558,192.99348 L 109.54328,193.78723 L 111.32922,192.92734 L 110.72874,189.60507 L 107.64211,187.61707 L 105.88821,188.76325 Z",
      type:"inf",
   },
   {
      name:"Kraslava",
      path:"M 117.61308,192.39819 L 115.92636,190.57918 L 115.66177,188.231 H 110.56854 L 109.65129,188.91106 L 110.72874,189.60509 L 111.32922,192.92736 L 115.82714,194.581 L 116.81933,193.98569 Z",
      type:"inf",
   },
   {
      name:"Moiry",
      path:"M 123.74397,190.54767 L 121.84641,190.28152 L 117.61308,192.39819 L 116.81933,193.98569 C 117.19835,193.89279 117.65937,193.81413 118.04151,193.76939 C 118.36488,193.73419 118.6466,193.75789 118.90021,193.84499 C 119.31821,194.53093 118.88891,195.69798 119.40211,196.07323 C 119.86631,196.35326 120.28652,196.6099 120.76379,196.90158 L 123.33671,196.56485 L 124.26275,193.45599 L 123.66744,192.1 Z",
      type:"inf",
   },
   {
      name:"Verkhnyadzvinsk",
      path:"M 123.74397,190.54767 L 123.66537,192.10053 L 124.26068,193.45652 L 123.33464,196.56538 L 126.77422,197.72293 L 128.17414,197.73983 C 128.7432,196.15484 129.26908,194.57212 129.81642,193.13751 C 129.53723,192.71262 129.24507,192.27127 128.95704,191.83579 L 126.54271,192.03423 L 125.38516,190.77746 Z",
      type:"inf",
   },
   {
      name:"Navapolatsk",
      path:"M 136.09877,196.13956 L 135.63781,194.41564 C 133.78482,193.76371 131.73734,193.19071 130.13637,192.48752 C 130.12597,192.48252 130.12137,192.47132 130.11487,192.46422 C 130.11434,192.46122 130.11387,192.46122 130.11287,192.46022 H 130.10987 C 129.95126,192.7753 129.82021,193.12347 129.70856,193.42086 C 129.14746,194.93536 128.55066,196.65163 128.06731,198.04694 C 127.84614,198.68565 127.69938,199.11353 127.69938,199.11353 L 129.82173,199.38846 L 129.74993,198.98124 C 131.77731,198.10316 134.12603,196.89934 136.09877,196.13956 Z",
      type:"inf",
   },
   {
      name:"Sumilna",
      path:"M 135.52464,201.41572 L 138.91203,199.24428 C 138.01636,198.27636 137.102,197.07577 136.13393,196.13531 C 133.80954,197.06683 131.93862,197.96384 129.75086,198.98088 L 129.96273,200.18082 L 135.40632,201.29598 Z",
      type:"inf",
   },
   {
      name:"Sigulda",
      path:"M 89.798753,175.96095 L 89.335732,178.17684 L 89.401882,178.83829 L 91.31856,179.78294 L 94.171615,181.67741 L 95.715192,180.39116 L 96.580255,179.22173 L 97.815322,179.21273 L 96.627279,176.41497 L 92.862134,176.48527 L 92.230651,176.04085 Z",
      type:"inf",
   },
   {
      name:"Valmiera",
      path:"M 99.555263,169.57788 L 96.049534,169.64398 L 93.602138,171.03305 L 95.13021,172.74353 L 95.20049,174.8018 L 95.925509,174.98889 L 95.7865,176.43065 L 96.627274,176.41505 L 98.076799,179.82931 L 100.626,181.84057 L 102.77781,181.74697 L 104.22733,179.99308 L 102.68427,176.3685 L 101.35102,175.59644 L 98.825591,175.47966 L 98.872101,171.69127 L 100.28287,171.16531 Z",
      type:"inf",
   },
   {
      name:"Madona",
      path:"M 112.0899,181.5172 C 111.21679,180.97982 110.40552,179.95444 109.49123,179.7153 C 108.68753,179.95787 107.94822,180.47684 107.29451,180.92145 L 104.22751,179.99282 L 102.77799,181.74671 L 104.04044,181.84031 L 104.32104,183.66448 L 108.03968,184.92744 L 111.66013,184.328 Z",
      type:"inf",
   },
   {
      name:"Rezekne",
      path:"M 111.65995,184.3284 L 108.0395,184.92784 L 107.64211,187.61709 L 109.65129,188.91106 L 110.56854,188.231 C 112.19415,188.1736 114.17878,188.33893 115.64787,188.1726 C 115.54022,187.73897 115.4573,187.28289 115.44387,186.86307 C 115.76641,186.51044 116.01138,186.09261 116.25576,185.71198 L 115.0997,185.05592 L 112.7846,185.32051 Z",
      type:"inf",
   },
   {
      name:"Ludza",
      path:"M 116.25554,185.7123 C 116.19844,185.8023 116.13604,185.89871 116.08341,185.98045 C 115.89075,186.28103 115.67049,186.60085 115.45775,186.8454 C 115.40785,187.16538 115.50175,187.49568 115.56395,187.80024 C 115.59025,187.92569 115.62155,188.05965 115.64765,188.17283 C 115.75619,188.97182 115.83587,189.77744 115.92623,190.57941 L 117.61295,192.39842 L 121.84628,190.28175 L 121.64785,188.49581 L 120.98639,188.29738 V 185.5854 L 119.72962,185.25467 L 118.90279,185.9492 C 118.02694,185.8563 117.11281,185.8631 116.25554,185.7123 Z",
      type:"inf",
   },
   {
      name:"Nevel",
      path:"M 128.52714,187.27189 L 125.84823,186.67658 L 121.64797,188.49559 L 121.84641,190.28152 L 125.38521,190.77762 L 126.54276,192.03439 L 128.95709,191.83595 L 130.97867,189.34567 C 130.70121,189.11175 130.39223,188.85087 130.06503,188.57414 C 129.28781,187.91681 128.52714,187.27189 128.52714,187.27189 Z",
      type:"inf",
   },
   {
      name:"Opukhliki",
      path:"M 135.03165,194.21926 L 136.00162,192.46434 L 135.93552,188.52866 L 131.23917,189.02475 L 128.95714,191.83595 C 129.28579,192.33372 129.52964,192.70087 129.81652,193.13767 C 129.90392,192.89192 129.99261,192.69606 130.09102,192.48128 C 130.09502,192.47428 130.09902,192.46938 130.10192,192.46568 C 130.10392,192.46268 130.10492,192.46268 130.10692,192.46168 C 130.10792,192.46168 130.10992,192.45868 130.11092,192.45868 C 130.11192,192.45852 130.11292,192.4585 130.11392,192.45868 C 131.6974,193.14839 133.19621,193.61791 134.78517,194.13707 C 134.88768,194.17057 134.94526,194.18927 135.0327,194.21767 Z",
      type:"inf",
   },
   {
      name:"Haradok",
      path:"M 142.88337,197.33018 L 142.81467,195.83777 L 140.00347,194.38257 L 137.523,194.34947 L 135.54844,193.28442 L 135.03168,194.21924 C 135.23426,194.28494 135.43534,194.35004 135.63784,194.41566 L 136.0988,196.13958 C 137.12304,197.08089 138.07509,198.28137 138.9118,199.24155 C 139.69453,198.83348 140.6032,198.36166 141.40828,197.95274 C 141.85983,197.71559 142.27899,197.51636 142.6874,197.35144 C 142.7533,197.34444 142.82257,197.33334 142.88325,197.32534 Z",
      type:"inf",
   },
 ]
export const smolenskLine = [
   {
      name:"Vitebsk",
      path:"M 144.10448,197.12762 C 143.73079,197.19312 143.28017,197.26802 142.93246,197.32302 C 141.46195,197.81775 140.25993,198.54315 138.91193,199.24663 L 135.52454,201.41806 L 138.08511,204.0422 L 140.33406,201.85939 L 143.52663,201.02378 Z",
      type:"inf",
   },
   {
      name:"UnnaBeshenkovichi",
      path:"M 140.56568,208.43856 L 142.71542,207.87632 L 144.5675,207.81022 L 145.03052,207.08262 L 143.27766,205.36282 L 145.19589,202.84928 L 143.87297,200.93105 L 140.33417,201.85709 L 138.08521,204.03991 L 137.75603,205.5959 Z",
      type:"inf",
   },
   {
      name:"Orsa",
      path:"M 147.3787,209.56303 C 146.94875,209.27915 146.51881,208.99527 146.08886,208.71139 C 145.96122,208.09334 145.83357,207.4753 145.70593,206.85725 C 145.48079,206.93236 145.25566,207.00746 145.03052,207.08257 C 144.87618,207.3251 144.72184,207.56764 144.5675,207.81017 C 143.94416,207.83522 143.31918,207.84912 142.69686,207.88112 C 141.98647,208.06692 141.27607,208.25271 140.56568,208.43851 C 140.27646,209.08257 139.98725,209.72662 139.69803,210.37068 C 140.08074,210.74613 140.51248,211.09559 140.78451,211.56379 C 141.21876,211.35984 141.65302,211.15589 142.08727,210.95194 C 143.44326,211.11731 144.79925,211.28267 146.15524,211.44804 C 146.56306,210.8197 146.97088,210.19137 147.3787,209.56303 Z",
      type:"inf",
   },
   {
      name:"Chavusy",
      path:"M 143.9386,215.38903 L 146.29918,213.62275 L 145.4429,211.36138 L 142.08703,210.9521 L 140.78427,211.56395 C 140.85907,212.56191 140.85237,213.59625 140.86457,214.52402 Z",
      type:"inf",
   },
   {
      name:"Bykhaw",
      path:"M 144.01353,218.35837 L 144.03833,215.41694 L 140.86333,214.52397 L 139.01125,217.59975 L 139.81998,220.9861 L 140.00344,220.74168 L 142.00383,220.6409 L 142.21932,219.38569 Z",
      type:"inf",
   },
   {
      name:"Kanhnho",
      path:"M 143.93912,220.54324 L 140.00344,220.74168 L 138.61438,222.59376 L 138.51668,225.51503 L 139.64116,227.76399 L 139.72176,227.79189 L 143.49259,226.53149 L 143.31069,221.66771 Z",
      type:"inf",
   },
   {
      name:"Svietlahorsk",
      path:"M 138.51516,225.57033 L 136.72922,226.13257 L 135.07558,227.38934 L 135.04248,229.77059 L 138.38285,231.82111 L 141.55785,229.43986 L 141.65705,228.51394 L 139.6396,227.81928 Z",
      type:"inf",
   },
   {
      name:"Kapmaebl",
      path:"M 144.00526,234.00392 L 144.2037,232.84637 L 142.78156,231.72189 L 142.74846,229.93595 L 141.55784,229.43986 L 138.38284,231.82111 L 138.41594,233.97085 L 140.99563,234.43387 L 141.87413,235.39661 L 142.78156,235.32681 Z",
      type:"inf",
   },
   {
      name:"Chojniki",
      path:"M 141.87413,235.39661 L 140.99563,234.43387 L 138.41594,233.97085 L 138.38284,231.82111 L 137.42372,232.11876 L 135.737,232.71408 L 135.24091,233.50783 L 133.22346,232.78022 L 133.21006,233.69335 L 133.48808,233.67325 L 134.34798,234.86388 C 134.57298,234.78298 134.83904,234.78748 135.06008,234.78958 C 135.28852,235.10613 135.22449,235.54712 135.24148,235.88949 L 136.23366,235.85639 L 137.85424,237.37774 L 137.89454,238.2764 L 138.15189,238.2704 L 139.77247,239.22951 L 140.78067,240.56328 L 141.55272,240.62268 L 141.49222,235.42584 Z",
      type:"inf",
   },
   {
      name:"Berezino",
      path:"M 145.19589,202.84923 L 145.55969,201.85704 L 148.90005,202.25392 L 149.36307,200.07111 L 147.11412,196.10236 L 144.10448,197.12762 L 143.52674,201.02144 L 143.87297,200.93104 Z",
      type:"inf",
   },
   {
      name:"Krasny",
      path:"M 150.00386,202.92107 C 149.62893,202.70046 149.26082,202.45999 148.88164,202.25173 C 147.77432,202.12017 146.66701,201.9886 145.55969,201.85704 C 145.43306,202.1918 145.31938,202.53366 145.18464,202.86397 C 144.54898,203.6969 143.91332,204.52984 143.27766,205.36277 C 143.86195,205.93604 144.44623,206.5093 145.03052,207.08257 C 145.62583,206.88413 146.22115,206.68569 146.81646,206.48725 C 147.46689,206.63057 148.11733,206.77388 148.76776,206.9172 C 149.20873,206.52033 149.64971,206.12345 150.09068,205.72658 C 150.27285,205.75083 150.18623,205.60187 150.15908,205.49125 C 150.01054,204.94322 149.86199,204.3952 149.71344,203.84717 C 149.80878,203.53801 149.90592,203.2294 150.00386,202.92107 Z",
      type:"inf",
   },
   {
      name:"Kadino",
      path:"M 153.13339,205.23048 L 150.09068,205.72658 L 148.76776,206.9172 L 146.81646,206.48725 L 145.70593,206.85725 L 146.08886,208.71139 L 147.3787,209.56303 L 151.57896,209.3646 L 152.67037,207.94246 L 153.56334,208.67007 L 154.65474,206.35496 Z",
      type:"inf",
   },
   {
      name:"Monastyrshchina",
      path:"M 153.56334,208.67007 L 152.67037,207.94246 L 151.57896,209.3646 L 147.3787,209.56303 L 149.03235,212.14272 L 151.14901,212.83725 C 152.39294,212.34538 153.8532,211.73467 155.03043,211.32111 C 155.04283,211.31711 155.05243,211.31411 155.06353,211.31111 C 154.90106,210.7965 154.71073,210.23902 154.55555,209.79438 Z",
      type:"inf",
   },
   {
      name:"Lobkovichi",
      path:"M 147.3787,209.56303 C 146.9708,210.19142 146.5629,210.8198 146.155,211.44819 C 145.91763,211.41926 145.68027,211.39032 145.4429,211.36139 C 145.72833,212.11518 146.01375,212.86897 146.29918,213.62276 C 147.07794,214.18775 147.85671,214.75274 148.63547,215.31773 C 149.03641,215.23509 149.44448,215.16979 149.84097,215.07631 C 150.23288,214.81503 150.6248,214.55375 151.01672,214.29247 C 151.13127,214.3195 151.24582,214.34654 151.36037,214.37357 C 151.2919,213.86119 151.22081,213.34916 151.14898,212.83724 C 150.44343,212.60573 149.73787,212.37422 149.03232,212.14271 C 148.48111,211.28282 147.92991,210.42292 147.3787,209.56303 Z",
      type:"inf",
   },
   {
      name:"Cherykaw",
      path:"M 149.8261,215.08621 L 148.63547,215.31772 L 146.29918,213.62275 L 143.9386,215.38903 L 144.0383,215.41693 L 144.0135,218.35836 L 146.08883,217.16979 L 148.70159,218.09584 L 150.55367,217.20287 Z",
      type:"inf",
   },
   {
      name:"Kapma",
      path:"M 148.70162,218.09585 L 146.08886,217.1698 L 142.21932,219.38569 L 142.00384,220.6409 L 143.93912,220.5432 L 146.08886,220.74164 L 147.70943,222.42836 L 149.25145,220.88634 Z",
      type:"inf",
   },
   {
      name:"Vietka",
      path:"M 147.70943,222.4284 L 146.08886,220.74168 L 143.93912,220.54324 L 143.31073,221.66772 L 143.49263,226.5315 L 146.48625,226.5785 L 148.7781,224.28665 Z",
      type:"inf",
   },
   {
      name:"Gomel",
      path:"M 148.39569,232.13789 L 148.07633,230.36693 L 146.48625,226.57852 L 143.49263,226.53152 L 139.7218,227.79192 L 141.65864,228.45854 L 141.55944,229.38458 L 142.75006,229.88067 L 142.78316,231.66661 L 144.20529,232.79109 L 147.84331,232.59265 Z",
      type:"inf",
   },
   {
      name:"Horodnya",
      path:"M 151.47974,232.81329 L 150.28912,232.25105 L 148.40396,232.18495 L 147.84172,232.64797 L 144.2037,232.84641 L 144.00526,234.00396 L 144.89823,235.06229 V 235.95526 L 145.85735,235.82297 L 145.95655,236.55057 L 148.1466,236.04982 C 149.07881,235.59506 150.01481,234.87987 150.92942,234.57999 C 151.45333,234.20863 151.37113,233.4048 151.47974,232.81329 Z",
      type:"inf",
   },
   {
      name:"Honcharivske",
      path:"M 148.14661,236.04978 L 145.95656,236.55053 L 145.85736,235.82293 L 144.89824,235.95522 V 235.06225 L 144.00527,234.00392 L 142.78157,235.32684 L 141.49173,235.42604 L 141.55223,240.62262 L 143.51645,240.77404 C 143.88149,240.459 144.26818,240.11979 144.68439,239.834 C 145.01214,239.7661 145.38576,239.8197 145.691,239.839 L 146.83718,238.62306 L 148.14666,237.29032 Z",
      type:"inf",
   },
 ]
export const dniperLine = [
   {
      name:"Boryspil",
      path:"M 147.24641,250.20965 L 146.35344,248.75444 C 146.81257,248.15106 147.22857,247.54607 147.65779,247.07876 C 147.66479,247.07376 147.67119,247.06976 147.67639,247.06786 C 147.67939,247.06786 147.68039,247.06486 147.68239,247.06286 C 147.22323,246.46449 146.65614,245.9022 146.17396,245.42707 C 145.50327,244.71918 144.98282,244.40259 144.27062,244.22263 C 143.944,244.84829 143.50131,245.61608 143.25096,246.24674 C 142.77798,246.63933 142.18028,246.96345 141.68365,247.22086 L 141.80923,247.81255 L 141.9963,249.87082 L 143.63341,251.55444 Z",
      type:"inf",
   },
   {
      name:"Pereyaslav-Khmelnytskyi",
      path:"M 154.25787,249.84585 L 151.94276,249.68048 L 150.05761,249.71358 L 149.39615,250.37504 L 147.24641,250.20968 L 143.63319,251.55482 L 146.86039,253.65961 L 148.43703,253.02088 L 149.6938,253.21931 L 151.31438,253.41775 L 153.26568,250.80499 Z",
      type:"inf",
   },
   {
      name:"Shramkivka",
      path:"M 154.25787,249.84585 L 153.26568,250.80496 L 151.31438,253.41772 L 149.6938,253.21928 L 149.793,255.30288 L 151.64509,256.09663 L 153.56332,256.29506 L 157.0029,253.91381 L 157.3667,252.78934 Z",
      type:"inf",
   },
   {
      name:"Lazirky",
      path:"M 162.85682,252.49168 L 161.03781,252.72319 L 157.36672,252.78939 L 157.00292,253.91386 L 157.6313,255.10449 L 158.855,254.34381 L 159.38417,258.04798 L 160.40943,258.34564 L 161.79849,257.08886 L 162.81238,257.28265 L 163.65057,255.50136 L 162.8899,253.64928 Z",
      type:"inf",
   },
   {
      name:"Lubny",
      path:"M 169.63832,252.67721 L 166.82557,251.03647 L 162.85682,252.49168 L 162.88992,253.64923 L 163.65059,255.50131 L 162.8124,257.2826 L 164.742,257.65105 L 166.89174,257.08881 L 168.37589,255.95142 L 168.04826,254.68844 L 169.68537,253.75311 Z",
      type:"inf",
   },
   {
      name:"Myrhorod",
      path:"M 172.3255,251.56511 L 171.1819,251.50771 L 169.63832,252.67717 L 169.68532,253.75307 L 168.04821,254.6884 L 168.37584,255.95138 L 166.89169,257.08877 L 167.32164,258.57706 L 168.28075,258.74242 L 169.86825,258.04789 L 171.11986,258.88762 L 172.11721,257.21435 L 173.52074,256.27849 L 175.24932,254.19799 L 172.35131,252.06944 Z",
      type:"inf",
   },
   {
      name:"Poltava",
      path:"M 175.24935,254.19803 L 173.52077,256.27853 L 172.11724,257.21439 L 171.11989,258.88766 L 172.48104,259.80079 L 175.92063,260.82605 L 177.50968,260.0168 L 176.8415,258.75744 L 178.15098,256.13848 L 178.50083,255.77106 L 177.16861,254.12723 L 175.34444,254.2678 Z",
      type:"inf",
   },
   {
      name:"Mahdalynivka",
      path:"M 183.46125,262.38927 L 181.61227,260.30102 L 177.77684,259.88038 L 175.92063,260.82605 L 177.50813,263.86876 H 178.83104 C 179.56516,264.29372 180.28838,264.80282 180.91974,265.23966 C 181.13943,265.39193 181.28458,265.4934 181.28458,265.4934 L 181.6892,265.4685 L 181.79927,263.94974 L 183.10876,262.45318 Z",
      type:"inf",
   },
   {
      name:"Synelnykove",
      path:"M 187.43517,267.05977 L 185.4942,266.1477 L 183.59975,265.35239 L 181.28464,265.49296 L 179.41395,266.94298 L 180.55962,267.73776 C 180.55962,267.73776 180.60372,267.91935 180.67021,268.19306 C 180.73671,268.46674 180.82557,268.8325 180.91619,269.20229 C 180.96109,269.3854 181.00599,269.56971 181.049,269.74386 L 181.1461,269.65656 L 183.85808,269.62346 L 185.85382,269.76041 L 186.17215,268.76719 Z",
      type:"inf",
   },
   {
      name:"Zaporozhe",
      path:"M 183.19667,276.53569 C 183.60457,275.87423 184.01247,275.21277 184.42037,274.55131 C 184.25343,274.12292 184.12153,273.6684 183.85917,273.28684 C 183.68576,273.07162 183.79879,272.72202 183.75736,272.46047 C 183.76442,271.51405 183.80874,270.56836 183.85707,269.6233 C 182.95308,269.63433 182.04908,269.64537 181.14509,269.6564 C 180.81436,269.95406 180.48363,270.25171 180.1529,270.54937 C 180.00146,270.99872 179.83481,271.44524 179.6928,271.89635 C 179.67165,272.33159 179.67242,272.77254 179.63764,273.20423 C 179.39186,273.68371 179.14607,274.16318 178.90029,274.64266 C 179.25049,274.88227 179.60068,275.12187 179.95088,275.36148 C 180.04028,275.88513 180.12968,276.40879 180.21908,276.93244 C 180.46161,276.94347 180.70415,276.95451 180.94668,276.96554 C 181.21126,276.72301 181.47585,276.48047 181.74043,276.23794 C 182.22584,276.33719 182.71126,276.43644 183.19667,276.53569 Z",
      type:"inf",
   },
   {
      name:"Enerhodor",
      path:"M 180.22011,276.93256 L 179.95191,275.3616 L 178.61865,274.4495 C 178.46233,274.58699 178.2867,274.72651 178.11636,274.8598 C 177.91705,275.03746 177.82382,275.03288 177.58572,275.0189 C 176.29212,274.9629 174.98947,274.91846 173.66658,274.8726 C 173.21347,274.857 172.91262,274.8467 172.91262,274.8467 L 171.93026,275.52469 L 171.96176,276.29572 L 172.81184,276.10503 L 173.341,277.42795 L 174.26704,276.6342 L 175.02772,277.42795 L 175.04992,279.08675 L 176.979,279.47847 L 178.23577,278.28784 L 179.6579,277.85789 Z",
      type:"inf",
   },
   {
      name:"Nova Kakhovka",
      path:"M 175.04988,279.08746 C 175.04248,278.53453 175.03508,277.98159 175.02768,277.42866 C 174.77412,277.16408 174.52057,276.89949 174.26701,276.63491 C 173.95833,276.89949 173.64964,277.16408 173.34096,277.42866 C 173.16457,276.98769 172.98819,276.54671 172.8118,276.10574 C 172.27161,276.22701 171.73141,276.34827 171.19122,276.46954 C 170.12186,277.32944 169.05251,278.18933 167.98315,279.04923 C 168.03178,279.53269 168.08045,280.01614 168.12725,280.49978 C 168.27722,280.48802 168.41835,280.51826 168.55091,280.58919 C 168.99294,280.77207 169.43498,280.95494 169.87702,281.13782 C 170.0615,280.90476 170.24599,280.67169 170.43047,280.43863 C 171.17636,280.52737 171.92209,280.64885 172.67021,280.69893 C 173.227,280.38864 173.70727,279.95776 174.21332,279.57292 C 174.44648,279.41676 174.6552,279.16546 174.89845,279.05716 C 174.94893,279.06726 174.9994,279.07736 175.04988,279.08746 Z",
      type:"inf",
   },
   {
      name:"Kherson",
      path:"M 169.87707,281.13748 L 168.34693,280.50444 L 166.7925,280.47134 L 166.09797,281.62889 L 162.95604,281.52969 L 159.78311,282.3286 L 159.84871,283.58537 L 160.49208,285.79766 L 164.26239,284.2763 L 167.487,284.93776 L 170.18245,282.90378 L 170.23875,282.92128 L 169.80209,281.23207 Z",
      type:"inf",
   },
   {
      name:"Oster",
      path:"M 149.50312,242.1538 C 149.60142,241.65394 149.78863,241.01372 149.61492,240.50515 C 149.28019,240.45075 148.92798,240.47965 148.61333,240.51695 L 146.83617,238.62251 C 146.31884,239.13302 145.97815,239.85474 145.28691,239.81515 C 144.50432,239.69181 144.07811,240.28562 143.51545,240.77369 L 144.14642,241.26463 L 144.26893,244.22346 V 244.22346 H 144.26945 C 145.1966,244.34602 145.51907,244.78524 146.17269,245.4283 C 146.21529,245.4703 146.26039,245.5154 146.30395,245.55853 L 147.49096,244.58597 C 147.54646,244.06976 147.59525,243.55046 147.60756,243.08931 C 147.60677,243.08631 147.60656,243.08631 147.60656,243.08431 C 147.6065,243.08131 147.60651,243.08031 147.60709,243.07931 C 147.60762,243.07631 147.60909,243.07631 147.61009,243.07431 C 147.61109,243.07131 147.61209,243.07131 147.61409,243.07031 C 148.04475,243.03631 148.50439,243.05811 148.89361,243.08731 Z",
      type:"inf",
   },
   {
      name:"Nizhyn",
      path:"M 152.17427,246.2409 L 152.75356,245.10041 L 152.96441,243.08916 L 152.16911,242.87831 L 151.51436,242.22357 L 149.50312,242.15377 L 148.89489,243.08913 C 148.45484,243.05753 148.01618,243.03623 147.62976,243.06413 C 147.56076,243.60132 147.54966,244.08219 147.49203,244.58779 L 146.30503,245.56035 C 146.78568,246.06921 147.28996,246.53596 147.6604,246.9975 C 148.65921,247.29538 149.75596,247.45857 150.68611,247.63187 Z",
      type:"inf",
   },
   {
      name:"Yahotyn",
      path:"M 153.39642,246.92458 L 152.17427,246.2409 L 150.68599,247.62996 L 147.68204,247.06359 C 147.68104,247.06659 147.67904,247.06759 147.67604,247.06859 C 147.16115,247.63559 146.78319,248.15785 146.35309,248.75527 L 147.24606,250.21048 L 149.3958,250.37584 L 150.05726,249.71438 L 151.94242,249.68128 L 154.25752,249.84665 H 154.25952 H 154.25752 L 153.33148,247.79613 C 153.35518,247.53041 153.37758,247.21263 153.39518,246.95742 C 153.39598,246.94392 153.39571,246.93702 153.39618,246.92542 Z",
      type:"inf",
   },
   {
      name:"Pryluky",
      path:"M 158.21731,244.41826 C 157.60946,244.59075 156.99396,244.74681 156.39088,244.92952 C 156.07675,245.14616 155.76262,245.3628 155.44849,245.57944 C 155.17288,245.57944 154.89728,245.57944 154.62167,245.57944 C 154.5004,245.94324 154.37914,246.30705 154.25787,246.67085 C 153.97855,246.71467 153.62342,246.65918 153.40089,246.85625 C 153.41736,247.19615 153.28309,247.56013 153.37145,247.88532 C 153.6679,248.53894 153.96172,249.19698 154.2597,249.84777 C 155.31752,249.40697 156.37533,248.96616 157.43315,248.52536 C 157.60954,248.7238 157.78593,248.92223 157.96232,249.12067 C 158.83324,249.1427 159.70416,249.16474 160.57508,249.18677 C 160.81124,248.92374 161.0474,248.66071 161.28356,248.39768 C 160.71295,247.62434 160.17384,246.82693 159.55753,246.08848 C 159.11079,245.53174 158.66405,244.975 158.21731,244.41826 Z",
      type:"inf",
   },
   {
      name:"Pyryatyn",
      path:"M 160.57479,249.18439 L 157.96203,249.11819 L 157.43287,248.52288 L 154.25787,249.8458 L 157.36672,252.78929 L 161.03781,252.72309 Z",
      type:"inf",
   },
   {
      name:"Lokhvytsya",
      path:"M 167.58625,248.25835 L 164.54354,247.72918 L 162.59224,246.77006 L 162.03,247.56381 L 160.57479,249.18439 L 161.03781,252.72319 L 162.85682,252.49168 L 166.82557,251.03647 Z",
      type:"inf",
   },
   {
      name:"Hadyach",
      path:"M 174.08198,248.98233 L 172.01802,247.8284 L 169.33912,247.20001 L 167.58625,248.25835 L 166.82557,251.03647 L 169.63832,252.67721 L 171.1819,251.50775 L 172.3255,251.56515 L 172.2578,250.24535 Z",
      type:"inf",
   },
   {
      name:"Kotelva",
      path:"M 180.95753,253.1919 L 178.52512,252.35007 L 178.38507,251.22716 L 176.13973,250.99358 L 174.08198,248.98233 L 172.2578,250.24531 L 172.3513,252.06948 L 175.3444,254.2678 L 177.16857,254.12723 L 178.50079,255.77106 Z",
      type:"inf",
   },
   {
      name:"Karlivka",
      path:"M 183.01528,256.32554 L 183.06228,253.79962 L 180.9575,253.1919 L 178.15095,256.13848 L 176.84147,258.75744 L 177.50965,260.0168 L 177.77681,259.88038 L 179.95188,260.11911 L 180.1622,259.22564 L 182.26698,258.47685 Z",
      type:"inf",
   },
   {
      name:"Krasnohrad",
      path:"M 187.11219,261.95672 L 187.64549,260.44159 L 185.02653,256.60616 L 183.01528,256.32554 L 182.26701,258.47685 L 180.16223,259.22564 L 179.95191,260.11911 L 181.61227,260.30102 L 183.46125,262.38927 L 186.99075,261.75106 Z",
      type:"inf",
   },
   {
      name:"Pereshchepyne",
      path:"M 188.67489,264.60412 L 186.99075,261.75106 L 183.10882,262.45282 L 181.79934,263.94938 L 181.68927,265.46814 L 183.59975,265.35239 L 185.4942,266.1477 L 185.96497,266.36886 Z",
      type:"inf",
   },
   {
      name:"Pavlohrad",
      path:"M 195.53132,269.43276 C 195.54633,269.24308 195.56856,269.05245 195.47879,268.87654 C 195.11286,267.76419 194.74692,266.65183 194.38099,265.53947 C 193.61704,265.21218 192.85309,264.8849 192.08914,264.55761 C 190.95105,264.57311 189.81297,264.58861 188.67488,264.60411 C 187.77157,265.19236 186.86827,265.7806 185.96496,266.36885 C 186.46319,266.59841 186.95644,266.84393 187.45777,267.06353 C 187.92346,267.14697 188.39705,267.20885 188.85786,267.3056 C 190.13476,268.06719 191.41167,268.82878 192.68858,269.59037 C 193.22022,269.34906 193.76062,269.06566 194.35095,269.01877 C 194.76065,269.08347 195.14542,269.29155 195.53132,269.43276 Z",
      type:"inf",
   },
   {
      name:"Vasylkivka",
      path:"M 192.6886,269.59038 C 191.40513,268.82488 190.12166,268.05937 188.83819,267.29387 C 188.37052,267.21584 187.90284,267.1378 187.43517,267.05977 C 187.0141,267.63592 186.57824,268.20448 186.16638,268.78535 C 186.06221,269.11037 185.95804,269.43538 185.85387,269.7604 C 186.3131,269.79193 186.77233,269.82347 187.23156,269.855 C 187.3418,270.61568 187.45205,271.37635 187.56229,272.13703 C 187.90936,272.34556 188.25541,272.55605 188.59423,272.77781 C 188.81486,272.71192 189.03006,272.61215 189.25406,272.56731 C 190.43196,272.57823 191.60987,272.58914 192.78777,272.60006 C 192.75471,271.59683 192.72166,270.59361 192.6886,269.59038 Z",
      type:"inf",
   },
   {
      name:"Novomykolaivka",
      path:"M 188.18241,277.2421 C 188.17414,276.69795 188.16588,276.1538 188.15761,275.60965 C 188.53244,275.21277 188.90726,274.8159 189.28209,274.41902 C 189.19569,273.97304 189.1487,273.51268 189.00759,273.08133 C 188.56275,272.7154 188.04923,272.44196 187.56216,272.13665 C 187.45192,271.37597 187.34167,270.6153 187.23143,269.85462 C 186.10695,269.77745 184.98247,269.70028 183.85799,269.62311 C 183.80978,270.7762 183.72427,271.93185 183.77869,273.08595 C 183.80657,273.33803 184.06213,273.49469 184.11193,273.75518 C 184.18481,274.03663 184.44211,274.34496 184.3779,274.62155 C 183.9845,275.2595 183.59109,275.89745 183.19769,276.5354 C 183.31155,276.80102 183.42541,277.06663 183.53927,277.33225 C 183.79546,277.26458 184.04456,277.15238 184.31438,277.1908 C 185.4514,277.20845 186.58842,277.24771 187.72544,277.25142 C 187.87776,277.24832 188.03009,277.24521 188.18241,277.2421 Z",
      type:"inf",
   },
   {
      name:"Melitopol",
      path:"M 183.68966,280.64395 L 182.63443,279.34689 L 183.69276,277.69324 L 183.19667,276.53569 L 181.74146,276.23803 L 180.94771,276.96564 L 180.22011,276.93254 L 179.65787,277.85859 L 178.23573,278.28853 L 176.97896,279.47916 L 174.86229,279.04921 L 176.84667,280.90129 L 177.16086,281.94309 L 177.16286,281.94609 L 182.56873,281.59882 Z",
      type:"inf",
   },
   {
      name:"Tavrychanka",
      path:"M 177.16241,281.94467 L 177.16041,281.94167 L 176.84622,280.89987 L 174.86185,279.04779 C 174.16216,279.57551 173.36971,280.28074 172.66968,280.69678 C 171.90184,280.64388 171.08515,280.52088 170.42996,280.43838 L 169.80158,281.23213 L 170.23824,282.92145 L 174.06798,284.11104 L 176.86264,283.61494 L 177.1603,281.94476 Z",
      type:"inf",
   },
   {
      name:"Novotroits'ke",
      path:"M 174.06854,284.10939 L 170.18248,282.90223 L 167.48703,284.93621 L 168.31541,287.48645 H 168.31594 L 168.91073,287.02343 L 171.755,287.61874 L 173.01177,286.6927 Z",
      type:"inf",
   },
   {
      name:"Skadovs'k",
      path:"M 167.48703,284.93621 L 164.26242,284.27475 L 160.49211,285.79611 L 159.15576,288.37735 L 163.22218,288.54478 L 164.80968,287.61874 L 165.93416,288.54478 L 167.78624,288.61088 V 289.86712 H 167.78677 V 288.61035 L 168.31542,287.4864 Z",
      type:"inf",
   },
   {
      name:"Bekhtery",
      path:"M 159.15576,288.37735 L 160.49211,285.79611 L 159.84874,283.58385 L 158.12895,284.17916 L 155.41697,284.70832 L 153.56489,285.56822 L 152.24197,286.49426 L 155.02009,287.02343 L 156.80603,288.2802 Z",
      type:"inf",
   },
 ]
export const moscowArea = [
   {
      name:"Moscow",
      path:"M 199.30163,190.46083 L 196.85837,191.38328 L 196.09769,193.73145 L 197.45368,193.96296 L 201.60485,194.72517 C 201.86683,193.94484 202.45813,193.27789 202.81362,192.63979 C 202.81462,192.63679 202.81562,192.63679 202.81762,192.63579 C 202.41825,192.17165 201.95226,191.6281 201.53757,191.1377 C 200.80252,191.03104 199.96327,190.84521 199.32781,190.6078 C 199.28011,190.5757 199.30911,190.50482 199.30141,190.46246 Z",
      type:"tank",
   },
   {
      name:"Podolsk",
      path:"M 201.60485,194.72517 L 197.45368,193.96296 L 197.15602,196.14578 L 196.616,196.55453 L 198.2805,198.36166 L 199.90108,198.32856 L 202.27974,196.36691 C 202.27974,196.36691 202.26764,196.34361 202.24614,196.30231 C 202.22464,196.26111 202.19394,196.20206 202.15774,196.13022 C 201.92645,195.63926 201.73345,195.21397 201.60884,194.76543 C 201.60684,194.75143 201.60584,194.73803 201.60484,194.72513 Z",
      type:"tank",
   },
   {
      name:"Odintsovo",
      path:"M 190.61689,195.14943 L 191.4649,197.25991 L 192.6886,198.05366 L 196.06203,196.99533 L 197.15344,196.1685 L 197.4511,193.98569 L 196.09511,193.75418 L 194.70604,193.12579 L 191.66333,194.61408 L 191.13417,195.14324 Z",
      type:"tank",
   },
   {
      name:"Zelenograd",
      path:"M 191.66333,194.61408 L 194.70604,193.12579 L 196.09511,193.75418 L 196.85578,191.406 L 196.2274,189.95079 L 194.04458,189.78543 L 192.98625,189.55392 L 192.09328,190.44689 L 191.00188,190.54609 L 190.35747,191.31348 L 190.67115,193.48958 Z",
      type:"tank",
   },
   {
      name:"Klin",
      path:"M 194.07535,187.53934 C 194.08945,188.33091 194.06935,189.0238 194.04675,189.76311 L 196.22956,189.92848 L 196.85795,191.38368 L 199.30121,190.46124 C 199.30107,190.45034 199.30138,190.45064 199.30121,190.43844 C 199.28531,189.51498 199.26501,188.49637 199.19571,187.59464 C 198.44464,187.20134 197.6205,186.78462 196.96172,186.4328 C 196.01824,186.804 194.99638,187.1593 194.07538,187.53934 Z",
      type:"tank",
   },
   {
      name:"Elektrostal",
      path:"M 208.99354,192.86121 L 207.53833,190.97606 C 206.25784,191.43838 204.93224,192.07109 203.66055,192.44934 C 203.40048,192.41234 203.12592,192.48884 202.90805,192.55064 C 202.63646,193.46466 201.74771,194.04524 201.60755,194.76602 C 201.75132,195.31344 202.02319,195.87032 202.24485,196.3029 C 202.84622,196.4689 203.746,196.3675 204.42819,196.3675 L 207.00788,195.24302 C 207.07528,194.85179 207.15588,194.38141 207.20448,194.02195 C 207.73321,193.60076 208.42688,193.17721 208.99217,192.86172 Z",
      type:"tank",
   },
   {
      name:"Dmitrov",
      path:"M 205.31211,188.22894 C 205.31011,188.08133 205.30811,187.94666 205.30611,187.7928 C 205.29811,187.20574 205.28961,186.6435 205.28961,186.6435 L 204.42971,186.80887 C 204.38351,186.38948 204.23433,186.04146 204.02241,185.75341 C 202.85969,185.96154 201.8416,186.17914 200.7584,186.37936 C 199.94305,187.72558 199.69325,187.32033 199.19814,187.5489 C 199.19314,187.6066 199.20614,187.66913 199.21014,187.7226 C 199.27454,188.68342 199.28674,189.50572 199.30254,190.43719 C 199.30354,190.51239 199.30454,190.56173 199.30454,190.57827 C 199.30454,190.58127 199.30507,190.58227 199.30654,190.58427 C 199.30754,190.58727 199.30954,190.58927 199.31154,190.59127 C 199.31354,190.59427 199.31554,190.59627 199.31854,190.59827 C 199.32154,190.60127 199.32454,190.60327 199.32884,190.60527 C 199.34994,190.62047 199.37494,190.62857 199.39594,190.63917 C 202.61912,191.67014 200.62746,191.11213 202.4604,189.52399 C 203.91925,189.06945 204.10222,188.02003 205.16584,188.19551 C 205.21314,188.20581 205.26324,188.21691 205.31363,188.22811 Z",
      type:"tank",
   },
   {
      name:"Sergiev Posad",
      path:"M 207.5435,190.94351 L 207.08048,189.91825 L 206.25365,189.28986 C 206.25365,189.28986 206.25436,189.27546 206.25465,189.25006 C 206.28915,188.67842 205.93681,188.33094 205.52433,188.24265 C 205.33074,188.19835 205.12548,188.15495 204.9435,188.11755 C 204.64972,188.09215 204.38875,188.28828 204.1676,188.41372 C 203.58816,188.75917 203.0272,189.10507 202.53366,189.44152 C 202.07662,189.92573 201.69126,190.46806 201.48278,190.9846 C 201.47978,190.9965 201.4821,191.0078 201.48178,191.0187 C 201.48221,191.0217 201.48253,191.0247 201.48378,191.0277 C 201.91956,191.56306 202.45519,192.17765 202.82142,192.60329 C 204.48188,192.22306 206.1231,191.51956 207.5435,190.94351 Z",
      type:"tank",
   },
 ]
export const leningradArea = [
   {
      name:"Petergof",
      path:"M 138.38287,149.56876 L 135.86467,149.85867 L 136.03469,150.2633 V 151.8508 L 135.41716,152.72257 L 137.42375,153.76902 L 138.18443,154.72814 L 140.26802,154.33126 L 140.76412,150.89168 L 141.15376,150.87208 L 140.33417,149.99869 Z",
      type:"tank",
   },
   {
      name:"Tosno",
      path:"M 145.30854,155.65212 L 147.21334,154.59585 L 144.69979,150.69324 L 140.76412,150.89168 L 140.26802,154.33126 Z",
      type:"tank",
   },
   {
      name:"Kirovsk",
      path:"M 148.11561,154.81289 L 149.49537,152.64455 L 150.28912,149.73413 L 149.03648,147.65416 L 147.84172,149.10574 L 145.39898,148.80343 L 145.42738,149.00859 L 144.69977,150.72838 L 147.18644,154.58966 C 147.18944,154.58966 147.21334,154.59566 147.21334,154.59566 Z",
      type:"tank",
   },
   {
      name:"Vsevolozhsk",
      path:"M 144.69979,150.69324 L 145.4274,148.97345 L 145.16281,147.05522 L 141.98781,143.28491 L 140.43339,143.71486 L 140.10266,145.99689 L 142.18625,147.35288 L 140.33417,149.99871 L 141.15376,150.87205 Z",
      type:"tank",
   },
   {
      name:"Leningrad",
      path:"M 140.43339,143.71486 L 136.89459,143.18569 L 136.10084,148.27892 L 138.44901,148.80809 L 138.38291,149.56876 L 140.33421,149.99871 L 142.18629,147.35288 L 140.1027,145.99689 Z",
      type:"tank",
   },
   {
      name:"Sanatoriy",
      path:"M 136.63775,144.8321 C 135.20622,143.90104 133.84006,143.03665 132.36361,142.06119 C 130.93548,143.22772 129.50736,144.50752 128.29542,145.6329 L 129.35375,146.75738 L 132.06573,147.05503 L 134.61234,146.45972 L 136.10063,148.27873 Z",
      type:"tank",
   },
   {
      name:"Zaporozhskoye",
      path:"M 141.95836,143.29318 C 141.41925,142.79789 140.7757,142.2052 140.27216,141.69483 C 140.18586,141.60493 140.10741,141.5037 140.03276,141.41222 C 138.9304,141.0356 137.83509,140.83091 136.75693,140.6107 L 132.83004,141.17191 L 132.99128,141.26701 V 142.47262 C 134.20028,143.25836 135.53349,144.11929 136.63705,144.83165 L 136.89389,143.18524 L 140.43269,143.71441 Z",
      type:"tank",
   },
 ]
