import React, {useEffect, useState} from 'react'
import { useLocation} from 'react-router-dom';
import { useSelector } from "react-redux"
import { db } from '../../../../firebase-config';
import { doc, setDoc, arrayUnion, getDoc, increment, updateDoc,} from "firebase/firestore";
import { useDispatch } from 'react-redux'
import { addFreeMoves } from '../../../../features/freeMoves';
import { setSelectionRange } from '@testing-library/user-event/dist/utils';
import { Link} from "react-router-dom"
import { setTotalTime } from '../../../../features/totalTime';

const GameInfo = () => {

   let location = useLocation();
   const gameId = location.state.id;
   const dispatch = useDispatch();

   const user = useSelector((state) => state.user.value)
   const pts = useSelector((state) => state.pts.value)
   const totalTime = useSelector((state) => state.totalTime.value)
   const freeMoves = useSelector((state) => state.freeMoves.value)
   const faction = useSelector((state) => state.faction.value)

   const docRefGame = doc(db, "games", gameId);
   const docRefPlayers = doc(db, "games", gameId, "players", user.uid);
   const docRefUpgrade = doc(db, "games", gameId, "players", user.uid, "ww2", "economy");
   const docRefArmy = doc(db, "games", gameId, "players", user.uid, "ww2", "army");

   const [item, setItem] = useState(null);
   const [dayOfTimeframe, setDayOfTimeframe] = useState(1);
   const [timeframe, setTimeframe] = useState(1);
   const [responsiveToggler, setResponsiveToggler] = useState(false);

   useEffect(()=>{

      async function fetchData(){
      
         const docSnap = await getDoc(docRefGame);
         setItem(docSnap)
         const date = new Date();
         const todayDay = date.getDate();
         const now = Math.floor(Date.now()/1000);
         const gameDuration = (now - docSnap.data().midnightStart)/60/60/24;
         const currentDay = Math.floor(gameDuration);
         setDayOfTimeframe(currentDay+1);
         setTimeframe(docSnap.data().timeframe);

         const gameLoaded = (docSnap.data().loaded)
         if (gameLoaded !== true){
            return;
         }
         if (todayDay !== docSnap.data().currentDay){

            const docSnapGame = await getDoc(docRefGame);

            if(currentDay + 1 === 3 & docSnapGame.data().playerCount !== "Singleplayer" & 
               docSnapGame.data().players.length < docSnapGame.data().playerCount){
               if (faction.faction === "Germany"){
                  updateDoc(docRefGame, {
                     victory: 10
                  });
               } else if (faction.faction === "Russia"){
                  updateDoc(docRefGame, {
                     victory: -10
                  });
               }
            }
            
            await updateDoc(docRefGame, {
               currentDay: todayDay,
            })

            //time tracking history
            const docSnapPlayers = await getDoc(docRefPlayers);
            const totalProductiveYesterday = docSnapPlayers.data().totalProductiveTimeToday;
            const totalMaintenanceYesterday = docSnapPlayers.data().totalMaintenanceTimeToday;

            await updateDoc(doc(db, "games", gameId, "players", user.uid, "time", "history"), {
               logs: arrayUnion({day: currentDay, productiveTime: totalProductiveYesterday, maintenanceTime: totalMaintenanceYesterday}),
            });

            await updateDoc(docRefPlayers, {
               totalProductiveTimeToday: 0,
               totalMaintenanceTimeToday: 0,
            })

            updateDoc(doc(db, "users", user.uid), {
               totalTime: increment(totalTime.minutes+(totalTime.hours*60))
            })
            updateDoc(doc(db, "games", gameId, "players", user.uid), {
               totalTime: 0,
            })
            dispatch(setTotalTime({ minutes: 0, hours: 0}));

            const docSnapUpgrade = await getDoc(docRefUpgrade);
            const infrastructureLevel = docSnapUpgrade.data().infrastructureLevel; 
            let freeMovesNum = 0;

            if (infrastructureLevel === 1){
               freeMovesNum = 0
            } else if (infrastructureLevel === 2 || infrastructureLevel === 3){
               freeMovesNum = 2
            } else if (infrastructureLevel === 4){
               freeMovesNum = 3
            } else if (infrastructureLevel === 5){
               freeMovesNum = 4
            } else if (infrastructureLevel === 6 || infrastructureLevel === 7){
               freeMovesNum = 5
            } else if (infrastructureLevel === 8){
               freeMovesNum = 6
            } else if (infrastructureLevel === 9){
               freeMovesNum = 7
            } else if (infrastructureLevel === 10){
               freeMovesNum = 20;
            }

            let freeAttacks = 0;
            if (faction.faction === "Germany"){
               if(dayOfTimeframe===1||dayOfTimeframe===2){
                  freeAttacks=8;
               } else if(dayOfTimeframe===3||dayOfTimeframe===4){
                  freeAttacks=7;
               } else if(dayOfTimeframe===5||dayOfTimeframe===6||dayOfTimeframe===7){
                  freeAttacks=6;
               } else if(dayOfTimeframe >= 8||dayOfTimeframe <22){
                  freeAttacks=5;
               } else if(dayOfTimeframe >= 22||dayOfTimeframe <29){
                  freeAttacks=4;
               } else if(dayOfTimeframe >= 29||dayOfTimeframe <36){
                  freeAttacks=3;
               } else if(dayOfTimeframe >= 36||dayOfTimeframe <43){
                  freeAttacks=2;
               }
            }
            updateDoc(docRefArmy, {
               freeMoves: freeMovesNum,
               freeAttacks: freeAttacks,
            });
            dispatch(addFreeMoves({ freeAttacks: freeAttacks, freeMoves: freeMovesNum, distance: 1}));

            //check victory
            const docRefMoscow = doc(db, "games", gameId, "territories", "Moscow");
            const docSnapMoscow = await getDoc(docRefMoscow);
            const docRefKharkov = doc(db, "games", gameId, "territories", "Kharkov");
            const docSnapKharkov = await getDoc(docRefKharkov);
            const docRefLeningrad = doc(db, "games", gameId, "territories", "Leningrad");
            const docSnapLeningrad = await getDoc(docRefLeningrad);
            const docRefGrozny = doc(db, "games", gameId, "territories", "Grozny");
            const docSnapGrozny = await getDoc(docRefGrozny);
            const docRefMaikop = doc(db, "games", gameId, "territories", "Maikop");
            const docSnapMaikop = await getDoc(docRefMaikop);
            const docRefStalingrad = doc(db, "games", gameId, "territories", "Stalingrad");
            const docSnapStalingrad = await getDoc(docRefStalingrad);
            const docRefBaku = doc(db, "games", gameId, "territories", "Baku");
            const docSnapBaku = await getDoc(docRefBaku);
            

            if (docSnapMoscow.data().controlledBy === "Germany" &
               docSnapLeningrad.data().controlledBy === "Germany" &
               docSnapKharkov.data().controlledBy === "Germany" &
               dayOfTimeframe < 170){

               updateDoc(docRefGame, {
                  victory: increment(1)
               });
            } else if (docSnapMoscow.data().controlledBy === "Germany" &
               docSnapMaikop.data().controlledBy === "Germany" &
               docSnapGrozny.data().controlledBy === "Germany" &
               docSnapStalingrad.data().controlledBy === "Germany" &
               dayOfTimeframe < 500){
               
               updateDoc(docRefGame, {
                  victory: increment(1)
               });
            } else if (docSnapLeningrad.data().controlledBy === "Germany" &
               docSnapMaikop.data().controlledBy === "Germany" &
               docSnapGrozny.data().controlledBy === "Germany" &
               docSnapStalingrad.data().controlledBy === "Germany" &
               dayOfTimeframe < 500){
               
               updateDoc(docRefGame, {
                  victory: increment(1)
               });
            } else if (docSnapBaku.data().controlledBy === "Germany" &
               docSnapStalingrad.data().controlledBy === "Germany" &
               docSnapMaikop.data().controlledBy === "Germany" &
               docSnapGrozny.data().controlledBy === "Germany" &
               dayOfTimeframe < 500){
               
               updateDoc(docRefGame, {
                  victory: increment(1)
               });
            } else if (dayOfTimeframe >= 500){
               updateDoc(docRefGame, {
                  victory: -10
               });
            }
         } else {
            const docSnapArmy = await getDoc(docRefArmy);
            dispatch(addFreeMoves({ freeAttacks: docSnapArmy.data().freeAttacks, freeMoves: docSnapArmy.data().freeMoves, distance: 1}));
         }
      }
      fetchData();
   }, [])

   if (item === null){
      return null
   }

   return (
      <>
      {responsiveToggler ? 
      <button onClick={()=>setResponsiveToggler(true)}>Show game information</button>
      :
      <section className='game-info-container'>
         <div>
            <h3>Day: {dayOfTimeframe}</h3>
         </div>
         <h3>Germany: </h3>
         {item.data().playersGermany.map( (object) => {
            return <>
            <Link to={`/profile/${object.uid}`} 
               state={{ 
                  nickName: object.nickName,
                  uid: object.uid,
               }}>
               <h3 className='game-info-name'>{object.nickName}</h3>
            </Link>
            </>
         })}
         <h3>Russia: </h3>
         {item.data().playersRussia.map( (object) => {
            return <>
            <Link to={`/profile/${object.nickName}`} 
               state={{ 
                  nickName: object.nickName,
                  uid: object.uid,
               }}>
               <h3 className='game-info-name'>{object.nickName}</h3>
            </Link>
            </>
         })}
         <h3>Points: {pts.pts}</h3>
         
      </section>
      }
      </>
   )
}

export default GameInfo