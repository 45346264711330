import React, {useState} from 'react'
import { db } from '../../../firebase-config';
import { doc, setDoc, deleteDoc, updateDoc, arrayUnion, getDoc} from 'firebase/firestore';

export async function initializeGame (user, gameId, faction){

   const docRefGame = doc(db, "games", gameId);
   const docRefPlayers = doc(db, "games", gameId, "players", user.uid);
   const date = new Date();

   if (faction === "Germany") {
      await updateDoc(docRefGame,{
         playersGermany: arrayUnion({uid: user.uid, nickName: user.nickName})
      })
   } else if (faction === "Russia"){
      await updateDoc(docRefGame,{
         playersRussia: arrayUnion({uid: user.uid, nickName: user.nickName})
      });
   };

   await setDoc(doc(db, "games", gameId, "players", user.uid), {
      position: faction,
      pointsToSpend: 20,
      totalTime: 0,
      totalProductiveTimeToday: 0,
      totalMaintenanceTimeToday: 0,
      productiveTime:0,
      semiproductiveTime:0,
      maintenanceTime:0,
      lastPayout: Math.floor(date.setHours(0,0,0,0)/1000),
      AIopenMoves: 0,
    });

   await setDoc(doc(db, "games", gameId, "players", user.uid, "ww2", "economy"), {
      economyLevel: 1,
      infrastructureLevel: 1,
      tankProductionLevel: 1,
      tankResearchLevel: 1,
      recruitmentLevel: 1,
      infantryResearchLevel: 1,
      armyTrainingLevel: 1,
      winterPreparationlevel: 1,
      propagandaLevel: 1,
      armyResearchLevel: 1,
   });

   if (faction === "Germany") {
      await setDoc(doc(db, "games", gameId, "players", user.uid, "ww2", "army"), {
         freeAttacks: 20,
         freeMoves: 0,
         lastInfRF: Math.floor(date.setHours(0,0,0,0)/1000),
         lastTankRF: Math.floor(date.setHours(0,0,0,0)/1000),
         deployableInfDivs: 0,
         deployableTankDivs: 0,
      });
   } else if (faction === "Russia"){
      await setDoc(doc(db, "games", gameId, "players", user.uid, "ww2", "army"), {
         freeAttacks: 0,
         freeMoves: 20,
         lastInfRF: Math.floor(date.setHours(0,0,0,0)/1000),
         lastTankRF: Math.floor(date.setHours(0,0,0,0)/1000),
         deployableInfDivs: 0,
         deployableTankDivs: 0,
      });
   };

   await setDoc(doc(db, "games", gameId, "players", user.uid, "time", "running"), {
      running: false,
      numberLogs: 0,
      startTime: 0,
    });

   await setDoc(doc(db, "games", gameId, "players", user.uid, "time", "history"), {
      logs: []
   });
   

   const docSnapGame = await getDoc(docRefGame);
   if (docSnapGame.data().players.length === docSnapGame.data().playerCount){
      await updateDoc(docRefGame, {
         state: "started"
      });
   }

   await updateDoc(docRefGame, {
      players: arrayUnion({uid: user.uid, nickName: user.nickName})
   });
   
}
