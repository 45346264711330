import { createSlice } from "@reduxjs/toolkit";

const initialStateValue = { minutes: 0, hours: 0}

export const totalTimeSlice = createSlice({
  name:"totalTime",
  initialState: {value: initialStateValue},
  reducers: {
    setTotalTime: ( state, action ) => {
        state.value = action.payload;
    },
  }
});

export const { setTotalTime } = totalTimeSlice.actions;
export default totalTimeSlice.reducer;