import React, {useState, useEffect} from 'react'
import { db } from '../../../../firebase-config';
import { doc, getDoc, setDoc, arrayUnion, updateDoc, increment} from "firebase/firestore";
import { useLocation} from 'react-router-dom';
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { render } from '@testing-library/react';

const ActionsHistory = () => {

    const location = useLocation();
    const dispatch = useDispatch();
    const user = useSelector((state) => state.user.value);
    const gameId = location.state.id;

    const [logsNumber, setLogsNumber] = useState(5);
    const [actionsLog, setActionsLog] = useState([]);

    useEffect (()=>{

        async function fetchData(){
            const docRefGame = doc(db, "games", gameId);
            const docSnapGame = await getDoc(docRefGame);
            setActionsLog(docSnapGame.data().actionsLog)
        }

        fetchData();

    }, [])

    if (actionsLog === []){
        return <h4>Loading...</h4>
    }


    return (
    <div className='past-actions-outer'>
        <h2 className='actions-log-title'>Battle log</h2>
        {actionsLog.slice((actionsLog.length-logsNumber > 0 ? (actionsLog.length-logsNumber):(0)), actionsLog.length).reverse().map((item)=>{
            return <section className='past-actions-inner'>
                <h4>{item.faction}</h4>
                <h4>{item.type}</h4>
                <h4>{new Date(item.time).toLocaleString("en-DE")}</h4>
                <h4>{item.territory}</h4>
            </section>
        })}
        <h4 className='actions-log-title' style={{cursor: 'pointer'}}onClick={()=>setLogsNumber(logsNumber+10)}>Expand</h4>
        
        
    </div>
    )
}

export default ActionsHistory