import React, {useEffect, useState} from 'react'
import { db } from '../firebase-config';
import { doc, getDoc, setDoc, updateDoc} from "firebase/firestore";
import { useLocation} from 'react-router-dom';

const OtherProfile = () => {

  const location = useLocation();
  const nickName = location.state.nickName;
  const uid = location.state.uid;

  const [numberGames, setNumberGames] = useState(0);
  const [numberWon, setNumberWon] = useState(0);
  const [bestTimeAi, setBestTimeAi] = useState(0);
  const [userCreatedAt, setUserCreatedAt] = useState(0);
  const [timeTotal, setTimeTotal] = useState(0);
  const [achievementLevel, setAchievmentLevel] = useState(1);

  useEffect( () => {

    async function fetchData(){
      const docRefUser = doc(db, "users", uid);
      const docSnapUser = await getDoc(docRefUser);
      const date = Date(docSnapUser.data().createdAt);
      const stringified = date.toString()
      const splitArr = stringified.split(" ")
      const wantedArr = [splitArr[2], splitArr[1], splitArr[3]]
      const joined = wantedArr.join(" ")
      setUserCreatedAt(joined);
    }

    fetchData();
  }, [])

  return (
    <div className='profile-container'>
      <section className='profile-top'>
        <img className="profile-placeholder" height="200px" width="200px"
        src="../../images/icons/profile-placeholder.svg"  alt="profile" />
        <div className='profile-top-sub'>
          <h1 className='profile-name'>Profile: {nickName}</h1>
          <h2 className='profile-level'>Lvl: {achievementLevel}</h2>
        </div>
      </section>

      <div className='profile-bottom'>
        <h4>Member since: {userCreatedAt}</h4>
        <h4>Number of games: {numberGames}</h4>
        <h4>Number of won games: {numberWon}</h4>
      </div>
      
      {/* <h4>SOON: Productive time tracked: {timeTotal}</h4> */}
      {/* <h4>SOON: Best time to beat AI: {bestTimeAi}</h4> */}
      
    </div>

  )
}

export default OtherProfile