import React, {useState} from 'react'
import MultiplayerGameActions from './Navigation/MultiplayerGameActions.jsx';
import MultiplayerGameEconomy from './Navigation/MultiplayerGameEconomy';
import EnemyInformation from './Navigation/EnemyInformation.jsx';
import OwnUpgrades from './Navigation/OwnUpgrades.jsx'
import TerritorySelectionSVG from './Navigation/TerritorySelectionSVG.jsx';

const GameNavigation = (props) => {

   const [showTab, setShowTab] = useState("map");

   function setVictory (number){
      props.setVictory(number)
   }

   function setSelectedTerritories(selectedT){
      props.setSelectedTerritories(selectedT)
   }
   function setRussianOccupied (selectedRO){
      props.setRussianOccupied(selectedRO)
   }

   function renderTab () {
      if (showTab === "upgrades"){
         return <OwnUpgrades/>
      } else if (showTab === "enemy"){
         return <EnemyInformation/>
      } else if (showTab === "information"){
         return <MultiplayerGameEconomy/>
      } else if (showTab === "actions"){
         return <MultiplayerGameActions
            setVictory={(number)=>setVictory(number)}
            setSelectedTerritories={(selectedT)=>setSelectedTerritories(selectedT)}
            setRussianOccupied={(selectedRO)=>setRussianOccupied(selectedRO)}
         />
      } else if (showTab === "map"){
         return <TerritorySelectionSVG 
            setSelectedTerritories={(selectedT)=>setSelectedTerritories(selectedT)}
            setRussianOccupied={(selectedRO)=>setRussianOccupied(selectedRO)}
         />
      }
   }

   return (
      <>
      <section className='game-info-container'>
         <h3 className='game-nav-items' onClick={()=>setShowTab("map")}>Map</h3>
         <h3 className='game-nav-items' onClick={()=>setShowTab("upgrades")}>Upgrades</h3>
         <h3 className='game-nav-items' onClick={()=>setShowTab("enemy")}>Enemy upgrades</h3>
         <h3 className='game-nav-items' onClick={()=>setShowTab("information")}>Other information</h3>
         <h3 className='game-nav-items' onClick={()=>setShowTab("actions")}>Actions</h3>
      </section>
      <section>{renderTab()}</section>
      </>
   )
}

export default GameNavigation