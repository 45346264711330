import React, {useState} from "react";
import { Link } from "react-router-dom";
import { getAuth, signOut } from "firebase/auth";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux"
import { login, logout } from "../../features/users";
import { useCookies} from 'react-cookie';

function Navbar(){

   //const userdb = auth.currentUser;
   const [cookies, setCookie, removeCookie] = useCookies(['cookie-name']);
   
   //getAll
   const userCookies = cookies.user;

   const dispatch = useDispatch();
   const user = useSelector((state) => state.user.value.email)
   const navigate = useNavigate();
   const auth = getAuth();
   const userdb = auth.currentUser;

   const [burgerToggle, setBurgerToggle] = useState();

   async function handleLogout (e) {

      e.preventDefault()
         //const object = getAll()
         removeCookie("user", {path: "/"})
         dispatch(login({ email: "", uid: "", nickName: ""}));
         navigate("/")
         
   }

   return (
     <>
     <div className="nav-outer">

     
      <nav className="nav-container">
         {burgerToggle ?
         null
         :
         <div className="logo-nav">
            <Link to="/"> 
               <img 
               src="/images/logo/pwLogo1.5blue.svg" alt="logo" height="40px" />
            </Link>
         </div>
         }
         <section className="outside-burger-section">
            {(user !== "") ? (
               <Link to="/multiplayer">
                  <span>Games</span>
               </Link>
            ): null}
               <Link to="/help">
                  <span>Help</span>
               </Link>
            {(user !== "") ? 
               <Link to="/myprofile">
                  <span>Profile</span>
               </Link>
            : null}
            
            {(user !== "") ? (
               <Link onClick={handleLogout} to="/">
                  <span>Logout</span>
               </Link>
            ):(
               <Link to="/">
                  <span>Login</span>
               </Link>
            )}
         </section>
         {burgerToggle ?
            <section className="burger-section">
               <div className="burger-menu">
                  {(user !== "") ? (
                     <Link to="/multiplayer">
                        <span>Singleplayer / Multiplayer</span>
                     </Link>
                  ):<></>}
                     <Link to="/help">
                        <span>Help</span>
                     </Link>
                     <Link to="/myprofile">
                        <span>Profile</span>
                     </Link>
                  {(user !== "") ? (
                        <span onClick={(e)=>handleLogout(e)}>Logout</span>
                  ):(
                     <Link to="/">
                        <span>Login</span>
                     </Link>
                  )}
               </div>
               
               <img src="/images/icons/BurgerMenuIcon.svg" className="burger-menu-toggler"
               onClick={()=>setBurgerToggle(false)} height="20px" alt="BurgerMenu" />
            </section>
         :
         <img src="/images/icons/BurgerMenuIcon.svg" className="burger-menu-toggler"
            onClick={()=>setBurgerToggle(true)} height="20px" alt="BurgerMenu" />
         }
         </nav>
      <section className='section-diff'></section>
      </div>
      </>
   )
}
export default Navbar;

/*
<Link onClick={handleLogout} to="/">
*/