import React from 'react'

const NewCampaignImage = (props) => {

  const selectedScenario = props.selectedScenario;

  return (
  <>
    <img 
      onClick={()=>{props.chooseMap(selectedScenario)}} 
      src={"/images/map/" + selectedScenario + ".jpg"}
      alt={selectedScenario} height={props.height}
    />
  </>
  )
}

export default NewCampaignImage