import React, {useState, useEffect} from 'react'
import { useLocation} from 'react-router-dom';
import { useSelector } from "react-redux"
import { db } from '../../../../../firebase-config';
import { doc, getDoc, setDoc, updateDoc, arrayUnion} from "firebase/firestore";
import faction from '../../../../../features/faction';

const MultiplayerGameEconomy = (props) => {

   //setup
   const location = useLocation();
   const gameId = location.state.id;
   const user = useSelector((state) => state.user.value)
   const pts = useSelector((state) => state.pts.value)
   const faction = useSelector((state) => state.faction.value)

   const docRefGame = doc(db, "games", gameId);
   const docRefGameEco = doc(db, "games", gameId, "players", user.uid, "ww2", "economy");
   
   const [territoryPoints, setTerritoryPoints] = useState(1)
   const [totalEconomy, setTotalEconomy] = useState(1)
   const [territoryCount, setTerritoryCount] = useState(1)
   const [enemyTerritoryPoints, setEnemyTerritoryPoints] = useState(1)
   const [enemyTotalEconomy, setEnemyTotalEconomy] = useState(1)
   const [enemyTerritoryCount, setEnemyTerritoryCount] = useState(1)
   const [ecoPointsCreated, setEcoPointsCreated] = useState(0);
   const [prodPointsCreated, setProdPointsCreated] = useState(0);
   
   useEffect( ()=>{
      
      async function fetchData(){
         const docSnapEco = await getDoc(docRefGameEco);
         let economyLevel = docSnapEco.data().economyLevel
         const docSnapGame = await getDoc(docRefGame)
         if (faction.faction === "Germany"){
            setTerritoryPoints((docSnapGame.data().germanTerritoryPoints).toFixed(1))
            setTotalEconomy((docSnapGame.data().germanTerritoryPoints*(1+0.1*economyLevel)).toFixed(1))
            setTerritoryCount((docSnapGame.data().germanTerritories).toFixed(1))
            setEnemyTerritoryPoints((docSnapGame.data().sovietTerritoryPoints).toFixed(1))
            setEnemyTotalEconomy((docSnapGame.data().sovietTerritoryPoints*(1+0.1*economyLevel)).toFixed(1))
            setEnemyTerritoryCount((docSnapGame.data().sovietTerritories).toFixed(1))
         } else {
            setEnemyTerritoryPoints((docSnapGame.data().germanTerritoryPoints).toFixed(1))
            setEnemyTotalEconomy((docSnapGame.data().germanTerritoryPoints*(1+0.1*economyLevel)).toFixed(1))
            setEnemyTerritoryCount((docSnapGame.data().germanTerritories).toFixed(1))
            setTerritoryPoints((docSnapGame.data().sovietTerritoryPoints).toFixed(1))
            setTotalEconomy((docSnapGame.data().sovietTerritoryPoints*(1+0.1*economyLevel)).toFixed(1))
            setTerritoryCount((docSnapGame.data().sovietTerritories).toFixed(1))
         }
         const docRefTimeTotal = doc(db, "games", gameId, "players", user.uid);
         const docSnapTotal = await getDoc(docRefTimeTotal);
         setProdPointsCreated(docSnapTotal.data().totalTime); 
         
         const playerEconomyLevel = docSnapEco.data().economyLevel;
         setEcoPointsCreated(Number((24*(territoryPoints/500/10)*(1+0.1*playerEconomyLevel)).toFixed(1)));
      }
      fetchData();
   },[pts]) //maybe remove points


   return (
      <section className='game-info-inner'>
         <div>
            <h3>Occupied territories: {territoryCount}</h3>
            <h3>Occupation points: {territoryPoints}</h3>
            <h3>Total economy: {totalEconomy}</h3>
            <h3>Number of infantry divisions: SOON</h3>
            <h3>Number of tank divisions: SOON</h3>
            <h3>Points from economy per day: {ecoPointsCreated}</h3>
            <h3>Points created today from productivity: {prodPointsCreated}</h3>
         </div>
         <div>
            <h3>Enemy occupied territories: {enemyTerritoryCount}</h3>
            <h3>Enemy occupation points: {enemyTerritoryPoints}</h3>
            <h3>Enemy total economy: {enemyTotalEconomy}</h3>
            <h3>Enemy number of infantry divisions: SOON</h3>
            <h3>Enemy number of tank divisions: SOON</h3>
         </div>
      </section>
   )
}

export default MultiplayerGameEconomy