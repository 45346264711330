import { createSlice } from "@reduxjs/toolkit";

const initialStateValue = { loaded: false}

export const gameLoadedSlice = createSlice({
  name:"gameLoaded",
  initialState: {value: initialStateValue},
  reducers: {
    addGameLoaded: ( state, action ) => {
        state.value = action.payload;
    },
  }
});

export const { addGameLoaded } = gameLoadedSlice.actions;
export default gameLoadedSlice.reducer;