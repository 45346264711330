import React, { useState} from 'react'
import NewCampaignImage from '../../General/NewCampaignImage'
import { db } from '../../../firebase-config';
import { doc, getDoc, setDoc, updateDoc } from 'firebase/firestore';
import { useSelector } from 'react-redux'
import { useNavigate } from "react-router-dom"
import { computeGame } from './compute-game'

const CreateMultiplayerGame = () => {

  //create game -> compute-game -> initializeGame
  const navigate = useNavigate();
  const user = useSelector((state) => state.user.value)

  const [selected, setSelected] = useState("");
  const [timeframe, setTimeframe] = useState(180);
  const [playerCount, setPlayerCount] = useState("Singleplayer");
  const [open, setOpen] = useState('public');
  const [scenario, setScenario] = useState('taifun');
  const [faction, setFaction] = useState('Germany');
  const [creating, setCreating] = useState(false);
  
  const id = Date.now() + Math.random();
  const gameId = `hh${id}`;

  function setMap(scenario){
    setSelected(scenario);
    setScenario(scenario)
  }

  function createGame(e){
    e.preventDefault();
    setCreating(true)
    computeGame("initial", gameId, user, faction, playerCount, timeframe, open, scenario)
    if (playerCount === "Singleplayer"){
      setTimeout(() => navigate(`/multiplayer/${gameId}` , { state: { id: `${gameId}`, players: [{nickName: 'AI', uid: 'AI'},{nickName: user.nickName, uid: user.uid}] } }), 4000);
    } else {
      setTimeout(() => navigate(`/multiplayer/${gameId}` , { state: { id: `${gameId}`, players: [{nickName: user.nickName, uid: user.uid}] } }), 4000);
    }
    
  }
  

  return (
    <>
    <section className='hzcenter'>
    <h1>Create New Game</h1>
    </section>
    { selected !== "" ?
    <section className='hzcenter'>
        <div>
          <NewCampaignImage
            selectedScenario={selected}
            height="400px"
          />
        </div>
        <div className='vtcentersmall'>
          <h1>Game settings</h1>
          <hr />
          <h4>Number of players</h4>
          <section> 
            <button onClick={()=>setPlayerCount("Singleplayer")}>Singleplayer</button>
            <button onClick={()=>setPlayerCount(2)}>2</button>
          </section>
          <hr />
          <h4>Choose faction</h4>
          <section>
            <button onClick={()=>setFaction("Germany")}>Germany</button>
            <button onClick={()=>setFaction("Russia")}>Soviet Union</button>
          </section>
          <hr />
          {creating? 
          <h1>Creating...</h1>
          :
          <button className="createButton" onClick={createGame}>Create game</button>  
          }
        </div>
    </section>
    :
    <section className='hzcenter'>
    <div className="campaignImg">
      <NewCampaignImage
        selectedScenario="standard"
        desc="June 1941"
        height="300px"
        chooseMap={(scenario)=>setMap(scenario)}
      />
      
    </div>
    <div className="campaignImg">
      <NewCampaignImage 
        selectedScenario="taifun"
        desc="September 1941"
        height="300px"
        chooseMap={(scenario)=>setMap(scenario)}
      />
    </div>
  </section>
    }
  </>
  )
}

export default CreateMultiplayerGame