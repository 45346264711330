import React from 'react'
import { Link} from "react-router-dom"

const PastGameListed = (props) => {

   const item = props.item;

   return (
      <>
      <section className='game-list-past-container'>
         <h4>Operation {item.data().scenario}</h4>
         {item.data().victory === "yes" ?
         <h4>Victory</h4>:<h4>Defeat</h4>}
         <h4>{item.data().id}</h4>
         <section>
            <h4>Germany:</h4>
            {item.data().playersGermany.map((player)=>{
               return <h4>{player.nickName}</h4>
            })}
            <h4>Russia:</h4>
            {item.data().playersRussia.map((player)=>{
               return <h4>{player.nickName}</h4>
            })}
         </section>
            <Link to={`/pastgames/${item.data().id}`} 
               state={{ 
                  id: item.data().id,
                  selectedTerritories: item.data().territories,
               }}>
               <button className="inverse-button listed-button">View</button>
            </Link>
      </section>
         

      </>
   )
}

export default PastGameListed