import React, {useEffect, useState} from 'react'
import { db } from '../../../../../../firebase-config';
import { doc, getDoc, increment, setDoc, updateDoc} from "firebase/firestore";
import { useLocation} from 'react-router-dom';
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
  } from 'chart.js';
import { Bar } from 'react-chartjs-2';

const TimeTrackingHistory = (props) => {

    console.log(props.userData)
    console.log(props.userData[0].productiveTime)
    const location = useLocation();
    const dispatch = useDispatch();
    const gameId = location.state.id;
    

    const [loaded, setLoaded] = useState(false)
    const [histArr, setHistArr] = useState({
        labels: props.userData.map(log=>log.day) ,
        datasets: [
            {
            label: "Productive",
            data: props.userData.map(log=>log.productiveTime/60),
            color: "white",
            backgroundColor: "rgb(29, 30, 44)",
            }, 
            {
            label: "Maintenance",
            data: props.userData.map(log=>log.maintenanceTime/60),
            color: "white",
            backgroundColor: "rgb(147, 31, 29)",
            }
        ]
    })

    ChartJS.register(
        CategoryScale,
        LinearScale,
        BarElement,
        Title,
        Tooltip,
        Legend
    );
    const options = {
        responsive: true,
        plugins: {
          legend: {
            display: false,
            position: 'bottom',
          },
          title: {
            display: true,
            text: props.state==="standard"? 'Your tracked time history':'Enemy tracked time history',
            color: "rgb(29, 30, 44)",
          },
          label: {
            color: "white",
          }
        },
        scales: {
            grid: {
                color: "white",
                display: false,
            },
            ticks: {
                display: false,
            },
            x: {
              stacked: true,
            },
            y: {
                ticks: {
                    color: "rgb(29, 30, 44)",
                    callback: function(value, index, ticks) {
                        return value + "h";
                    }
                },
              stacked: true,
              display: true,
              color: "rgb(29, 30, 44)"
            },
          },
    };

return <section className='game-info-inner'>
    <div className='bar-chart-history'>
        <Bar options={options} data={histArr} />
    </div>
</section>
}

export default TimeTrackingHistory