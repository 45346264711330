import React, { useState} from 'react'
import { db } from '../../../firebase-config';
import { doc, getDoc, setDoc, updateDoc, arrayUnion } from 'firebase/firestore';
import { german_standard_short, russian_standard_short } from './standard-short';

export async function setTerritoriesStandard (gameId) {

   await setDoc(doc(db, "games", gameId, "russian", "territories"), {
      territories: russian_standard_short
   });
   await setDoc(doc(db, "games", gameId, "german", "territories"), {
      territories: german_standard_short
   });
   
   updateDoc(doc(db, "games", gameId), {
      loaded5: true,
   })

   const docRefGame = doc(db, "games", gameId);
   const docSnapGame = await getDoc(docRefGame);

   if (docSnapGame.data().loaded1 === true & docSnapGame.data().loaded2 === true & docSnapGame.data().loaded3 === 7 & docSnapGame.data().loaded4 === true & docSnapGame.data().loaded6 === true){
   }

}

/*

for(let i = 0; i<russian_occupied.length; i++){
   await updateDoc(doc(db, "games", gameId, "russian", "territories"), {
      territories: arrayUnion(russian_occupied[i])
   });

   if (i === russian_occupied.length -1 ){
      
   }
}
*/