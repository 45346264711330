const { initializeApp } = require('firebase/app');
const { getFirestore} = require('@firebase/firestore');

const firebaseConfig = {
  apiKey: "AIzaSyBucY2EzNg-B6ipUdyvBWUa93o5YzoUl24",
  authDomain: "productivity-time-wars.firebaseapp.com",
  projectId: "productivity-time-wars",
  storageBucket: "productivity-time-wars.appspot.com",
  messagingSenderId: "722455521132",
  appId: "1:722455521132:web:97d392f5496c745471b549",
  measurementId: "G-3TSJWCS9XR"
};

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

export {db};
//const db = firebaseApp.firestore();