import React, {useState} from 'react'

const Help = () => {

  const [showDetailedAttack, setShowDetailedAttack] = useState(false)

  return (
    <div className='help-container'>
      
      <div>
        <h3>Please report any bugs you encounter by sending them to my e-mail adress: felix.moosbauer@gmx.at.</h3>

        <h1>Game principle</h1>
        <p>Doing anything in this game costs points. The primary way to generate points is by tracking your productivity time with the built in time tracker. So the game rewards you for 
          the time you spend productively by giving you the ability to launch attacks, reinforce troops or buy upgrades to defeat your opponent.
        </p>

        <h3>Time tracking</h3>

        <img src="/images/components/time-tracker-preview.png" alt="time-tracker-preview" width="500px"/>
        <p>Press play on the time tracker window to start the timer and the arrow to make them be listed.</p>
        

        <img src="/images/components/complete-tracker-preview.png" alt="complete-tracker-preview" width="500px"/>
        
        <p>You can still edit start and end times in this tab and you also need to select the productivity type. Maintenance time converts productivity into half the game points as productivity time. You can then press the button next to the selected time to redeem the time and get rewarded the game points. Start and end times cannot be edited anymore after that.</p>


        <h1>Battle mechanics</h1>
        <h3>Attack</h3>
        <p>To attack a territory first click on the territory you want to attack from and then click on select.
          Now you can click on the territory you want to attack, put in the number of divisions you want to move into the territory and click confirm.</p>
        <p>The amount of points you require to take a territory is based on the strength ratio. If you have an overwhelming force taking a territory might only cost 1-2 points,
          but attacking a far superior enemy might take over 10 points. </p>
        <p>Furthermore, the strength ratio is based on the number of troops in the territory you are attacking from, not the actual amount you are using to move into the territory with.</p>
        <p>In every battle there is a chance that divisions get shattered and destroyed. The probability of this happening increases based on how strong your enemy is.</p>
        <p>A territory can only be attacked once every 5 hours.</p>
          
          {showDetailedAttack ? 
          <div>
            <button className="help-button" onClick={()=>setShowDetailedAttack(false)}>Hide detailed combat logic</button>
            <p>Combat starts by checking whether tank divisions are involved and whether they have been shattered.</p>
            <p>If no tank divisions are involved or no tank division has been shattered, the strength number is applied to the infantry.</p>
            <p>For every 20 strength of the enemy one of your infantry divisions will be shattered for sure.</p>
            <p>For every strength above that, the probability goes up for an extra division to be shattered.</p>
            <h3>Example</h3>
            <p>Attack strength: 24, Defence strength: 7</p>
            <p>The defender loses 1 division and has a 4*5 = 20% probability of shattering an additional division</p>
            <p>the attacker has a 7*5 = 35% probability of having a division shattered.</p>
            <h3>Strength calculation</h3>
            <p>Attacking power: Number of attacking tanks x 5 x tank research level + Number of attacking infantry divisions x 1 x infantry research level</p>
            <p>Defending power: Attacking power</p>
            <p className='help-indented-text'>+20% strength basic defender bonus</p>
            <p className='help-indented-text'>+2 base defence points for every level of fortification</p>
            <p className='help-indented-text'>+40% strength for every level of fortification or -15% for every negative level of fortification</p>
            <p className='help-indented-text'>+100% strength when being attacked over a river</p>
          </div>
          : 
          <button className="help-button" onClick={()=>setShowDetailedAttack(true)}>Show detailed combat logic</button>
          }
        <h3>Move</h3>
        <p>Divisions can also be move around within your territories. Doing this work just like an attack, only that you move your divisions into a friendly territory.</p>
      </div>
      <div>
        <h1>Other battle options</h1>
        <h3>Artillery</h3>
        <p>The artillery barrage action costs 5 points and can be called onto an enemy territoy, in which it damages enemy divisions significantly, by shattering each division with a sizeable probability.
          This ability should be used to soften up big stacks of enemy units.
        </p>
        <h3>Assault</h3>
        <p>The assault actions costs 5 points and reduces the fortification level of an enemy territory by 1.
          This can also go into minus, which creates a debuf for the defending troops if attacked.</p>
        <h3>Fortify</h3>
        <p>Increases the fortification level of a territory by 1. </p>
        <h4>Counter attack</h4>
        <p>SOON</p>
      </div>
      <div>
        <h1>Actions</h1>
        <p>Every faction has their own unique actions with different costs, but most of them have to do with the deployment of new and fresh divisions.</p>
      </div>
      <div>
        <h1>Upgrades</h1>
        <p>Upgrades can be purchased which either improve your economy or your army or give utility benefits. Most upgrades are the same for every factions,
          but there are some that are different and unique. The cost for upgrading increases for each upgrade by one point.</p>
      </div>
      <div>
        <h1>Victory conditions</h1>
        <p>To win Germany needs to capture and hold the following territories for 10 days before day 180.</p>
        <p>Moscow, Leningrad, Kharkov</p>
        <p>Is this not achieved, Germany can still win if it captures and holds the following territories for 10 days before day 500.</p>
        <p>Maikop, Grozny, Stalingrad and one of either Moscow, Leningrad or Baku</p>
        <p>Russia wins by holding out until day 500 or by capturing Germany.</p>
      </div>
      <div>
        <h1>Territories</h1>
        <p>Every basic territory gives 0.5 economy points. Tier 1 cities give 5 points, tier 2 cities 10 points, tier 3 cities 15 points.</p>
        <p>Leningrad:50 , Moscow:80 and Stalingrad:40.</p>
        <p>Oil fields: Maikop: 30, Grozny: 40, Baku: 80</p>
      </div>
      <div>
        <h1>Encirclements</h1>
        <p>Encirclments are important as all remaining divisions are automatically shattered if they have no friendly neighboring territory to retreat into. However, encircled units do not suffer combat penalties if they are still connected to more than 2 friendly territories.</p>
      </div>
      <div>
        <h1>Useful to know</h1>
        <p>Murmansk is worth a lot of points and as it is the supply line for Russia by the allies.</p>
        <p>Cutting the land connection between Murmansk and the rest of Russia has no effect. Only Murmansk itself matters in this game.</p>
      </div>
      <div>
      <h3>Have more questions? Found a bug? Contact me at felix.moosbauer@gmx.at.</h3>
      </div>
      
    </div>
  )
}

export default Help

/*
<h2>Uprades</h2>
        <h4>Propaganda Level: Increases the points you generate by being productive.</h4>
        <h4>Economy Level: Increases the points you generate ever hour based on the number or territories and cities you own.</h4>
        <h4>Infrastructure Level: Allows you to move an increasing number of divisions for no cost an increasing distance every day.</h4>        

        <h3>Combat upgrades:</h3>
        <h4>Tank Production Level: Reduces the amount of time it takes to automatically spawn new tank divisions by 5%.</h4>
        <h4>Tank Research Level: Increases fighting strength of tank divisions by 20%.</h4>
        <h4>Recruitment Level and Infantry Research Level are the same as the tank upgrades.</h4>
        <h4>Army Training Level: </h4>
        <p>Lvl 1: -1 cost to attack, lvl 2: -1 cost to assault, lvl 3: -10% shatter probability, lvl4: +1 cost for enemy to attack, lvl5: -1 cost to attack, lvl 6: -1 cost to assault, lvl 7: -10% shatter probability, lvl 8: +1 cost for enemy to attack, lvl9: -1 cost to attack, lvl 10: -1 cost to attack</p>
        <h4>Army Research Level: </h4>
        <p>Lvl 2: -1 cost to upgrade, lvl 3: -5 cost on all actions, lvl4: +20% strength of fortifications lvl5: -1 cost to fortify, lvl 6: -1 cost of artillery barrage lvl 7: -1 cost to upgrade, lvl 8: +20% strength of fortifications, lvl9: -1 cost of artillery barrage, lvl 10: unlock the katyusha ability)</p>
        */